import {
  createGraphQLApiProvider,
  type CreateGraphQLApiProviderOptions,
} from '@stitch-fix/graphql-api-provider';
import { settings } from '../utils';

type CreateOptions<TCacheShape> = Partial<
  Omit<
    CreateGraphQLApiProviderOptions<TCacheShape>,
    'bearerToken' | 'graphqlURI' | 'name' | 'version'
  >
>;

/**
 * Creates a GraphQLApiProvider component based on the app settings
 * @returns An object containing the `GraphQLApiProvider`, as well as the
 * underlying `ApolloClient` object
 */
export const create = <TCacheShape>(options: CreateOptions<TCacheShape> = {}) =>
  createGraphQLApiProvider({
    ...options,
    bearerToken: settings.bearerToken,
    graphqlURI: settings.graphqlApiEndpoint,
    name: settings.apolloClientName,
    version: settings.appVersion,
  });
