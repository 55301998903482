import Script from 'next/script';
import { useEffect } from 'react';
import { useClientAuth } from '../ClientAuthContext';
import { useClientI18n } from '../ClientI18nContext';
import { getWindow } from '../utils/getBrowserGlobals';
import { getGtmScript, getInitialData } from './utils';

declare global {
  interface Window {
    /**
     * Namespace used by Google Tag Manager to track events
     */
    dataLayer?: unknown[];
  }
}

/**
 * Includes the Google Tag Manager `<script>`, initializing it with user and
 * other pertinent data
 */
const ClientGtm = () => {
  const { region } = useClientI18n();
  const { authStatus, client } = useClientAuth();

  // TODO: Test `<script>` contents & `dataLayer` initialization once we're able
  // to mock auth & i18n (KFE-577)

  useEffect(() => {
    const win = getWindow();

    if (win && authStatus !== 'loading') {
      win.dataLayer = win.dataLayer || [];

      // Ensure the initialization data is first in the data
      // See: https://github.com/stitchfix/kufak_gtm_client/blob/b903167ced0e85b37e1f36d7583aefe69df46458/lib/stitch_fix/kufak_gtm_client/view_helpers.rb#L12-L15
      win.dataLayer.unshift(
        getInitialData(client?.externalId, client?.businessLine),
      );
    }
  }, [authStatus, client?.externalId, client?.businessLine]);

  return (
    <Script id="sf-next-gtm_init" strategy="afterInteractive">
      {getGtmScript(region)}
    </Script>
  );
};

export default ClientGtm;
