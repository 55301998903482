import useMedia from './useMedia';

/**
 * A hook that returns whether or not the screen is the width of the specified breakpoint or higher,
 * updating when the screen width crosses the threshold
 * @param breakpoint The breakpoint to match on (in pixels)
 */
export const useAtBreakpoint = (breakpoint: number) =>
  useMedia(`screen and (min-width: ${breakpoint}px)`);

/**
 * A hook that returns whether or not the screen width is less than the specified breakpoint,
 * updating when the screen width crosses the threshold
 * @param breakpoint The breakpoint to match on (in pixels)
 */
export const useBelowBreakpoint = (breakpoint: number) =>
  useMedia(`screen and (max-width: ${breakpoint - 1}px)`);

/**
 * A hook that returns whether or not the screen width is between the specified breakpoints,
 * updating when the screen width crosses the threshold
 * @param minBreakpoint The lower bound breakpoint (in pixels)
 * @param maxBreakpoint The higher bound breakpoint (in pixels)
 */
export const useBetweenBreakpoints = (
  minBreakpoint: number,
  maxBreakpoint: number,
) =>
  useMedia(
    `screen and (min-width: ${minBreakpoint}px) and (max-width: ${
      maxBreakpoint - 1
    }px)`,
  );
