import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
import IconBase, { IconProps } from '../IconBase';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconSocialTwitter24 = (baseProps: IconProps, ref: Ref<SVGSVGElement>) => {
  return <IconBase {...baseProps}>
        {({
      title,
      titleId,
      ...props
    }: SVGProps<SVGSVGElement> & SVGRProps) => <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path d="M14.2396 10.1624L23.0035 0H20.9267L13.3171 8.82384L7.23928 0H0.229248L9.42009 13.3432L0.229248 24H2.30612L10.3421 14.6817L16.7607 24H23.7707L14.2391 10.1624H14.2396ZM11.3951 13.4608L10.4639 12.1321L3.05444 1.55962H6.2444L12.2239 10.0919L13.1551 11.4206L20.9277 22.5113H17.7378L11.3951 13.4613V13.4608Z" fill="currentColor" /></svg>}
      </IconBase>;
};
const ForwardRef = forwardRef(IconSocialTwitter24);
export default ForwardRef;