import { ReactNode } from 'react';
import {
  ResponsiveSection,
  ResponsiveSectionProps,
} from '@stitch-fix/mode-react';
import { Header, HeaderProps, Footer } from '@stitch-fix/knit';
import { useClientI18n } from '../ClientI18nContext';

interface Props {
  children: ReactNode;
  /**
   * Header props passed through to Knit Header
   */
  header?: Partial<HeaderProps>;
  /**
   * Styling props passed through to ResponsiveSection
   */
  responsiveSection?: {
    /**
     * @default 4
     */
    mb?: ResponsiveSectionProps['mb'];
    /**
     * @default 2
     */
    mt?: ResponsiveSectionProps['mt'];
    /**
     * @default '100vh'
     */
    minHeight?: ResponsiveSectionProps['minHeight'];
    gutter?: ResponsiveSectionProps['gutter'];
    width?: ResponsiveSectionProps['width'];
  };
}

const Layout = ({ children, header = {}, responsiveSection = {} }: Props) => {
  const { region } = useClientI18n();

  return (
    <>
      <Header region={region} {...header} />
      <ResponsiveSection
        id="knit-main-content"
        as="main"
        minHeight="100vh"
        mb={4}
        mt={2}
        {...responsiveSection}
      >
        {children}
      </ResponsiveSection>
      <Footer region={region} />
    </>
  );
};

export default Layout;
