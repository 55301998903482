import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
import IconBase, { IconProps } from '../IconBase';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconSocialYoutube16 = (baseProps: IconProps, ref: Ref<SVGSVGElement>) => {
  return <IconBase {...baseProps}>
        {({
      title,
      titleId,
      ...props
    }: SVGProps<SVGSVGElement> & SVGRProps) => <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M15.2005 3.21059C15.6823 3.695 15.8385 4.79796 15.8385 4.79796L15.8372 4.79665C15.937 5.657 15.9913 6.52199 16 7.38803V8.60686C15.9931 9.47287 15.9401 10.3379 15.8411 11.1982C15.8411 11.1982 15.6849 12.3025 15.2044 12.7869C14.655 13.3609 14.0475 13.4206 13.7032 13.4545C13.6655 13.4582 13.631 13.4616 13.6001 13.4653C11.3642 13.6307 8.00064 13.6307 8.00064 13.6307C8.00064 13.6307 3.84015 13.5943 2.56011 13.4719C2.49843 13.4602 2.42366 13.4512 2.33901 13.4409C1.93221 13.3918 1.29748 13.3151 0.793029 12.7869C0.315136 12.296 0.158863 11.193 0.158863 11.193C0.0600228 10.3327 0.00699418 9.46765 0 8.60168V7.38933C0.00688291 6.52332 0.0599115 5.6583 0.158863 4.79796C0.158863 4.79796 0.312529 3.695 0.796939 3.21059C1.34549 2.63628 1.95206 2.57679 2.2957 2.54313C2.33383 2.53938 2.36872 2.53594 2.39994 2.53216C4.6397 2.36938 7.99936 2.36938 7.99936 2.36938C7.99936 2.36938 11.3603 2.36938 13.5975 2.53216C13.6284 2.53591 13.663 2.53931 13.7007 2.54303C14.0443 2.57689 14.6525 2.63685 15.2005 3.21059ZM6.34296 5.57666V10.0731L10.6649 7.83206L6.34296 5.57666Z" fill="currentColor" /></svg>}
      </IconBase>;
};
const ForwardRef = forwardRef(IconSocialYoutube16);
export default ForwardRef;