import type { ResourceKey } from 'i18next';
import { Locale } from './types';
import { DEFAULT_LOCALE, VALID_LOCALES } from './constants';

export const isExistingBundle = (
  retrievedBundle: ResourceKey,
  bundleToTest: ResourceKey,
) => {
  // Stringify the objects as a cheap way to do a deep compare. Ideally we could
  // use referential equality because the `retrievedBundle` *should* be the same
  // object we added. But somehow they are different references but exact same
  // contents.
  return JSON.stringify(retrievedBundle) === JSON.stringify(bundleToTest);
};

/**
 * Returns whether or not the given value is a valid locale string.
 */
export const isValidLocale = (value?: string): value is Locale => {
  const validLocaleStrings: Set<string> = VALID_LOCALES;

  return !!value && validLocaleStrings.has(value);
};

/**
 * Validates that the specified `value` is a valid locale. If it is, it
 * returns it. Otherwise, it returns the default locale.
 */
export const getValidatedLocale = (value?: string) =>
  isValidLocale(value) ? value : DEFAULT_LOCALE;
