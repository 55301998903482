import Bugsnag from '@bugsnag/js';
import Cookies from 'js-cookie';
import { getWindow } from './getBrowserGlobals';

import { getAuthenticatedClientFromJwt } from './utils';
import { AUTH_COOKIE_NAME, VISITOR_COOKIE_NAME } from './constants';

/**
 * Gets the client auth information for the logged in user from the auth cookie, if it exists.
 * NOTE: The client auth cookie is **not** validated, only parsed
 * @fallbackToken A fallback JWT if the auth cookie doesn't exist
 * @returns Client auth info if logged in, otherwise `undefined` indicating logged out
 */
export const getAuthenticatedClient = (fallbackToken?: string) => {
  // By default grab the JWT from the auth cookie. However, if it doesn't exist,
  // we check the `fallbackToken`. The `fallback` is typically an env var
  // defined in the caller of `getAuthenticatedClient`
  const token = Cookies.get(AUTH_COOKIE_NAME) || fallbackToken;

  const result = getAuthenticatedClientFromJwt(token);

  // Check if we're client-side rendering by checking the presence of the
  // `window` object.
  if (result.error && getWindow()) {
    Bugsnag.notify(result.error);
  }

  return result;
};

/**
 * Gets the value of the visitor UUID cookie, if it exists
 * @returns The visitor UUID string if it exists, otherwise `undefined`
 */
export const getVisitorUuid = () => {
  return Cookies.get(VISITOR_COOKIE_NAME);
};

export { AUTH_COOKIE_NAME, VISITOR_COOKIE_NAME };
