// See .env* files & CI for env values. In order to keep server-only secrets
// safe, Next.js replaces `process.env.*` with the correct values at build time.
// This means that `process.env` is not a standard JS object, so we can't do
// object destructuring or string concatenation of a env name.

import { settings } from '@stitch-fix/sf-next';

const appSettings = {
  // Include app-specific environment variables here.
  // myCustomAppEnvVar: process.env.NEXT_PUBLIC_MY_CUSTOM_APP_ENV_VAR,

  // Required environment variables from sf-next. DO NOT REMOVE OR OVERRIDE.
  ...settings,
  datadogClientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
} as const;

export default appSettings;
