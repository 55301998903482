import { G } from '@mobily/ts-belt';
import { assign, createMachine, pure } from 'xstate';
import { flows, FlowId } from './flows';
import { browserLogger } from '../../../../utils/loggers/browser';
import { getSteps } from './utils';
import type { FixScheduleInput } from '../../../../types/definitions/vendor/graphql-schema.d';

type FixScheduleInputData = Pick<
  FixScheduleInput,
  'clientWantsFixOn' | 'firstAvailableFixDate' | 'interval'
>;

export type FlowContext = {
  flow: FlowId;
  currentStepIndex: number;
  nextStepPath?: string;
  previousStepPath?: string;
  data?: Partial<FixScheduleInputData>;
  errorMessage?: string;
};

type CompleteData = Required<FixScheduleInputData>;
export type FlowData =
  | Pick<CompleteData, 'interval'>
  | Pick<CompleteData, 'clientWantsFixOn' | 'firstAvailableFixDate'>;

type FlowEvent =
  | { type: 'BACK' }
  | { type: 'NEXT'; data?: FlowData }
  | { type: 'RESET_FLOW'; flow: FlowId; stepIndex: number }
  | { type: 'PAYMENT_ERROR_REDIRECT'; message: string }
  | { type: 'UPDATE_FLOW_STEPS'; currentStepIndex: number };

type FlowTypestate =
  | {
      value: 'idle' | 'navigating';
      context: FlowContext;
    }
  | {
      value: 'complete';
      context: FlowContext & { data: CompleteData };
    };

export const flowMachine = createMachine<FlowContext, FlowEvent, FlowTypestate>(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QDMA2B7A7gOgJYVTAGIAhAQQGEBpAbQAYBdRUAB3VlwBdd0A7ZkAA9EAWgBMYgKzYAbAA46kgJwyALAGYxcpWIDsAGhABPUZLrYAjDJm7VY1arkWl6yapkBfD4bRY8BYgA5AFEADQAVeiYkEDYObj4BYQQRVQt1bF06ZwklOjF1C10ZQxMUyTlsJzMdG3Us1V1dSS8fDBx8QiIAJWCAZWDwgH0AMQAZAHkAdSiBOK4efhjk8SULbHU5eWd1GQrGuVLRdXUlWSVJXR2LRSUNXVaQXw6AogBVAAUAETJw4NHJlMhn0-h8+rMYvMEktQCsVJYxEpqhY5FpaodjMdTudLtdbvdHs9-F0Iax2AtEstEFZzA46DItO4xHR1NkjilnGcTnJdijCrZ1A5Ce1sLwAIYAN1wUDF3F4UCIED4YDwvAl6AA1iqieKpTK5VAELg1egAMayxZRUmxcnQpKiKzrRwOPIVVFKZrskQWMTYNb3OSNH0XZlyYV+XXSi3yohgABOcfQcewLFQsuQSYAttgdZKowajSbzdCrYw5rbFvaUnpsJJTkVJD65Ao9Cp2ZIZJYlGssv66I1JB3wzhTehM6mwJxiB8yABNACywUCw2C3W6E26Q16XwAkr0KJEy5CK5TYYgxPJMkzJPZUQ31F76pZVHQ7q+roibxYvN4QLx0BAcACM85bxJWVIpO46xZDkiL5PyJSYpBNbOLiLg3MoBK-kSnRgKBFIwkIxwdrWGj2KoFzdiy7hehIGQ8jy1gyHQWSso4w6inm+rGlA+F2hBIi7JUDhNHQ9IWG4VgPkhzKqNgLqnIKg5ZPknjYSKo7joQU58eBZ4IIK6yNioeyOJoRTSWU4jZLWza7Io+R1i46g-h4QA */
    id: 'flow',
    predictableActionArguments: true,
    initial: 'idle',
    states: {
      idle: {
        description:
          'Await user navigation events, or transition to complete state if final step.',
        always: { target: 'complete', cond: 'isFinalStep' },
        exit: 'clearError',
        on: {
          BACK: 'navigating',
          NEXT: {
            actions: 'assignDataToContext',
            target: 'navigating',
          },
          RESET_FLOW: { actions: 'resetFlow' },
          UPDATE_FLOW_STEPS: { actions: 'assignStepsToContext' },
        },
      },

      navigating: {
        description: `Invoke the handleFlowNavigation service, which is defined as a config
          option in FlowProvider, and handles step transition animations and routing.`,
        invoke: {
          src: 'handleFlowNavigation',
          onDone: {
            target: 'idle',
          },
          onError: {
            target: 'idle',
            actions: 'logError',
          },
        },
      },

      complete: {
        on: {
          PAYMENT_ERROR_REDIRECT: {
            target: 'navigating',
            actions: 'assignErrorMessageToContext',
          },
        },
      },
    },
  },
  {
    guards: {
      isFinalStep: ctx =>
        !G.isUndefined(ctx.flow) &&
        ctx.currentStepIndex === flows[ctx.flow].length - 1,
    },
    actions: {
      assignDataToContext: assign({
        data: (ctx, ev) => ({
          ...ctx.data,
          ...(ev.type === 'NEXT' && ev.data),
        }),
      }),
      assignErrorMessageToContext: assign({
        errorMessage: (_, ev) =>
          ev.type === 'PAYMENT_ERROR_REDIRECT' ? ev.message : undefined,
      }),
      assignStepsToContext: assign((ctx, ev) => {
        if (ev.type === 'UPDATE_FLOW_STEPS') {
          return {
            ...ctx,
            ...getSteps({
              flow: ctx.flow,
              currentStepIndex: ev.currentStepIndex,
            }),
          };
        }

        return ctx;
      }),
      clearError: pure(
        ctx =>
          ctx.errorMessage &&
          assign({
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            errorMessage: _ => undefined,
          }),
      ),
      logError: (error: unknown) =>
        browserLogger({
          level: 'error',
          feature: 'lower-fix-funnel',
          message: 'Lower Fix Funnel navigation error',
          context: { error },
        }),
      resetFlow: assign((ctx, ev) => {
        if (ev.type === 'RESET_FLOW') {
          const { flow, stepIndex } = ev;

          return {
            flow,
            ...getSteps({ flow, currentStepIndex: stepIndex }),
          };
        }

        return ctx;
      }),
    },
  },
);
