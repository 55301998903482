import { SimpleScreenViewEvent } from '../types/events';

type UTMParams = SimpleScreenViewEvent['utm_params'];

const getUTMParams = (): UTMParams => {
  // Second URL arg is required for Safari < 14
  const urlParams = new URL(window.location.href, window.location.origin)
    .searchParams;

  return {
    utm_campaign: urlParams.get('utm_campaign'),
    utm_content: urlParams.get('utm_content'),
    utm_medium: urlParams.get('utm_medium'),
    utm_source: urlParams.get('utm_source'),
    utm_term: urlParams.get('utm_term'),
    utm_campaign_id: urlParams.get('utm_campaign_id'),
    utm_adgroup_id: urlParams.get('utm_adgroup_id'),
    utm_ad_id: urlParams.get('utm_ad_id'),
    utm_keyword_id: urlParams.get('utm_keyword_id'),
    utm_product_id: urlParams.get('utm_product_id'),
    utm_site_id: urlParams.get('utm_site_id'),
  };
};

export default getUTMParams;
