import React, { ReactNode, ElementType } from 'react';
import classNames from 'classnames';
import Box, { BoxProps, ExtendableBoxProps } from '../Box';
import IconCloseX16 from '../icons/IconCloseX16';
import IconButton, { IconButtonProps } from '../IconButton';
import {
  ResponsiveValue,
  getResponsiveValue,
  processStylingProps,
  DeprecatedAndDangerousStylingProps,
} from '../_internal/styling';
import useScreenSize from '../_internal/useScreenSize';

import styles from './interactableContainer.module.scss';

interface Props extends DeprecatedAndDangerousStylingProps {
  /**
   * The Interactable Container contents
   */
  children: ReactNode;

  /**
   * Label used for the icon for accessibility.
   */
  iconLabel: string;

  /**
   * Title for the icon, if you want one to appear on hover.
   */
  iconTitle?: string;

  /**
   * The icon. Only one element is allowed. Defaults to an instance of `IconCloseX16`.
   */
  icon?: ResponsiveValue<React.ReactElement>;

  /**
   * Position of the icon within the container.
   * @default 'right'
   */
  iconPosition?: ResponsiveValue<'left' | 'right'>;

  /**
   * Vertical alignment of the icon within the container.
   * @default 'top'
   */
  iconVAlign?: ResponsiveValue<'top' | 'middle'>;

  /**
   * Whether or not the icon button should inherit the color of its container
   *
   * @default false
   * @deprecated
   */
  iconInheritsColor?: IconButtonProps['inheritsColor'];

  /**
   * The variant/style of the icon button.
   *
   * - `default`: The default look & feel of an icon button (no background).
   * - `knockout`: The icon color matches the text color. Good for when the button is sitting on top of other content.
   * - `inherited`: The icon color matches the text color of its container. Good for when on a solid background of an unknown color.
   *
   * Can be a responsive value.
   *
   * @default 'default'
   */
  iconVariant?: IconButtonProps['variant'];

  /**
   * Callback function for when the icon is clicked.
   * The icon is omitted when this handler is not specified.
   */
  onClickIcon?: () => void;
}

export type InteractableContainerProps<C extends ElementType = 'div'> =
  ExtendableBoxProps<C, Props>;

const DEFAULT_ICON = <IconCloseX16 />;
const DEFAULT_ICON_POSITION = 'right';
const DEFAULT_ICON_VALIGN = 'top';

/**
 * Use `InteractableContainer` to render an interactable `Box` with a clickable icon.
 */
const InteractableContainer = <C extends ElementType>({
  children,
  icon = DEFAULT_ICON,
  iconPosition = DEFAULT_ICON_POSITION,
  iconVAlign = DEFAULT_ICON_VALIGN,
  iconLabel,
  iconTitle,
  iconInheritsColor,
  iconVariant,
  onClickIcon,
  ...rootProps
}: InteractableContainerProps<C>) => {
  const screenSize = useScreenSize();

  const respIcon = getResponsiveValue(screenSize, icon, DEFAULT_ICON);
  const respIconPosition = getResponsiveValue(
    screenSize,
    iconPosition,
    DEFAULT_ICON_POSITION,
  );
  const respIconVAlign = getResponsiveValue(
    screenSize,
    iconVAlign,
    DEFAULT_ICON_VALIGN,
  );

  // TODO: we need to improve processStylingProps to create types that don't
  // trigger `neither type sufficiently overlaps with the other` errors
  const boxProps = processStylingProps(rootProps, 'InteractableContainer', {
    stylingProps: 'warn',
    dangerousStylingProps: 'rewrite',
  }) as unknown as BoxProps<C>;

  const classNameWithDangerouslySetClassName = classNames(
    styles.root,
    boxProps.className,
  );

  return (
    <Box {...boxProps} className={classNameWithDangerouslySetClassName}>
      {onClickIcon && (
        <Box
          className={classNames(
            styles.icon,
            styles[`position-${respIconPosition}`],
            styles[`align-${respIconVAlign}`],
          )}
        >
          <IconButton
            aria-label={iconLabel}
            title={iconTitle}
            onClick={onClickIcon}
            inheritsColor={iconInheritsColor}
            variant={iconVariant}
          >
            {respIcon}
          </IconButton>
        </Box>
      )}
      {children}
    </Box>
  );
};

export default InteractableContainer;
