import React, {
  CSSProperties,
  Fragment,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import {
  getResponsiveStylesForProperties,
  ResponsiveCssProperties,
} from '../_internal/responsiveStyling';
import { wasServerSideRendered } from '../_internal/ssr';
import type { NonResponsiveValue } from '../_internal/styling';
import useUniqueId from '../_internal/useUniqueId';

const CLASS_NAME_PREFIX = 'mrrp-'; // (translation: mode-react-responsive-prop)

interface RenderPropParams {
  /**
   * The unique class name that should be added directly to the rendered
   * element/component's `className`. The class name is referenced in any media
   * queries within `<style>` elements
   */
  className: string;

  /**
   * Any inline styles for non-responsive props that don't have any media
   * queries. These should be added/merged directly to the `style` of the
   * rendered element/component.
   */
  style: CSSProperties;
}

export interface ResponsiveBaseProps<
  OptionsValue extends ResponsiveCssProperties<NonResponsiveValue>,
> {
  /**
   * A render prop that renders the actual UI, given `className` & `style` props
   */
  children: (params: RenderPropParams) => ReactNode;

  /**
   * A look-up of CSS properties to responsive prop configurations
   */
  properties: OptionsValue;
}

/**
 * The `ResponsiveBase` component is used as a base render prop component to
 * convert a component's responsive props into CSS media queries so that the responsive styles
 * can display correctly on pre-rendered or server-side rendered pages.
 */
const ResponsiveBase = <
  OptionsValue extends ResponsiveCssProperties<NonResponsiveValue>,
>({
  children,
  properties,
}: ResponsiveBaseProps<OptionsValue>) => {
  const [mustSyncAfterHydration, syncAfterHydration] = useState(
    !wasServerSideRendered(),
  );
  const uniqueClassName = useUniqueId(CLASS_NAME_PREFIX);
  const { cssStyles, inlineStyles } = getResponsiveStylesForProperties(
    uniqueClassName,
    properties,
  );
  const hasCssStyles = cssStyles.length > 0;

  useEffect(() => {
    // The `useUniqueId` utility we use to generate class names is not stable
    // between server and client side renders. When server and client side class
    // names differ, React updates the component's nested style tag but not the
    // root class name. So, when using SSR and CSS styles, we force a component
    // re-render to fix any mismatch between the responsive styles and root
    // class name. We *must* include `mustSyncAfterHydration` in the `key` for the
    // `<style>` and root element. Without it, the Virtual DOM has the updated
    // `cssStyle` & `uniqueClassName` attributes, but the UI doesn't. So it doesn't
    // re-render.
    // TODO: Replace `useUniqueId` with the stable `React.useId` when we upgrade
    // to React 18 and remove `mustSyncAfterHydration`.
    if (hasCssStyles) {
      // SSR renders initialize this state with `false`. On hydration, the state
      // change triggers a full component re-render. If class names are the
      // same, we incur a useless DOM diff; if they are different React syncs
      // the root class name attribute and the style tag.
      syncAfterHydration(true);
    }
  }, [hasCssStyles]);

  return (
    <>
      {
        // writing a `<style>` for each so that if the component is re-rendered
        // with changed `properties` we only have to re-render the changes
        cssStyles.map(cssStyle => (
          <style
            key={`${mustSyncAfterHydration}-${cssStyle}`}
            data-testid="responsive-base-style"
            data-test-for={uniqueClassName}
          >
            {cssStyle}
          </style>
        ))
      }
      <Fragment key={`${mustSyncAfterHydration}-${uniqueClassName}`}>
        {children({
          // if there are no media query styles there's no need to add the `className`
          className: hasCssStyles ? uniqueClassName : '',

          style: inlineStyles,
        })}
      </Fragment>
    </>
  );
};

export default ResponsiveBase;
