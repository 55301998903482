export type Region = 'US' | 'UK';

export const US_REGION: Region = 'US';
export const UK_REGION: Region = 'UK';
export const US_HOST = 'www.stitchfix.com';
export const UK_HOST = 'www.stitchfix.co.uk';

export const DEFAULT_HOST_INFO: Record<
  string,
  { locale: 'en-US' | 'en-GB'; region: Region }
> = {
  // it's important for these domains to include the `www` subdomain, otherwise
  // Next.js i18n domain routing won't match. See:
  // https://nextjs.org/docs/advanced-features/i18n-routing#domain-routing
  [US_HOST]: { locale: 'en-US', region: US_REGION },
  [UK_HOST]: { locale: 'en-GB', region: UK_REGION },
};

export const VALID_REGIONS = new Set<string>(
  Object.values(DEFAULT_HOST_INFO).map(({ region }) => region),
);
/**
 * Returns whether or not the specified `value` is a valid region.
 */

export const isValidRegion = (value?: string): value is Region =>
  !!value && VALID_REGIONS.has(value);
/**
 * Validates that the specified `value` is a valid region. If it is, it returns it. Otherwise, it returns the default region
 */

export const getValidatedRegion = (value?: string): Region =>
  isValidRegion(value) ? value : US_REGION;
/**
 * Given the specified `hostName`, returns the matching host info for it (if it
 * exists).
 * @param hostName Full hostname (e.g. `www.stitchfix.com`)
 * @returns Host info containing the `host`, `locale` and
 * `region` for the specified `hostName`
 */

export const getHostInfo = (hostName?: string | null) => {
  const matchingHostInfo = Object.entries(DEFAULT_HOST_INFO).find(
    ([host]) => hostName?.toLowerCase() === host,
  );

  // `matchingHostInfo` is an array of `[host, hostInfo]` or `undefined`,
  // but for clarity we'll return an object with the `host` included with
  // `locale` & `region.
  return matchingHostInfo
    ? { host: matchingHostInfo[0], ...matchingHostInfo[1] }
    : undefined;
};
/**
 * The region to default to when none is specified.
 * Takes into account the `NEXT_PUBLIC_REGION` environment variable.
 */

export const DEFAULT_REGION: Region = getValidatedRegion(
  process.env.NEXT_PUBLIC_REGION,
);
