import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
import IconBase, { IconProps } from '../IconBase';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconCloseX24 = (baseProps: IconProps, ref: Ref<SVGSVGElement>) => {
  return <IconBase {...baseProps}>
        {({
      title,
      titleId,
      ...props
    }: SVGProps<SVGSVGElement> & SVGRProps) => <svg fill="none" height={24} viewBox="0 0 24 24" width={24} xmlns="http://www.w3.org/2000/svg" ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path clipRule="evenodd" d="m10.8688 12-6.86709-6.86956 1.13157-1.13117 6.86672 6.86913 6.8668-6.86913 1.1315 1.13117-6.8671 6.86956 6.867 6.8695-1.1315 1.1312-6.8667-6.8691-6.86664 6.8691-1.13157-1.1312z" fill="currentColor" fillRule="evenodd" /></svg>}
      </IconBase>;
};
const ForwardRef = forwardRef(IconCloseX24);
export default ForwardRef;