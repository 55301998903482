import { getWindow } from './getBrowserGlobals';

// Ideally we would use a module-scoped variable instead of data attached to the
// `window`, but there are situations where the versions of `mode-react` that
// Knit and a host app are using could be different. And in that case, the app
// would have the module-scoped variable set, but Knit wouldn't. Using a value
// attached to the window ensures that we always get the data even if there are
// multiple `mode-react` versions.
declare global {
  /**
   * An object shape that lives at `window.stitchfix`. Designed to be used for
   * global concerns. This interface may be extended by UI apps to add fields.
   *
   * For example:
   * ```tsx
   * // some-ui-app/app/src/setup.tsx
   * declare global {
   *   interface StitchfixGlobal {
   *     additionalProperty: string;
   *   }
   * }
   * ```
   */
  interface StitchfixGlobal {
    /**
     * Whether or not the React app was rendered server-side
     */
    wasServerSideRendered?: boolean;
  }
  interface Window {
    /**
     * Stitch Fix namespace used to globally pass data
     */
    stitchfix?: StitchfixGlobal;
  }
}

/**
 * Sets whether or not the containing React app was rendered server-side. This
 * information is specifically used for optimization purposes within the
 * initialization of the `useState()` Hook so that we can eagerly retrieve a
 * client-side only value (usually from `window`) prior to getting it in the
 * `useEffect` Hook.
 */
export const setServerSideRendered = (wasServerSideRendered = true): void => {
  const win = getWindow();

  if (win) {
    if (!win.stitchfix) {
      win.stitchfix = {};
    }

    win.stitchfix.wasServerSideRendered = wasServerSideRendered;
  }
};

/**
 * Returns whether or not the React app was rendered server-side.
 * @see `setServerSideRendered` for more info
 * @see `useMedia` for an example on how it is used
 */
export const wasServerSideRendered = (): boolean => {
  const window = getWindow();

  // If there is no window, we can assume the app is server side rendered
  if (!window) {
    return true;
  }

  return !!window?.stitchfix?.wasServerSideRendered;
};
