import {
  getBreakpoints,
  getScreenSizes,
  ScreenSizeName,
} from '../_internal/screenSizes';
import { ImageSource, ResponsiveImageSources } from './types';

const sortDescending = (a: number, b: number) => {
  return b - a;
};

/**
 * Sorts an object's keys by descending order
 * @param sources Object whose keys are numeric
 * @returns sorted array containing sources' keys in descending order
 */
export const sortSourcesKeys = (
  sources: Record<number, ImageSource>,
): number[] => {
  return Object.keys(sources).map(Number).sort(sortDescending);
};

/**
 * A map of the supported screen size names and their breakpoint pixel values.
 *
 * `ResponsiveImage` uses `(min-width: ${breakpoint}px)` to render an image above the specified breakpoint;
 * to better integrate this component with other Mode-React components using the Responsive Value props,
 * we map a given breakpoint to the next screen size, similarly to `useScreenSize`
 * (e.g., map the 'sm' breakpoint (556px) to the 'md' screen size).
 *
 * @returns { sm: 1, md: 560, lg: 900, xl: 1140, xxl: 1730 } when screen sizes are default.
 */
const getScreenSizeBreakpoints = (): Record<ScreenSizeName, number> => {
  // Default: ['xxl', 'xl', lg', 'md', 'sm']
  const screenSizesReversed = [...getScreenSizes()].reverse();
  // Default: { sm: 560, md: 900, lg: 1140, xl: 1730 }
  const breakpoints = getBreakpoints();
  const screenSizeBreakpoints = screenSizesReversed.map((size, i) =>
    i + 1 < screenSizesReversed.length
      ? [size, breakpoints[screenSizesReversed[i + 1]]]
      : [size, 1],
  );

  return Object.fromEntries(screenSizeBreakpoints);
};

/**
 * Converts all screen size name string keys in a ResponsiveImageSources object
 * to their respective numbered pixel values.
 *
 * Smallest screen size is used as the base source.
 *
 * @param sources An `ResponsiveImageSources` object whose keys are of type `ScreenSizeName`
 * @returns An object mapping screen size pixel numbers, or 'base', to their corresponding ImageSource
 */
export const convertAliases = (
  sources: ResponsiveImageSources,
): Record<number | string, ImageSource> => {
  const screenSizes = getScreenSizes();
  const breakpoints = getScreenSizeBreakpoints();

  return screenSizes.reduce((accessor, screenSize) => {
    const source = sources[screenSize];

    if (source) {
      const breakpoint = 'base' in accessor ? breakpoints[screenSize] : 'base';

      return { ...accessor, [breakpoint]: source };
    }

    return accessor;
  }, {});
};

/**
 * Generates the `srcSet` attribute value for a `<source>` element
 * @param srcSet An object with '1x' and '2x' properties and string values
 * @returns A concatenated string of srcSet's keys and values
 */
export const generateSrcSet = (srcSet: Record<'1x' | '2x', string>) => {
  return Object.entries(srcSet)
    .map(([density, path]) => `${path} ${density}`)
    .join(',');
};

/**
 * Helper for generating the attributes for a `<source>` element
 * @param source A `ImageSource` at the given screenSize
 * @param screnSize Breakpoint number pixel value corresponding to the screen size for source
 * @returns An array of objects used to create a `<source>` element's attributes
 */
export const generateSourcesProps = (
  source: ImageSource,
  screenSize?: number,
) => {
  const { webp, ...image } = source;
  const media = screenSize ? `(min-width: ${screenSize}px)` : undefined;

  if (webp !== undefined) {
    return [
      { source: webp, media, type: 'image/webp' },
      { source: image, media },
    ];
  }

  return [{ source: image, media }];
};

// JSON.stringify prints most data structures as readable strings, but prints
// nothing on undefined. String prints undefined as a readable string.
const formatValue = (value: unknown) => JSON.stringify(value) || String(value);

const validateRequiredScreenSize = (screenSize: string[]) => {
  const valid = screenSize.length > 0;

  if (!valid) {
    throw new Error(
      `Mode React: ResponsiveImage \`sources\` must specify at least one screen size; \`${
        getScreenSizes()[0]
      }\` is recommended.`,
    );
  }
};

const validateSourcesKey = (key: string) => {
  const screenSizes = getScreenSizes() as string[];
  const valid = screenSizes.includes(key);

  if (!valid) {
    throw new Error(
      `Mode React: Source key must be one of ${screenSizes.join(
        ', ',
      )}. Received ${key}`,
    );
  }

  return valid;
};

const validateSourceType = (source: ImageSource) => {
  const valid = typeof source === 'object';

  if (!valid) {
    throw new Error(
      `Mode React: An image source must be an Object. Received ${formatValue(
        source,
      )}`,
    );
  }
};

const validateDensitiesExists = (densitiesObject: {
  '1x': string;
  '2x': string;
}) => {
  const valid = Object.keys(densitiesObject).length === 2;

  if (!valid) {
    throw new Error(`Mode React: A source must contain 1x and 2x densities`);
  }
};

const validateDensityKey = (key: string) => {
  const valid = ['1x', '2x'].includes(key);

  if (!valid) {
    throw new Error(
      `Mode React: Density key must be either 1x or 2x. Received ${formatValue(
        key,
      )}`,
    );
  }
};

const validateDensityPath = (path: string) => {
  const valid = typeof path === 'string';

  if (!valid) {
    throw new Error(
      `Mode React: Path must be a string. Received ${formatValue(path)}`,
    );
  }
};

/**
 * Intended to validate sources prop from ResponsiveImage when not using typescript
 * @param sources prop to validate
 */
export const validateSources = (sources: ResponsiveImageSources) => {
  const sourceScreenSizes = Object.keys(sources || {});

  validateRequiredScreenSize(sourceScreenSizes);

  sourceScreenSizes.forEach(screenSize => {
    validateSourcesKey(screenSize);

    const source = sources[screenSize as ScreenSizeName];

    if (!source) return;

    validateSourceType(source);
    const sourceKeys = Object.keys(source);

    // This gets a little complicated. We have to be careful to preserve the
    // user's prop shape while separating the webp densities from the image
    // densities.
    const webpDensities = source.webp;
    let imageDensities: Omit<ImageSource, 'webp'> | null = null;

    if (
      // If the source prop is an object with no keys, create an empty image
      // densities object.
      sourceKeys.length === 0 ||
      // If the source prop has some non-webp keys, create an image densities
      // object with those keys.
      sourceKeys.some(sourceKey => sourceKey !== 'webp')
    ) {
      imageDensities = { '1x': source['1x'], '2x': source['2x'] };
    }

    [webpDensities, imageDensities].forEach(densitiesObject => {
      if (densitiesObject) {
        validateDensitiesExists(densitiesObject);

        Object.entries(densitiesObject).forEach(([densityKey, densityPath]) => {
          validateDensityKey(densityKey);
          validateDensityPath(densityPath);
        });
      }
    });
  });
};
