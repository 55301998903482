import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
import IconBase, { IconProps } from '../IconBase';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconArrowRight16 = (baseProps: IconProps, ref: Ref<SVGSVGElement>) => {
  return <IconBase {...baseProps}>
        {({
      title,
      titleId,
      ...props
    }: SVGProps<SVGSVGElement> & SVGRProps) => <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M10.7699 8.69226L6.90066 12.5011L7.88278 13.4988L12.9699 8.49111L13.4767 7.99225L12.9699 7.49339L7.89818 2.5011L6.91608 3.49883L10.7699 7.29226H2.52036V8.69226H10.7699Z" fill="currentColor" /></svg>}
      </IconBase>;
};
const ForwardRef = forwardRef(IconArrowRight16);
export default ForwardRef;