/**
 * Formatter used for consistent communication from logger.
 */
const formatLog = (text: string) => {
  return `sf-next: ${text}`;
};

/**
 * Determines if a log should be output or not.
 */
const shouldLog = (): boolean => {
  return process.env.NODE_ENV !== 'production';
};

/**
 * Log a warning.
 */
const warn = (text: string) => {
  if (!shouldLog()) {
    return;
  }

  // eslint-disable-next-line no-console
  console.warn(formatLog(text));
};

/**
 * Logging utility to be used for communicating with developers using components
 * from sf-next. These utilities SHOULD NOT be used for debugging during
 * development of sf-next itself. Use standard console.log/warn/error in
 * those cases to ensure eslint reminds you to remove them when done debugging.
 */
const logger = {
  warn,
};

export default logger;
