import React, {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
  memo,
} from 'react';
import Cookies from 'js-cookie';
import { wasServerSideRendered } from '../_internal/ssr';
import logger from '../_internal/logger';
import { getWindow } from '../_internal/getBrowserGlobals';

// Defines the set of valid themes
export const THEMES = ['brand-legacy', 'brand-2024-full'] as const;

export type Theme = (typeof THEMES)[number];

export const DEFAULT_THEME: Theme = 'brand-2024-full';

// Name of the cookie used for storing the user's theme preference
export const COOKIE_NAME = 'sf-mode-theme';

const ThemeContext = createContext<Theme | null>(null);

/**
 * Helper to validate that a passed theme is one of the possible themes.
 */
export const isValidTheme = (theme: string): theme is Theme =>
  THEMES.some(t => t === theme);

/**
 * Hook to return the current theme from the context.
 */
export const useTheme = (): Theme => {
  const theme = useContext(ThemeContext);

  return theme || DEFAULT_THEME;
};

/**
 * Inline JS script for applying the theme (from cookie) as a class to the top-level <html> document.
 * This is required to avoid "flicker" from the default theme when the browser loads the page, given
 * that our SSG/SSR pages are unaware of theme.
 *
 * Inspiration from https://github.com/pacocoursey/next-themes
 *
 * The javascript code below was run through terser (pasted into ./script.js in your working directory):
 *   $ terser --compress --mangle -- script.js | sed 's/\\/\\\\/g'
 *
 * Note the sed command is to preserve the backslashes in the final script output.
 *
 * ===
 *
 * (function () {
 *   const getCookieValueByName = name => (
 *     document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || null
 *   )
 *
 *   try {
 *     const theme = getCookieValueByName('sf-mode-theme');
 *
 *     if (theme) document.documentElement.classList.add(`theme--${theme}`);
 *   } catch (e) {}
 * })();
 */
const APPLY_THEME_FROM_COOKIE_SCRIPT =
  // eslint-disable-next-line no-template-curly-in-string
  `!function(){try{const t=(e="sf-mode-theme",document.cookie.match(new RegExp("(^|;)\\\\s*"+e+"\\\\s*=\\\\s*([^;]+)"))?.pop()||"${DEFAULT_THEME}");t&&document.documentElement.classList.add(\`theme--\${t}\`)}catch(e){}var e}();`;

const ApplyThemeOnLoadScript = memo(
  ({ theme }: { theme?: Theme }) => {
    const script = theme
      ? `!function(){try{document.documentElement.classList.add('theme--${theme}')}catch(e){}var e}();`
      : APPLY_THEME_FROM_COOKIE_SCRIPT;

    return (
      <script
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: script }}
        data-testid="theme-script"
      />
    );
  },
  // Never re-render this component
  () => true,
);

const getValidThemeFromCookie = (): Theme | null => {
  const theme = Cookies.get(COOKIE_NAME);

  return theme && isValidTheme(theme) ? theme : null;
};

/**
 * Sets a CSS class for the given theme on the top-level document `<html>` element
 */
const setRootElementTheme = (theme: Theme) => {
  const className = `theme--${theme}`;
  const classList = getWindow()?.document.documentElement.classList;

  if (classList) {
    // remove any existing `theme--*` classes:
    for (const rootClass of [...classList]) {
      if (rootClass.startsWith('theme--')) {
        classList.remove(rootClass);
      }
    }
    // add the new theme class:
    classList.add(className);
  }
};

type ThemeProviderProps = {
  children: ReactNode;
  /** Prop to set a custom theme. Overrides the theme set in the cookie. */
  theme?: Theme;
};

const TopLevelThemeProvider = ({
  children,
  theme: propTheme,
}: ThemeProviderProps) => {
  const [themeFromCookie, setThemeFromCookie] = useState<Theme | null>(() =>
    // If we are hydrating a SSR page, don't use the cookie value on the first render, to avoid
    // hydration mismatch errors.
    // If this is client-rendered only (ie rails app), we are ok to render with the cookie-based
    // theme from the start, which avoids a "UI flicker" from the default theme to the cookie's theme.
    wasServerSideRendered() ? null : getValidThemeFromCookie(),
  );

  // Updates the theme state if a valid theme is set in the cookie and no prop
  // theme is explicitly provided
  useEffect(() => {
    const cachedTheme = getValidThemeFromCookie();

    if (cachedTheme) {
      setThemeFromCookie(cachedTheme);
    }
  }, []);

  // This priority order is important:
  // - The `theme` prop should always take precedence, so that we can override e.g. for
  //   testing/storybook/onboarding
  // - Otherwise if the cookie exists (set by knit: https://github.com/stitchfix/knit/pull/994),
  //   we will respect it
  // - Finally the default theme ('brand-legacy') is used as a fallback
  const theme = propTheme || themeFromCookie || DEFAULT_THEME;

  // Add the theme class to the root `<html>` element:
  useEffect(() => {
    setRootElementTheme(theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={theme}>
      {wasServerSideRendered() && <ApplyThemeOnLoadScript theme={propTheme} />}
      {children}
    </ThemeContext.Provider>
  );
};

/**
 * Adds support for mode-react theming. Should wrap the entire page's react
 * tree.
 *
 * Note that theming is applied after the first render, so as not to cause
 * hydration errors with server-rendered page.
 *
 * Theme is set by the 'sf-mode-theme' cookie. To override, pass a `theme` prop:
 *
 *     <ThemeProvider theme="brand-2024-full">{...}</ThemeProvider>
 */
const ThemeProvider = (props: ThemeProviderProps) => {
  const { children, theme } = props;
  const contextTheme = useContext(ThemeContext);

  // If a parent <ThemeProvider> exists, nested <ThemeProvider>s have no effect:
  if (contextTheme) {
    if (theme) {
      logger.warn(
        'Nested `<ThemeProvider theme="...">` rendered within a parent `<ThemeProvider>`. `theme` prop overrides should only be set on the top-level `<ThemeProvider>`.',
      );
    }

    return <>{children}</>;
  }

  return <TopLevelThemeProvider {...props} />;
};

export default ThemeProvider;
