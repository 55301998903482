import React, {
  Children,
  type ElementType,
  forwardRef,
  type FunctionComponent,
  type ReactNode,
} from 'react';
import classNames from 'classnames';
import { PolymorphicRef } from '../_internal/components';
import {
  ResponsiveSizeValue,
  processStylingProps,
  DeprecatedAndDangerousStylingProps,
  ResponsiveValue,
  NonResponsiveValue,
} from '../_internal/styling';
import Divider, { DividerProps } from '../Divider';
import Box, { ExtendableBoxProps, BoxProps } from '../Box';

import ResponsiveBase from '../ResponsiveBase';

type HorizontalAlign = 'stretch' | 'start' | 'center' | 'end';

const HORIZONTAL_ALIGN_MAP: Record<HorizontalAlign, string> = {
  start: 'flex-start',
  center: 'center',
  end: 'flex-end',
  stretch: 'stretch',
};

interface Props extends DeprecatedAndDangerousStylingProps {
  /**
   * One or more elements to vertically evenly space. String must be wrapped in `<Fragment>`.
   */
  children: ReactNode;
  /**
   * The type of divider with which to separate the stack items. Leave unspecified for no divider.
   */
  divider?: DividerProps['variant'];
  /**
   * Controls the horizontal alignment
   *
   * @default 'stretch'
   */
  hAlign?: ResponsiveValue<HorizontalAlign>;
  /**
   * The spacing between the stack items. Can specify any CSS unit with a string value.
   * A numeric value maps to our spacing unit (i.e. `1rem`)
   */
  spacing: ResponsiveSizeValue;
}

export type StackProps<C extends ElementType = 'div'> = ExtendableBoxProps<
  C,
  Props
>;

type StackComponent = FunctionComponent &
  (<C extends ElementType = 'div'>(
    props: StackProps<C>,
  ) => React.ReactElement | null);

/**
 * Use `Stack` to evenly space out elements or components in the vertical direction.
 */
export const Stack: StackComponent = forwardRef(
  <C extends ElementType = 'div'>(
    {
      children,
      divider,
      hAlign = 'stretch',
      spacing,
      ...rootProps
    }: StackProps<C>,
    ref?: PolymorphicRef<C>,
  ) => {
    // TODO: we need to improve processStylingProps to create types that don't
    // trigger `neither type sufficiently overlaps with the other` errors
    const { style, className, ...boxProps } = processStylingProps(
      rootProps,
      'Stack',
      {
        stylingProps: 'warn',
        dangerousStylingProps: 'rewrite',
      },
    ) as unknown as BoxProps<C>;

    const isList = rootProps.as === 'ol' || rootProps.as === 'ul';
    const stackItems = Children.toArray(children)
      // We need to ensure that when we call `.map()` that we only have renderable items so that the
      // index calculations for when to include margins are correct.
      // TODO remove this ts-ignore (and the check `child !== false`) once we are fully on react 18.
      // @ts-ignore - Previously with react v17 we need to explicitly filter out `false` values -
      // with v18 it looks that `Children.toArray` handles this for us.
      .filter(child => child !== undefined && child !== null && child !== false)
      .map((child, index) => {
        let includedDivider;

        if (divider && index > 0) {
          includedDivider = (
            <Box mb={spacing}>
              <Divider variant={divider} data-testid="stack-divider" />
            </Box>
          );
        }

        return (
          <Box
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            data-testid="stack-item"
            as={isList ? 'li' : undefined}
            mt={index > 0 ? spacing : undefined}
          >
            {includedDivider}
            {child}
          </Box>
        );
      });

    // If there end up being no items to display,
    // we'll just return null instead of rendering
    // an empty container
    if (!stackItems.length) {
      return null;
    }

    const properties = {
      alignItems: {
        responsiveValue: hAlign,
        defaultValue: 'stretch',
        mapCssValue: (val: NonResponsiveValue) =>
          HORIZONTAL_ALIGN_MAP[val as HorizontalAlign],
      },
    };

    // Typescript's inferred type for these polymorphic props produce a cryptic error when
    // passed to Box (starting with React v18). We can coerce the type to BoxProps<C> to
    // avoid the error.
    const coercedBoxProps = boxProps as BoxProps<C>;

    return (
      <ResponsiveBase properties={properties}>
        {({ className: responsiveClassName, style: responsiveStyle }) => (
          <Box
            data-testid="stack"
            ref={ref}
            className={classNames(responsiveClassName, className)}
            display="flex"
            style={{ flexDirection: 'column', ...responsiveStyle, ...style }}
            {...coercedBoxProps}
          >
            {stackItems}
          </Box>
        )}
      </ResponsiveBase>
    );
  },
);

Stack.displayName = 'Stack';

export default Stack;
