import { OverridableStringUnion } from './components';

/**
 * ScreenSizeName: The name of a range of screen pixel widths
 *
 * Constructed by combining ScreenSizeNameDefaults with ScreenSizeNameOverrides.
 *
 * Users can customize the ScreenSizeName type by using declaration merging to
 * add to ScreenSizeNameOverrides. For example,
 *
 *   declare module '@stitch-fix/mode-react/types' {
 *     interface ScreenSizeNameOverrides {
 *       foo: true;
 *       xl: false;
 *     }
 *   }
 *
 * would add the 'foo' size name and remove the 'xl' size name.
 *
 * ScreenSizeNameOverrides is an empty interface to allow removal of default
 * screen-size names. TypeScript declaration merging only allows members to be
 * overriden with the same type. Since the 'xl' member of ScreenSizeNameDefaults
 * has a literal type of 'true', it can't be directly overriden with 'xl:
 * false'. Instead, you can merge 'xl: false' into ScreenSizeNameOverrides,
 * which is empty.
 *
 * The OverridableStringUnion utility type combines the 'defaults' and
 * 'overrides' interfaces, then uses Extract to construct the ScreenSizeName
 * string-union type.
 *
 * Inspired by material-ui:
 *
 *   https://material-ui.com/customization/breakpoints/#custom-breakpoints
 */
type ScreenSizeNameDefaults = Record<'sm' | 'md' | 'lg' | 'xl' | 'xxl', true>;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ScreenSizeNameOverrides {}
export type ScreenSizeName = OverridableStringUnion<
  ScreenSizeNameDefaults,
  ScreenSizeNameOverrides
>;

type ScreenSizeTuple = [ScreenSizeName, number?];

const validateScreenSizes = (screenSizes: ScreenSizeTuple[]): void => {
  const sizesWithoutValues = screenSizes.filter(([, value]) => !value);

  if (sizesWithoutValues.length !== 1) {
    throw new Error(
      `Mode React: Exactly one screen size (the largest one) should have no corresponding value. Found ${sizesWithoutValues.length}: ${sizesWithoutValues}`,
    );
  }
};

const ascendingScreenSizes = (
  [, a]: ScreenSizeTuple,
  [, b]: ScreenSizeTuple,
): number => {
  // A screen size without a value is assumed to be the max size
  if (!a) {
    return 1;
  }
  if (!b) {
    return -1;
  }

  return a - b;
};

const DEFAULT_SORTED_SCREEN_SIZES: ScreenSizeTuple[] = [
  ['sm', 560],
  ['md', 900],
  ['lg', 1140],
  ['xl', 1730],
  ['xxl'],
];

let SORTED_SCREEN_SIZES: ScreenSizeTuple[];

/**
 * Customize app-wide screen size names and breakpoint widths
 */
export const setScreenSizes = (screenSizes: ScreenSizeTuple[]): void => {
  validateScreenSizes(screenSizes);
  SORTED_SCREEN_SIZES = [...screenSizes].sort(ascendingScreenSizes);
};

/**
 * Reset default screen size names and breakpoint widths
 * FOR TESTING USE ONLY!
 */
export const resetScreenSizes = (): void => {
  setScreenSizes(DEFAULT_SORTED_SCREEN_SIZES);
};

/**
 * Get a list of screen size names, ordered from smallest to largest
 */
export const getScreenSizes = (): ScreenSizeName[] =>
  SORTED_SCREEN_SIZES.map(([name]) => name);

/**
 * Get a map of the available breakpoints and their pixels
 */
export const getBreakpoints = (): Record<ScreenSizeName, number> => {
  const breakpointSizes = SORTED_SCREEN_SIZES.filter(([, width]) => width);

  return Object.fromEntries(breakpointSizes);
};

/**
 * Set default app-wide screen sizes for apps using `mode-react`. Apps that need
 * to define their own custom screen sizes and breakpoint widths can call
 * `setScreenSizes()` to override the defaults.
 */
setScreenSizes(DEFAULT_SORTED_SCREEN_SIZES);
