import React from 'react';
import useUniqueIdLegacy from './legacy';

const usePrefixedId = (prefix: string): string => {
  const id = React.useId()
    // it's possible that the ID will have special characters in it. We want to
    // ensure that it can be a valid CSS ID or class name, so we'll replace any
    // invalid characters with underscores. From what we can tell the special
    // character is always a `:` so there should be little chance of name
    // collisions, but in theory that could change in the future. More info:
    // https://github.com/facebook/react/issues/26839
    .replace(/[^A-Za-z0-9-_]/g, '_');

  return `${prefix}${id}`;
};

// `React.useId` is a new hook in React 18. We want to use it if it's available
// but some apps still may be using older versions of React. In which case,
// we'll fall back to our own implementation which has known SSR hydration
// issues. In particular, the global ID counter will continue to increment on
// the server, but the client will start from 0 again. This will result in a
// mismatch between the server and client markup.
const useSupportedUniqueId =
  typeof React.useId !== 'undefined' ? usePrefixedId : useUniqueIdLegacy;

/**
 * Returns a unique ID that will stay the same across re-renders
 * @param prefix Optional prefix for the ID (defaults to `"id"`)
 */
const useUniqueId = (prefix = 'id'): string => useSupportedUniqueId(prefix);

export default useUniqueId;
