import React, { ReactNode, createContext, useContext } from 'react';
import i18next, { type i18n as I18nType, type Resource } from 'i18next';
import {
  initReactI18next,
  Trans as TransBase,
  useTranslation as useTranslationBase,
} from 'react-i18next';
import Bugsnag from '@bugsnag/js';
import type { I18nextUserConfig, Locale } from './types';
import { DEFAULT_LOCALE, DEFAULT_NAMESPACE, I18NEXT_CONFIG } from './constants';
import { getValidatedLocale } from '.';
import { isExistingBundle, isValidLocale } from './utils';

/**
 * Context carrying the internationalization (I18n) information
 */
export const I18nContext = createContext<{ locale: Locale }>({
  locale: DEFAULT_LOCALE,
});

interface I18nProviderProps {
  /**
   * The app/page contents
   */
  children?: ReactNode;

  /**
   * The app locale
   */
  locale: string;
}

const checkLocale = (locale: I18nProviderProps['locale']) => {
  if (!isValidLocale(locale)) {
    const error = new Error(
      `Invalid locale used for I18nProvider: '${locale}'`,
    );

    if (process.env.NODE_ENV === 'production') {
      Bugsnag.notify(error);
    } else {
      throw error;
    }
  }
};

/**
 * Puts the internationalization (I18n) information in the React context
 */
export const I18nProvider = ({ children, locale }: I18nProviderProps) => {
  checkLocale(locale);

  const value = { locale: getValidatedLocale(locale) };

  return <I18nContext.Provider value={value}>{children}</I18nContext.Provider>;
};

/**
 * Retrieves the internalization (I18n) information anywhere in the
 * component tree when nested within an `I18nProvider`.
 * @returns I18n information
 */
export const useI18n = () => useContext(I18nContext);

type UseTranslationArgs = Parameters<typeof useTranslationBase>;

interface TFunctionOptions {
  keyPrefix?: string;
  ns?: string;
  [interpolationKey: string]: unknown;
}
export type TFunction = (key: string, options?: TFunctionOptions) => string;

const genUseTranslation = (
  i18n: I18nType,
  namespace: UseTranslationArgs[0],
) => {
  /**
   * A Hook that returns the `t` function and the current locale.
   */
  const useTranslation = () => {
    const { locale } = useI18n();
    const { t: origT, ready } = useTranslationBase(namespace, {
      i18n,
      lng: locale,
    });

    const t = origT as TFunction;

    return { locale, ready, t };
  };

  return useTranslation;
};

const genLoadTranslations = (i18n: I18nType) => {
  /**
   * Loads namespaced translations to the i18n instance
   *
   * @param translations Translations to load - nested map of locale -> namespace -> translation key/value pairs
   * @param namespace Unique namespace for these translations, usually sharing
   *   the name of a react component
   * @returns `useTranslation` Hook, scoped to the given namespace
   */
  const loadTranslations = (translations: Resource, namespace: string) => {
    Object.entries(translations).forEach(
      ([translationLocale, localeTranslations]) => {
        // Why we call from Object.prototype - https://eslint.org/docs/rules/no-prototype-builtins
        if (
          namespace !== DEFAULT_NAMESPACE &&
          !Object.prototype.hasOwnProperty.call(localeTranslations, namespace)
        ) {
          throw new Error(
            `Namespace '${namespace}' is missing in the translation json`,
          );
        }

        // We need to add the resource bundle to the i18n instance, but if we're
        // requesting the default namespace, we assume that the translations
        // don't have a namespace key
        const resourceBundle =
          namespace === DEFAULT_NAMESPACE
            ? localeTranslations
            : localeTranslations[namespace];

        if (!i18n.hasResourceBundle(translationLocale, namespace)) {
          i18n.addResourceBundle(translationLocale, namespace, resourceBundle);
        } else if (
          !isExistingBundle(
            i18n.getResourceBundle(translationLocale, namespace),
            resourceBundle,
          )
        ) {
          // If the bundle already exists AND it's a different bundle than what
          // we're trying to load, that's a namespace collision.
          throw new Error(
            `Namespace '${namespace}' for different translations has already been loaded`,
          );
        }

        // But if it's the same bundle it could be a result of a React Fast
        // Refresh on the page that's re-calling all the functions in module
        // scope in order to maintain state. In which case we can skip
        // adding it again.
      },
    );

    return {
      useTranslation: genUseTranslation(i18n, namespace),
    };
  };

  /**
   * Loads translations to the i18n instance for the default namespace
   *
   * @param translations Translations to load - nested map of locale -> namespace -> translation key/value pairs
   * @returns `useTranslation` Hook, scoped to the default namespace
   */
  const loadDefaultTranslations = (translations: Resource) => {
    return loadTranslations(translations, DEFAULT_NAMESPACE);
  };

  return {
    loadTranslations,
    loadDefaultTranslations,
  };
};

/**
 * Initializes the i18n instance, returning a function to load translations
 * scoped to the `i18n` instance.
 */
export const initI18n = (userConfig: I18nextUserConfig = {}) => {
  const i18n = i18next.createInstance({
    ...I18NEXT_CONFIG,
    ...userConfig,
    lng: DEFAULT_LOCALE,
  });

  i18n.use(initReactI18next).init();

  return {
    ...genLoadTranslations(i18n),
  };
};

type TransProps = Parameters<typeof TransBase>[0];

// The `t` we want in our custom `Trans` component needs to be the same type
// that's returned by `useTranslation`
type CustomTransProps = Omit<TransProps, 't'> & {
  t: TFunction;
};

/**
 * Enables nesting any React content to be translated as one cohesive string. It
 * supports both plurals and interpolation.
 */
export const Trans = (customProps: CustomTransProps) => {
  const { t: customT } = customProps;

  // Our `customT` is a valid `t` function, but the types don't match up nicely.
  // So we transform its type so that it'll work with the main `Trans`
  // component.
  const t = customT as TransProps['t'];
  const props = { ...customProps, t };

  return TransBase(props);
};
