import generateId from '@stitch-fix/log-weasel';
import { addToQueue, setupQueue } from '../eventQueue';

// Defines the required event keys. Exported to ease type definitions in
// consuming apps.
interface Event {
  action_name: string;
  action_type: string;
  canonical_name: string;
  event_name: string;
  event_request?: {
    referrer?: string;
  };
  routing_key?: string;
  // TODO: switch source_app type to `one of` our app names
  source_app: string;
}

interface ReportEventArgs {
  event: Event & {
    // Allows additional fields to be set on the event. In the future we'll only
    // allow a union of more strict event schemas.
    [key: string]: unknown;
  };
  requestId?: string;
  url?: string;
}

let reportEventInitiated = false;

const setupReportEvent = () => {
  if (!reportEventInitiated) {
    setupQueue();
    reportEventInitiated = true;
  }
};

const reportEvent = ({ event, requestId, url }: ReportEventArgs) => {
  setupReportEvent();

  const resolvedUrl = url || '/api/events_collector';
  const resolvedRequestId = requestId || generateId(event.source_app);
  const resolvedReferrer =
    event.event_request?.referrer || window.document.referrer;

  const body = JSON.stringify({
    event: {
      // Date.now() facilitates Jest testing
      client_timestamp: new Date(Date.now()).toISOString(),
      routing_key: null,
      ...event,
      event_request: {
        full_url: window.location.href,
        referer: resolvedReferrer,
      },
    },
  });

  // Keepalive is only necessary when there's potential that the user will immediately navigate
  // away from this page (e.g. clicking a link, submitting a form)
  const useKeepalive =
    event.action_type === 'click' ||
    event.action_type === 'submit' ||
    event.action_type === 'event';

  addToQueue({
    body,
    requestId: resolvedRequestId,
    url: resolvedUrl,
    keepalive: useKeepalive,
  });
};

export default reportEvent;
export type { Event, ReportEventArgs };
