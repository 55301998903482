import React, {
  type ElementType,
  forwardRef,
  type FunctionComponent,
  type ReactElement,
} from 'react';
import classNames from 'classnames';
import ButtonBase, {
  ExtendableButtonBaseProps,
  ButtonBaseProps,
} from '../ButtonBase';
import { IconProvider } from '../IconBase';
import {
  processStylingProps,
  DeprecatedAndDangerousStylingProps,
  getResponsiveValue,
  ResponsiveValue,
} from '../_internal/styling';
import { PolymorphicRef } from '../_internal/components';
import logger from '../_internal/logger';

import styles from './iconButton.module.scss';

import useScreenSize from '../_internal/useScreenSize';

type Variant = 'default' | 'knockout' | 'inherited' | 'overlay';

interface Props extends DeprecatedAndDangerousStylingProps {
  /**
   * The label for the action
   * (communicates the purpose of the button to users)
   */
  'aria-label': string;

  /**
   * The icon.
   * Only one element is allowed.
   */
  children: ReactElement;

  /**
   * (Deprecated) Whether or not the icon button should inherit the color of its container
   *
   * @default false
   * @deprecated Use `variant` prop instead
   */
  inheritsColor?: boolean;

  /**
   * The variant/style of the icon button.
   *
   * - `default`: The default look & feel of an icon button (no background).
   * - `knockout`: The icon color matches the text color. Good for when the button is sitting on top of other content.
   * - `inherited`: The icon color matches the text color of its container. Good for when on a solid background of an unknown color.
   *
   * Can be a responsive value.
   *
   * @default 'default'
   */
  variant?: ResponsiveValue<Variant>;
}

export type IconButtonProps<C extends ElementType = 'button'> =
  ExtendableButtonBaseProps<C, Props>;

type IconButtonComponent = FunctionComponent &
  (<C extends ElementType = 'button'>(
    props: IconButtonProps<C>,
  ) => React.ReactElement | null);

/**
 * Icon buttons allow users to take actions represented by icons instead of described with text.
 *
 * Icon buttons are commonly used when there is limited space and the icon clearly describes the action.
 */
export const IconButton: IconButtonComponent = forwardRef(
  <C extends ElementType = 'button'>(
    {
      'aria-label': ariaLabel,
      children,
      inheritsColor = false,
      variant,
      disabled,
      ...rootProps
    }: IconButtonProps<C>,
    ref?: PolymorphicRef<C>,
  ) => {
    const screenSize = useScreenSize();
    const buttonBaseProps = processStylingProps(rootProps, 'IconButton', {
      stylingProps: 'warn',
      dangerousStylingProps: 'rewrite',
    }) as ButtonBaseProps<C>;
    let responsiveVariant: Variant | undefined;

    // if `variant` is defined, it wins over deprecated `inheritsColor`.
    // but use `inheritsColor` if specified and there's no `variant`.
    // otherwise default to `default` variant
    if (variant) {
      responsiveVariant = getResponsiveValue(screenSize, variant);
    } else if (inheritsColor) {
      responsiveVariant = 'inherited';

      logger.warn(
        'The `inheritsColor` prop is deprecated in `IconButton`. Use `variant="inherited"` instead.',
      );
    }

    // TypeScript is already verifying that this is passed, but for those not
    // using TypeScript, we need to make sure that the button is accessible.
    if (!ariaLabel) {
      logger.warn(
        'IconButton must specify an `aria-label` to satisfy accessibility needs',
      );
    }

    const className = classNames(
      styles.root,
      buttonBaseProps.className,
      responsiveVariant ? styles[responsiveVariant] : styles.default,
    );

    return (
      <ButtonBase
        aria-label={ariaLabel}
        disabled={disabled}
        ref={ref}
        {...buttonBaseProps}
        className={className}
      >
        <IconProvider defaultPurpose="decorative">{children}</IconProvider>
      </ButtonBase>
    );
  },
);

IconButton.displayName = 'IconButton';

export default IconButton;
