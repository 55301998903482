import { createActorContext } from '@xstate/react';
import { useRouter } from 'next/router';
import { type ReactNode } from 'react';
import { useNavigationContext } from '../../../../components/OnboardingVision/NavigationProvider';
import {
  type FlowContext as FlowMachineContext,
  flowMachine,
} from './flow-machine';
import { getStepPath } from './utils';

export const FlowContext = createActorContext(flowMachine);

export const FlowProvider = ({
  children,
  customContext,
}: {
  children: ReactNode;
  customContext?: FlowMachineContext;
}) => {
  const { onBackNavigation, onForwardNavigation } = useNavigationContext();
  const router = useRouter();

  const machineWithConfig = flowMachine.withConfig(
    {
      services: {
        handleFlowNavigation: async (
          { flow, previousStepPath, nextStepPath },
          event,
        ) => {
          if (event.type === 'BACK' && previousStepPath) {
            onBackNavigation();
            router.push(previousStepPath);
          }

          if (event.type === 'NEXT' && nextStepPath) {
            onForwardNavigation();
            router.push(nextStepPath);
          }

          if (event.type === 'PAYMENT_ERROR_REDIRECT') {
            const path = getStepPath({ flow, identifier: 'payment' });

            onBackNavigation();
            router.push(path);
          }

          return event.type;
        },
      },
    },
    customContext,
  );

  return (
    <FlowContext.Provider machine={() => machineWithConfig}>
      {children}
    </FlowContext.Provider>
  );
};
