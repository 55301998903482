import Cookies from 'js-cookie';
import { throttle } from 'lodash';
import { v4 as uuid } from 'uuid';
import { ActiveSessionId } from '../types/events';

const ACTIVE_SESSION_COOKIE = 'active_session';

const getActiveSession = () => Cookies.get(ACTIVE_SESSION_COOKIE);

const setActiveSession = (id: string) => {
  const in30Minutes = new Date(Date.now() + 30 * 60000);

  Cookies.set(ACTIVE_SESSION_COOKIE, id, {
    expires: in30Minutes,
  });
};

/**
 * A throttled function that updates the expiry of the session token so
 * it stays active for another 30 minutes
 */
const extendActiveSessionExpiry = throttle(
  () => {
    const activeSession = getActiveSession();

    if (activeSession) setActiveSession(activeSession);
  },
  60000, // 1 minute
  { leading: true },
);

/**
 * Manages the Algos active user session. Calling the function returns the
 * ActiveSessionId and also resets the session expiry to 30 minutes from time of
 * execution.
 */
const getAndTouchActiveSession = (): ActiveSessionId => {
  const activeSession = getActiveSession();

  if (activeSession) {
    extendActiveSessionExpiry();

    return activeSession;
  }

  const newSession = uuid();

  // We do not run the throttled function, so we can guarantee the cookie is set when a new session is started
  setActiveSession(newSession);

  return newSession;
};

export {
  ACTIVE_SESSION_COOKIE,
  getAndTouchActiveSession,
  extendActiveSessionExpiry,
};
