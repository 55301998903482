import { getValidatedLocale } from '@stitch-fix/i18n';
import type { Locale } from '../types/i18n';

export {
  getValidatedLocale,
  isValidLocale,
  VALID_LOCALES,
} from '@stitch-fix/i18n';

export const EN_US_LOCALE: Locale = 'en-US';
export const EN_GB_LOCALE: Locale = 'en-GB';

/**
 * The locale to default to when none is specified.
 * Takes into account the `NEXT_PUBLIC_LOCALE` environment variable.
 */
export const DEFAULT_LOCALE: Locale = getValidatedLocale(
  process.env.NEXT_PUBLIC_LOCALE,
);
