import React, { ReactNode, ElementType, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Transition } from 'react-transition-group';
import classnames from 'classnames';
import { PolymorphicComponentProps } from '../_internal/components';
import Box, { type BoxProps } from '../Box';
import { MarginProps } from '../_internal/spacing';
import {
  processStylingProps,
  DeprecatedAndDangerousStylingProps,
} from '../_internal/styling';
import { getDocument } from '../_internal/getBrowserGlobals';

import styles from './toast.module.scss';

export const Timeouts = {
  EnterAutoDismiss: 4000,
  EnterManagedDismiss: 0,
  Exit: 300,
};

interface BaseProps extends DeprecatedAndDangerousStylingProps {
  /**
   * Toast message contents.
   */
  children: ReactNode;
  /**
   * The number of milliseconds to wait before automatically calling the `onClose` function. `onClose` should then set the state of the `isOpen` prop to hide the Toast. Defaults to 4000.
   */
  delay?: number;
  /**
   * Content placed to the right of the toast message `children`.
   */
  endContent?: ReactNode;
  /**
   * Flag to determine if toast should appear or be removed.
   */
  isOpen?: boolean;
  /**
   * Callback function invoked `onEntered` after the Toast has completed `entering` based on the enter delay.
   *
   * This handler is used to provide "auto dismissal" and is expected to handle updating `isOpen` prop to `false`.
   *
   * Toast will be dismissed and removed after `isOpen` prop is set to `false`.
   */
  onClose?: () => void;
  /**
   * Content placed to the left of the toast message `children`.
   */
  startContent?: ReactNode;
}

type Props = BaseProps & MarginProps;

export type ToastProps<C extends ElementType = 'div'> =
  PolymorphicComponentProps<C, Props>;

/**
 * Toasts are brief, peripheral, ephemeral messages that signal the completion of an action taken by the user or the product itself. For example, having added something to your shopping cart.
 *
 * Toasts do not need any decoration and, in general, should not contain rich media. They do not need a close icon as they are short and should auto-dismiss.
 *
 * Toasts should be:
 *
 * - Informational
 * - Temporary
 * - Contextual
 * - Additive, non-essential
 * - If there is no action link in the toast, the toast width should be the width of the text + padding and horizontally centered.
 *
 */
const Toast = <C extends ElementType>({
  children,
  delay,
  endContent,
  isOpen,
  onClose,
  startContent,
  ...rootProps
}: ToastProps<C>) => {
  const [portalTarget, setPortalTarget] = useState<HTMLElement | null>(null);
  const boxProps = processStylingProps(rootProps, 'Toast', {
    stylingProps: 'warn',
    dangerousStylingProps: 'rewrite',
  }) as Partial<BoxProps>;

  // The enter timeout controls the amount of time between when the component is `entering` to when it has `entered`.
  // This timeout is used to "auto dismiss" the Toast `onEntered` by invoking the `onClose` method which controls setting the `isOpen` flag.

  // Provided `delay` will override any default.
  // A default 4s timeout is used to "auto dismiss" the Toast if the user provides an `onClose` method.
  // A default 0s timeout is used to allow the user control of when the Toast dismisses if user does not provide an `onClose` method.
  const timeout = {
    enter:
      delay ||
      (onClose ? Timeouts.EnterAutoDismiss : Timeouts.EnterManagedDismiss),
    exit: Timeouts.Exit,
  };

  useEffect(() => {
    const target = getDocument()?.body;

    if (target) {
      setPortalTarget(target);
    }
  }, []);

  return (
    portalTarget &&
    createPortal(
      <Transition
        in={isOpen}
        onEntered={onClose}
        timeout={timeout}
        appear
        mountOnEnter
        unmountOnExit
      >
        {state => (
          <Box
            className={classnames(styles.container, {
              [styles['container-exit']]: state === 'exiting',
            })}
          >
            <Box
              role="status"
              aria-live="polite"
              className={styles.toast}
              {...boxProps}
            >
              <Box className={styles['content-container']}>
                {startContent && (
                  <div className={styles['start-content']}>{startContent}</div>
                )}
                {children}
              </Box>
              {endContent && (
                <div className={styles['end-content']}>{endContent}</div>
              )}
            </Box>
          </Box>
        )}
      </Transition>,
      portalTarget,
    )
  );
};

export default Toast;
