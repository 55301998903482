import React, {
  type ElementType,
  type MouseEvent,
  type HTMLAttributes,
  type ButtonHTMLAttributes,
  forwardRef,
} from 'react';
import type {
  ExtendableProps,
  PolymorphicComponentProps,
  PolymorphicRef,
  PropsWithRef,
} from '../_internal/components';
import type { ElementStylingProps } from '../_internal/styling';
import type { MarginProps } from '../_internal/spacing';
import Box, { type BoxProps } from '../Box';

type BaseProps = {
  /**
   * Whether or not the button is disabled.
   */
  // We need to specifically define this prop even though
  // <button> has it because the parent can be using
  // <a> or other elements that don't have the prop. We
  // wanna make sure it always exists
  disabled?: boolean;
};

type Props = BaseProps & MarginProps & ElementStylingProps;

/**
 * Allows for the props of a parent component to extend
 * the props of `ButtonBase` *before* they are merged with the
 * props of the underlying element
 */
export type ExtendableButtonBaseProps<
  C extends ElementType = 'button',
  OverrideProps = {},
> = PropsWithRef<
  C,
  PolymorphicComponentProps<C, ExtendableProps<Props, OverrideProps>>
>;

export type ButtonBaseProps<C extends ElementType = 'button'> =
  ExtendableButtonBaseProps<C>;

type ButtonBaseComponent = <C extends ElementType = 'button'>(
  props: ButtonBaseProps<C>,
) => React.ReactElement | null;

const ButtonBase: ButtonBaseComponent = forwardRef(
  <C extends ElementType = 'button'>(
    {
      as,
      onClick,
      disabled,
      m,
      mt,
      mb,
      my,
      ml,
      mr,
      mx,
      style,
      ...rootProps
    }: ButtonBaseProps<C>,
    ref?: PolymorphicRef<C>,
  ) => {
    const buttonAs = as || 'button';
    const marginProps = { m, mt, mb, my, ml, mr, mx };
    const defaultProps: HTMLAttributes<HTMLElement> = {};

    if (buttonAs === 'button') {
      (defaultProps as ButtonHTMLAttributes<HTMLButtonElement>).type = 'button';
      // add `aria-disabled`, we don't use the `disabled` property for <button> because that disables
      // all interactions (ie, Tooltips on hover)

      if (disabled) {
        defaultProps['aria-disabled'] = disabled;
        defaultProps.tabIndex = -1;
      }
    } else if (disabled || onClick) {
      // If the button is not a <button> element, but we need to disable it
      // or click it, we need to add ARIA hints

      if (disabled) {
        defaultProps['aria-disabled'] = disabled;
      }

      if (buttonAs !== 'label') {
        // if we're adding an `onClick` we need to add a `'button'` role to
        // the element. we also need to add it to the tab order with `tabIndex`.
        // we don't, however, need to do this for <label> elements because they
        // area already naturally tabbable/focusable/clickable.
        defaultProps.role = 'button';
        defaultProps.tabIndex = disabled ? -1 : 0;
      }
    }

    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
      // If the component is disabled we need to prevent clicking going to the
      // `href`, calling the `onClick`, or calling `onSubmit` of a <form>
      if (disabled) {
        e.preventDefault();
      }

      if (onClick && !disabled) {
        onClick(e);
      }
    };
    // Typescript's inferred type for these polymorphic props produce a cryptic error when
    // passed to Box (starting with React v18). We can coerce the type to BoxProps<C> to
    // avoid the error.
    const coercedBoxProps = {
      as: buttonAs,
      ...defaultProps,
      ...marginProps,
      ...rootProps,
    } as BoxProps<C>;

    return (
      <Box onClick={handleClick} style={style} ref={ref} {...coercedBoxProps} />
    );
  },
);

export default ButtonBase;
