import { getValidatedLocale } from '..';

const optionConfig = {
  default: { day: 'numeric', month: 'numeric', year: 'numeric' },
  short: { day: 'numeric', month: 'short' },
  long: { day: 'numeric', month: 'long', year: 'numeric' },
} as const;

type FormatDateFormat = keyof typeof optionConfig;

/**
 * Format the specified date for the specified locale.
 * @returns Formatted date string.
 */
export const formatDateForLocale = (
  locale: string,
  date: Date,
  format: FormatDateFormat = 'default',
): string =>
  date
    .toLocaleString(getValidatedLocale(locale), optionConfig[format])
    // Match rails' default format, which uses hyphens instead of slashes:
    .replace(/\//g, '-');
