import React from 'react';
import { getScreenSizes } from '../_internal/screenSizes';
import ResponsiveImage, { ResponsiveImageProps } from '../ResponsiveImage';
import { ResponsiveImageSources } from '../ResponsiveImage/types';
import { CloudinaryImageSources, CloudinaryImageTransforms } from './types';
import generateCloudinaryUrl from './CloudinaryImageHelpers';

/**
 * Default recommended transformations.
 */
const requiredTransformations: CloudinaryImageTransforms = {
  /**
   * Allows optimal image compression.
   * See Cloudinary docs: https://cloudinary.com/documentation/transformation_reference#q_quality
   */
  q: 'auto',
  /**
   * Scales image so that it takes up as much space as possible
   * within width (w) x height (h) bounding box while maintaining original aspect ratio;
   * alternatively, use `c: "fill"` to resize image to specified width and height.
   * See Cloudinary docs: https://cloudinary.com/documentation/transformation_reference#c_crop_resize
   */
  c: 'fit',
};

const generateResponsiveImageSources = ({
  baseUrl,
  mediaPath,
  sources,
  transforms,
}: Omit<CloudinaryImageProps, 'alt'>): ResponsiveImageSources => {
  const screenSizes = getScreenSizes();

  return screenSizes.reduce((accessor, screenSize) => {
    const source = sources[screenSize];

    if (!source) return accessor;

    const { transforms: sourceTransforms } = source;
    const w = 'w' in source ? source.w : null;
    const h = 'h' in source ? source.h : null;

    const transforms1x = {
      ...requiredTransformations,
      ...transforms,
      ...(w && { w }),
      ...(h && { h }),
      ...sourceTransforms,
    };
    const transforms2x = {
      ...transforms1x,
      dpr: '2.0',
    };
    const transformsWebP1x = {
      ...transforms1x,
      f: 'webp',
    };
    const transformsWebP2x = {
      ...transforms2x,
      f: 'webp',
    };

    return {
      ...accessor,
      [screenSize]: {
        '1x': generateCloudinaryUrl(baseUrl, mediaPath, transforms1x),
        '2x': generateCloudinaryUrl(baseUrl, mediaPath, transforms2x),
        webp: {
          '1x': generateCloudinaryUrl(baseUrl, mediaPath, transformsWebP1x),
          '2x': generateCloudinaryUrl(baseUrl, mediaPath, transformsWebP2x),
        },
      },
    };
  }, {});
};

export interface CloudinaryImageProps
  extends Pick<
    ResponsiveImageProps,
    'alt' | 'lazy' | 'onLoad' | 'objectFit' | 'objectPosition' | 'fetchPriority'
  > {
  /**
   * The base URL for an image hosted on Cloudinary (or Cloudfront CDN).
   *
   * Cloudinary example: `https://res.cloudinary.com/stitch-fix/image/upload/`
   *
   * Cloudfront example: `https://d3n78nkjl8tizo.cloudfront.net/stitch-fix/image/upload/`
   */
  baseUrl: string;
  /**
   * A Cloudinary image's name (version and/or public id)
   *
   * Example: `/v1585276216/a6ttgw2xjomdl1pbzehz.jpg`
   */
  mediaPath: string;
  /**
   * Allows specifying the image's dimensions, width and/or height, at each screen size.
   * Mode-React's default screen sizes include `sm`, `md`, `lg`, `xl`, `xxl`.
   *
   * Recommended: always define an image source for the `sm` screen size.
   *
   * Example:
   * ```
   * {
   *   sm: { w: '200', h: '200' }
   * }
   * ```
   */
  sources: CloudinaryImageSources;
  /**
   * Allows applying additional image transformations across all screen sizes.
   * By default, `CloudinaryImage` applies image compression quality 'auto' and crop mode 'fit'.
   *
   * Example recommended transforms for SkuCard image:
   * ```
   * {
   *   e: ['trim', 'replace_color:f2f3f4:300:ffffff']
   * }
   * ```
   */
  transforms?: CloudinaryImageTransforms;
}

/**
 * Use `CloudinaryImage` to display optimized images hosted on Cloudinary.
 *
 * The `CloudinaryImage` component allows specifying the desired image's dimensions responsively, using Mode-React's screen sizes – `sm`, `md`, `lg`, `xl`, `xxl`.
 *
 * Given the `baseUrl` and `mediaPath` for an image hosted on Cloudinary (or served through the Cloudfront CDN),
 * the `CloudinaryImage` component renders a `ResponsiveImage`, automatically optimizing for a client's:
 *
 * - Device pixel ratio (i.e., image optimized for low and hi-res displays)
 * - Browser's supported image formats (i.e., image displayed is WebP)
 *
 * `CloudinaryImage` utilizes Cloudinary's URL based transformations ([learn more](https://cloudinary.com/documentation/transformation_reference)).
 */
const CloudinaryImage = ({
  alt,
  baseUrl,
  lazy,
  mediaPath,
  sources,
  transforms,
  onLoad,
  objectFit,
  objectPosition,
  fetchPriority,
}: CloudinaryImageProps): JSX.Element => {
  const responsiveImageSources = generateResponsiveImageSources({
    baseUrl,
    mediaPath,
    sources,
    transforms,
  });

  return (
    <ResponsiveImage
      alt={alt}
      lazy={lazy}
      sources={responsiveImageSources}
      onLoad={onLoad}
      objectFit={objectFit}
      objectPosition={objectPosition}
      fetchPriority={fetchPriority}
    />
  );
};

export default CloudinaryImage;
