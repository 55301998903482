import React, {
  forwardRef,
  type ElementType,
  type FunctionComponent,
} from 'react';
import classNames from 'classnames';
import {
  ExtendableProps,
  PolymorphicComponentProps,
  PolymorphicRef,
  PropsWithRef,
} from '../_internal/components';
import { Color } from '../_internal/colors';
import { MarginProps } from '../_internal/spacing';
import { ResponsiveValue, ElementStylingProps } from '../_internal/styling';
import Box from '../Box';
import ResponsiveBase from '../ResponsiveBase';

import styles from './text.module.scss';

type Setting =
  | 'title-xxlarge'
  | 'title-xlarge'
  | 'title-large'
  | 'title-medium'
  | 'title-small'
  | 'title-xsmall'
  | 'title-xxsmall'
  | 'subtitle-medium'
  | 'subtitle-small'
  | 'eyebrow-medium'
  | 'eyebrow-small'
  | 'display-xlarge'
  | 'display-large'
  | 'display-medium'
  | 'body-large'
  | 'body-large-fixed'
  | 'body-medium'
  | 'body-medium-fixed'
  | 'body-small'
  | 'body-small-fixed'
  | 'body-xsmall'
  | 'body-xsmall-fixed';

type TextAlign = 'left' | 'right' | 'center' | 'justify' | 'start' | 'end';

type CommonProps = {
  /**
   * Type alignment. Use keyword values. The value will be overridden if you set
   * `textAlign` using `style`
   */
  align?: ResponsiveValue<undefined | TextAlign>;

  /**
   * Type color. Use semantic names (such as `brand-mint`) before using color
   * names.
   */
  color?: Color;

  /**
   * Maximum number of lines of text. Any text that would extend past these
   * lines gets truncated and replaced with an ellipsis.
   */
  maxLines?: 1 | 2 | 3;

  /**
   * If `true`, the text will have a bottom margin relative to its `setting`.
   * This allows for quick spacing of text with the content below it. This value
   * will be overridden if you change the `display` or the bottom `margin` using
   * `style`, `m`, `my`, or `mb`.
   *
   * Only applies for block-level text like `<p>`.
   */
  spacingBottom?: boolean;

  /**
   * Variations on how the text is displayed.
   * @default 'default'
   */
  variant?: 'default' | 'knockout' | 'knockout-inverse';
};

type SettingProps = {
  /**
   * Responsive type family/scale/height combination for the majority of text
   * displays
   */
  setting?: Setting;

  // Cannot specify height/family/scale when specifying setting
  height?: undefined;
  family?: undefined;
  scale?: undefined;
};
type StandardProps = {
  /**
   * Type line height, only to be specified if a predefined `setting` is
   * inadequate.
   */
  height?: 'standard';
  /**
   * Type family, only to be specified if a predefined `setting` is inadequate.
   */
  family?: 'regular' | 'medium';
  /**
   * Type scale, only to be specified if a predefined `setting` is inadequate.
   */
  scale?:
    | '1'
    | '2'
    | '3'
    | '4'
    | '5'
    | '6'
    | '7'
    | '8'
    | '9'
    | '10'
    | '11'
    | '12';

  // Cannot specify setting when specifying height/family/scale
  setting?: undefined;
};
type ShortProps = {
  height?: 'short';
  family?: 'regular' | 'medium';
  scale?: '1' | '2' | '3' | '4';

  // Cannot specify setting when specifying height/family/scale
  setting?: undefined;
};
type TallProps = {
  height?: 'tall';
  family?: 'regular' | 'medium' | 'italic';
  scale?: '2' | '3' | '4';

  // Cannot specify setting when specifying height/family/scale
  setting?: undefined;
};
// NOTE: This separation of props prevents invalid combinations such as a
// `setting` prop with a `scale` prop or a `height` of 'tall' prop with a
// `scale` of '10'
type ScaleProps = SettingProps | StandardProps | ShortProps | TallProps;
type Props = CommonProps & ScaleProps & MarginProps;

/**
 * Allows for the props of a parent component to extend the props of `Text`
 * *before* they are merged with the props of the underlying element
 */
export type ExtendableTextProps<
  C extends ElementType = 'span',
  OverrideProps = {},
> = PropsWithRef<
  C,
  PolymorphicComponentProps<C, ExtendableProps<Props, OverrideProps>>
>;

export type TextProps<C extends ElementType = 'span'> = ExtendableTextProps<
  C,
  ElementStylingProps
>;

const SETTING_ELEMENT_MAPPING: Record<Setting, ElementType> = {
  'title-xxlarge': 'h1',
  'title-xlarge': 'h1',
  'title-large': 'h2',
  'title-medium': 'h2',
  'title-small': 'h3',
  'title-xsmall': 'h3',
  'title-xxsmall': 'h3',
  'subtitle-medium': 'h4',
  'subtitle-small': 'h4',
  'display-xlarge': 'h2',
  'display-large': 'h2',
  'display-medium': 'h2',
  'eyebrow-medium': 'h2',
  'eyebrow-small': 'h2',
  'body-large': 'p',
  'body-large-fixed': 'p',
  'body-medium': 'p',
  'body-medium-fixed': 'p',
  'body-small': 'p',
  'body-small-fixed': 'p',
  'body-xsmall': 'p',
  'body-xsmall-fixed': 'p',
};

type TextComponent = FunctionComponent &
  (<C extends ElementType = 'span'>(
    props: TextProps<C>,
  ) => React.ReactElement | null);

/**
 * Use text to present your design and content as clearly and efficiently as
 * possible.
 */
export const Text: TextComponent = forwardRef(
  <C extends ElementType>(
    {
      setting,
      as,
      align,
      height,
      family,
      maxLines,
      scale,
      color,
      variant = 'default',
      spacingBottom,
      m,
      mt,
      mb,
      my,
      ml,
      mr,
      mx,
      style,
      children,
      ...htmlAttributes
    }: TextProps<C>,
    ref?: PolymorphicRef<C>,
  ) => {
    // the default mapping can be overridden by the `as` prop
    const textAs = as || (setting ? SETTING_ELEMENT_MAPPING[setting] : 'span');
    const marginProps = { m, mt, mb, my, ml, mr, mx };
    let typeClass;

    if (setting) {
      // check if setting is set first
      typeClass = setting;
    } else if (height || family || scale) {
      // otherwise if height/family/scale are specified, we'll use them (but
      // make sure the others are defaulted)
      const fontHeight = height || 'standard';
      const fontFamily = family || 'regular';
      const fontScale = scale || '2';

      typeClass = `${fontHeight}-${fontFamily}-${fontScale}`;
    }

    const className = classNames(typeClass ? styles[typeClass] : undefined, {
      [styles['has-bottom-spacing']]: spacingBottom,
      [styles[`max-lines-${maxLines}`]]: maxLines,
      [styles[variant]]: variant !== 'default',
    });

    const isKnockout = variant === 'knockout' || variant === 'knockout-inverse';

    let processedColor = color;

    if (!processedColor && isKnockout) {
      // Use default colors for knockout variant if color is not set.
      processedColor = variant === 'knockout' ? 'gray-16' : 'white';
    }

    return (
      <ResponsiveBase
        properties={{
          textAlign: { responsiveValue: align },
        }}
      >
        {({ className: renderPropClassName, style: responsiveStyle }) => {
          // For knockout variants, we use a child span, so we can adjust
          // line-height relative to the parent settings using ems.
          const content = isKnockout ? (
            <span className={styles['knockout-wrapper']}>{children}</span>
          ) : (
            children
          );

          return (
            <Box
              ref={ref}
              as={textAs}
              color={processedColor}
              style={{ ...responsiveStyle, ...style }}
              {...marginProps}
              {...htmlAttributes}
              className={classNames(
                className,
                htmlAttributes.className,
                renderPropClassName,
              )}
            >
              {content}
            </Box>
          );
        }}
      </ResponsiveBase>
    );
  },
);

Text.displayName = 'Text';

export default Text;
