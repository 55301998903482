import Bugsnag, { BrowserConfig } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import settings from './settings';
import logger from './logger';
import { getAuthenticatedClient } from '../ClientAuthContext/utils';
import { getWindow } from './getBrowserGlobals';

/**
 * Gets info about the authenticated client stripping out any PII.
 */
export const getSafeClientInfo = () => {
  const { client } = getAuthenticatedClient();

  if (!client) {
    return undefined;
  }

  // Leave off email, name and other PII
  const { clientId, businessLine, externalId, locale, region } = client;

  return { clientId, businessLine, externalId, locale, region };
};

interface InitBugsnagOptions {
  /**
   * Whether or not to enable error logging when running in development. By
   * default, there is no error monitoring in non-Production environment to
   * avoid unnecessary reporting.
   */
  debug?: boolean;

  /**
   * Diagnostic metadata to send with all captured events
   */
  metadata?: BrowserConfig['metadata'];

  /**
   * Callback(s) to modify or discard error events before they are sent to Bugsnag.
   * See [Customizing error reports](https://docs.bugsnag.com/platforms/javascript/react/customizing-error-reports/#updating-events-using-callbacks) for more info.
   */
  onError?: BrowserConfig['onError'];
}

/**
 * Initialize Bugsnag error monitoring.
 * Ensure this is called as early as possible in application code.
 */
export const initBugsnag = ({
  debug = false,
  metadata = {},
  onError,
}: InitBugsnagOptions = {}) => {
  const win = getWindow();

  // Do not attempt to initialize Bugsnag when running server-side
  // (until full SSR w/ Node Runtime)
  if (!win) {
    return;
  }

  const apiKey = settings.bugsnagClientApiKey;

  if (!apiKey) {
    logger.warn(
      'Unable to initialize Bugsnag because the `apiKey` is missing. Visit https://github.com/stitchfix/sf-next/blob/beta/docs/bugsnag.md for setup instructions.',
    );

    return;
  }

  const enabledReleaseStages = debug
    ? ['development', 'production']
    : ['production'];

  Bugsnag.start({
    apiKey,
    appVersion: settings.appVersion,
    enabledReleaseStages,
    metadata,
    onError,
    plugins: [new BugsnagPluginReact()],
    releaseStage: settings.env,
  });
};
