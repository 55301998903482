import {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import type { AuthenticatedClient } from '@stitch-fix/client-facing-auth';
import { getVisitorUuid } from '@stitch-fix/client-facing-auth/browser';
import { getAuthenticatedClient } from './utils';

/** The status of the auth information:
 *
 * - `'loading'` - The authentication status is being retrieved
 * - `'unauthenticated'` - The user is **not** logged in
 * - `'authenticated'` - The client is logged in
   - `'error'` - An error ocurred while retrieving authentication info
 */
export type AuthStatus =
  | 'loading'
  | 'unauthenticated'
  | 'authenticated'
  | 'error';

export interface AuthInfo {
  /**
   * The status of the auth information:
   *
   * - `'loading'` - The authentication status is being retrieved
   * - `'unauthenticated'` - The user is **not** logged in
   * - `'authenticated'` - The client is logged in
   * - `'error'` - An error ocurred while retrieving authentication info
   */
  authStatus: AuthStatus;

  /**
   * The client auth information
   */
  client?: AuthenticatedClient;

  /**
   * The error that occurred while retrieving the auth information
   */
  error?: Error;

  /**
   * The UUID for the visitor that's available regardless of logged-in state
   */
  visitorUuid?: string;
}

const DEFAULT_AUTH_INFO: AuthInfo = {
  authStatus: 'loading',
  client: undefined,
  error: undefined,
  visitorUuid: undefined,
};

/**
 * Context carrying the information on the authenticated client
 */
export const ClientAuthContext = createContext(DEFAULT_AUTH_INFO);

interface ClientAuthProviderProps {
  /**
   * The app/page contents
   */
  children?: ReactNode;
}

/**
 * Puts the client auth information in the React context
 */
export const ClientAuthProvider = ({ children }: ClientAuthProviderProps) => {
  const [authInfo, setAuthInfo] = useState<AuthInfo>(DEFAULT_AUTH_INFO);

  // In the browser, we *could* retrieve the authenticated client outside of
  // `useEffect()`, but that would mean that hydration wouldn't match what was
  // pre-rendered. So we always have to do a 2-pass render. First time at
  // hydration always has `'loading'` state, and then on mount in `useEffect()`
  // we get the authenticated state.
  useEffect(() => {
    const { client, error } = getAuthenticatedClient();
    const visitorUuid = getVisitorUuid();

    let authStatus: AuthStatus = 'unauthenticated';

    if (error) {
      authStatus = 'error';
    } else if (client) {
      authStatus = 'authenticated';
    }

    setAuthInfo({
      authStatus,
      client,
      error,
      visitorUuid,
    });
  }, []);

  return (
    <ClientAuthContext.Provider value={authInfo}>
      {children}
    </ClientAuthContext.Provider>
  );
};

/**
 * Retrieves the client auth information anywhere in the component tree.
 * @returns Client auth information
 */
export const useClientAuth = () => useContext(ClientAuthContext);
