import generateId from '@stitch-fix/log-weasel';
import { debounce } from 'lodash';
import bugsnagNotify, { ErrorMetadata } from '../bugsnagNotify';
import { CompoundEvent } from '../types/events';

const maxBatchSize = 20;
const debounceDelay = 200; // milliseconds
const debounceMaxDelay = 1000; // milliseconds

let queue: CompoundEvent[] = [];

interface PostBatchArgs {
  events: CompoundEvent[];
  keepalive: boolean;
}

type ReportErrorArgs = {
  error: Error;
  errorName: string;
  metadata?: ErrorMetadata;
};

const postBatch = ({ events, keepalive }: PostBatchArgs) => {
  if (events.length === 0) return;

  const sourceApp = events[0].screen_view.source_app;
  const requestId = generateId(sourceApp);
  const body = JSON.stringify({
    events,
  });

  const reportError = ({
    error,
    errorName,
    metadata = {},
  }: ReportErrorArgs) => {
    // Reassigning `name` allows us to maintain the original stack and message
    // without complex error cloning. This gives developers immediate signal
    // of what this error is within bugsnag.
    // eslint-disable-next-line no-param-reassign
    error.name = errorName;

    bugsnagNotify({
      error,
      // We group these into single errors irrespective of which specific event
      // or component resulted in an error, since the cause (network errors) is
      // not dependent on the event or component, and developers can snooze
      // these behind a single threshold in bugsnag.
      group: error.name,
      metadata: {
        ...metadata,
        body,
        requestId,
      },
    });
  };

  window
    .fetch('/api/v2/events_collector', {
      body,
      credentials: 'same-origin',
      headers: {
        'X-Request-Id': requestId,
        'Content-Type': 'application/json',
      },
      keepalive,
      method: 'POST',
      mode: 'same-origin',
    })
    .then(async response => {
      if (!response.ok) {
        const responseBody = await response.text();

        let responseJson;

        try {
          responseJson = JSON.parse(responseBody);
        } catch {
          // response is not json, fallback to generic error
        }

        const invalidEvents = responseJson?.invalid_events;

        if (invalidEvents) {
          reportError({
            error: new Error('Invalid event schema'),
            errorName: 'EventReporterSchemaError',
            metadata: { invalidEvents },
          });
        } else {
          reportError({
            error: new Error(
              `Server responded with a ${response.status} status`,
            ),
            errorName: 'EventReporterAPIError',
            metadata: { responseBody, responseStatus: response.status },
          });
        }
      }
    })
    .catch((error: Error) => {
      reportError({ error, errorName: 'EventReporterNetworkError' });
    });
};

const flushQueue = () => {
  postBatch({ events: queue, keepalive: false });

  queue = [];
};

const debounceFlushQueue = debounce(flushQueue, debounceDelay, {
  maxWait: debounceMaxDelay,
});

const addToQueue = (event: CompoundEvent) => {
  queue.push(event);

  if (queue.length >= maxBatchSize) {
    flushQueue();
    debounceFlushQueue.cancel();
  } else {
    debounceFlushQueue();
  }
};

const reportEvent = (event: CompoundEvent) => {
  // Screen views function as an on/off switch for child events. We put this guard here
  // instead of in the event hooks so that the jest mock will still capture an event when
  // there is no screen view, as is the case when testing isolated components.
  if (!event.screen_view) return;

  if (event.type === 'select') {
    // Report selects immediately with keepalive, as we may be clicking a link
    // that leaves this window context
    postBatch({ events: [event], keepalive: true });
  } else {
    addToQueue(event);
  }
};

export { debounceDelay, debounceMaxDelay, maxBatchSize };
export default reportEvent;
