import Bugsnag from '@bugsnag/js';
import type { Locale, I18nextConfig } from './types';

export const DEFAULT_LOCALE: Locale = 'en-US';
export const VALID_LOCALES: Set<Locale> = new Set(['en-US', 'en-GB']);
export const DEFAULT_NAMESPACE = 'default';

export const I18NEXT_CONFIG: I18nextConfig = {
  // missing translations will fallback to en-US copy
  fallbackLng: DEFAULT_LOCALE,
  defaultNS: DEFAULT_NAMESPACE,
  interpolation: {
    // mimic rails' i18n interpolation
    prefix: '%{',
    suffix: '}',
    // React will already escape unsafe html, we don't want to double escape
    escapeValue: false,
  },
  // considers our translation loading to be synchronous, so as not to trigger react suspense
  initImmediate: false,
  // required for missingKeyHandler to be called
  saveMissing: true,
  missingKeyHandler: (_locale, namespace, key) => {
    const error = new Error(`Missing translation: '${namespace}:${key}'`);

    if (process.env.NODE_ENV === 'production') {
      Bugsnag.notify(error);
    } else {
      throw error;
    }
  },
  // return empty string if a translation key is missing:
  parseMissingKeyHandler: () => '',
};
