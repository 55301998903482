import { getScreenSizes, getBreakpoints, ScreenSizeName } from './screenSizes';
import { useAtBreakpoint } from './useBreakpoints';

/**
 * A hook that returns the current screen size name, updating the value as the screen size changes
 */
const useScreenSize = (): ScreenSizeName => {
  // Screen widths above a given breakpoint is the *next* window size.
  // So after the `md` breakpoint (900px) is large, x-large & xx-large screens.
  // See: https://www.figma.com/file/1Ur9AHpn5YZfQ4cva8cm4L/%E2%80%93-mode-%E2%80%93-notions?node-id=3067%3A6058

  // NOTE: Because hooks cannot be conditional, this creates n `matchMedia` handlers
  // (where n is the number of breakpoints) for *every* rendered component this hook
  // is called, whether or not it actually has responsive props. Underneath we are
  // caching MediaQueryList objects in `useMedia`. We're banking on the hope that the
  // browsers adequately optimize the adding/removal of change handlers.
  const screenSizesReversed = [...getScreenSizes()].reverse();
  const breakpoints = getBreakpoints();
  const sizeMatches = screenSizesReversed.map((size, i) =>
    i + 1 < screenSizesReversed.length
      ? {
          size,
          /*
           * You're not supposed to call a hook within a loop, but it's safe
           * here because we *know* the looping logic depends only on constants.
           * The same sequence of `useAtBreakpoint` hooks will be called every
           * time.
           */
          // eslint-disable-next-line react-hooks/rules-of-hooks
          matches: useAtBreakpoint(breakpoints[screenSizesReversed[i + 1]]),
        }
      : {
          size,
          matches: true,
        },
  );

  // Because we start w/ the largest screen and go down, we know that
  // once we have a match, that is the screen size. The screen is somewhere
  // between the breakpoint and the next breakpoint - 1 because the media
  // query for the higher breakpoint didn't match.
  const foundMatch = sizeMatches.find(({ matches }) => matches);

  return foundMatch?.size || 'sm';
};

export default useScreenSize;
