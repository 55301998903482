import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
import IconBase, { IconProps } from '../IconBase';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconSocialTiktok24 = (baseProps: IconProps, ref: Ref<SVGSVGElement>) => {
  return <IconBase {...baseProps}>
        {({
      title,
      titleId,
      ...props
    }: SVGProps<SVGSVGElement> & SVGRProps) => <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M16.6895 0C17.0856 3.40571 18.9867 5.43618 22.2921 5.65218V9.48271C20.3765 9.66991 18.6986 9.04349 16.7471 7.86266V15.0269C16.7471 24.128 6.82382 26.9721 2.83434 20.4487C0.270704 16.2509 1.84057 8.88509 10.0644 8.58988V12.6292C9.43787 12.73 8.76815 12.8884 8.15605 13.0972C6.32694 13.7164 5.28996 14.8757 5.57801 16.9206C6.1325 20.8375 13.3193 21.9967 12.7216 14.3429V0.00720023H16.6895V0Z" fill="currentColor" /></svg>}
      </IconBase>;
};
const ForwardRef = forwardRef(IconSocialTiktok24);
export default ForwardRef;