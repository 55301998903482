import { ResponsiveSizeValue, NonResponsiveValue } from './styling';
import { ResponsiveCssProperties } from './responsiveStyling';

type AllMarginProps = {
  /**
   * Set margin for all 4 sides of the container
   */
  m?: ResponsiveSizeValue;

  // Can't specify the other values if the overall margin is set
  mt?: undefined;
  mb?: undefined;
  my?: undefined;
  ml?: undefined;
  mr?: undefined;
  mx?: undefined;
};
type XYMarginProps = {
  /**
   * Set horizontal margin (both left & right) for the container
   */
  mx?: ResponsiveSizeValue;
  /**
   * Set vertical margin (both top & bottom) for the container
   */
  my?: ResponsiveSizeValue;

  // Can specify just the directional props together
  m?: undefined;
  ml?: undefined;
  mr?: undefined;
  mt?: undefined;
  mb?: undefined;
};

type XTopBottomMarginProps = {
  mx?: ResponsiveSizeValue;
  /**
   * Set top margin for the container
   */
  mt?: ResponsiveSizeValue;
  /**
   * Set bottom margin for the container
   */
  mb?: ResponsiveSizeValue;

  // Can specify the X with the top & bottom individual props
  m?: undefined;
  my?: undefined;
  ml?: undefined;
  mr?: undefined;
};
type YLeftRightMarginProps = {
  my?: ResponsiveSizeValue;
  /**
   * Set left margin for the container
   */
  ml?: ResponsiveSizeValue;
  /**
   * Set right margin for the container
   */
  mr?: ResponsiveSizeValue;

  // Can specify the Y with the left & right individual props
  m?: undefined;
  mx?: undefined;
  mt?: undefined;
  mb?: undefined;
};
type IndividualMarginProps = {
  mt?: ResponsiveSizeValue;
  mb?: ResponsiveSizeValue;
  ml?: ResponsiveSizeValue;
  mr?: ResponsiveSizeValue;

  // Can specify the individual sides by themselves
  m?: undefined;
  mx?: undefined;
  my?: undefined;
};

type AllPaddingProps = {
  /**
   * Set padding for all 4 sides of the container
   */
  p?: ResponsiveSizeValue;

  // Can't specify the other values if the overall padding is set
  pt?: undefined;
  pb?: undefined;
  py?: undefined;
  pl?: undefined;
  pr?: undefined;
  px?: undefined;
};
type XYPaddingProps = {
  /**
   * Set horizontal padding (both left & right) for the container
   */
  px?: ResponsiveSizeValue;
  /**
   * Set vertical padding (both top & bottom) for the container
   */
  py?: ResponsiveSizeValue;

  // Can specify just the directional props together
  p?: undefined;
  pl?: undefined;
  pr?: undefined;
  pt?: undefined;
  pb?: undefined;
};

type XTopBottomPaddingProps = {
  px?: ResponsiveSizeValue;
  /**
   * Set top padding for the container
   */
  pt?: ResponsiveSizeValue;
  /**
   * Set bottom padding for the container
   */
  pb?: ResponsiveSizeValue;

  // Can specify the X with the top & bottom individual props
  p?: undefined;
  py?: undefined;
  pl?: undefined;
  pr?: undefined;
};
type YLeftRightPaddingProps = {
  py?: ResponsiveSizeValue;
  /**
   * Set left padding for the container
   */
  pl?: ResponsiveSizeValue;
  /**
   * Set right padding for the container
   */
  pr?: ResponsiveSizeValue;

  // Can specify the Y with the left & right individual props
  p?: undefined;
  px?: undefined;
  pt?: undefined;
  pb?: undefined;
};
type IndividualPaddingProps = {
  pt?: ResponsiveSizeValue;
  pb?: ResponsiveSizeValue;
  pl?: ResponsiveSizeValue;
  pr?: ResponsiveSizeValue;

  // Can specify the individual sides by themselves
  p?: undefined;
  px?: undefined;
  py?: undefined;
};

// NOTE: This separation of spacing prop definitions prevents invalid
// combinations, such as `mx` with `ml` or `p` with `py`, for component
// interfaces
export type MarginProps =
  | AllMarginProps
  | XYMarginProps
  | XTopBottomMarginProps
  | YLeftRightMarginProps
  | IndividualMarginProps;
export type PaddingProps =
  | AllPaddingProps
  | XYPaddingProps
  | XTopBottomPaddingProps
  | YLeftRightPaddingProps
  | IndividualPaddingProps;

// These are "loose" versions of the spacing prop definitions that make it
// easier to pass all the properties around in helper functions
export type LooseMarginProps = Partial<
  Record<keyof MarginProps, ResponsiveSizeValue>
>;
export type LoosePaddingProps = Partial<
  Record<keyof PaddingProps, ResponsiveSizeValue>
>;

const toResponsiveSpacingProperties = (
  type: 'margin' | 'padding',
  {
    all,
    top,
    bottom,
    y,
    left,
    right,
    x,
  }: Record<string, ResponsiveSizeValue | undefined>,
): ResponsiveCssProperties<NonResponsiveValue> => {
  const allProperties = {
    [`${type}Top`]: y ?? top,
    [`${type}Right`]: x ?? right,
    [`${type}Bottom`]: y ?? bottom,
    [`${type}Left`]: x ?? left,
    [type]: all,
  };

  return Object.fromEntries(
    Object.entries(allProperties)
      // Remove undefined values, which are unnecessary.
      .filter(([, responsiveValue]) => responsiveValue !== undefined)

      // wrap the responsive values in an object with `responsiveValue` as the
      // key
      .map(([propertyName, responsiveValue]) => [
        propertyName,
        { responsiveValue },
      ]),
  );
};

/**
 * Given margin props returns CSS properties for the specified values
 * @param {MarginProps} marginProps - The margin props to convert
 */
export const toResponsiveMarginProperties = ({
  m,
  mt,
  mb,
  my,
  ml,
  mr,
  mx,
}: {
  m?: ResponsiveSizeValue;
  mt?: ResponsiveSizeValue;
  mb?: ResponsiveSizeValue;
  my?: ResponsiveSizeValue;
  ml?: ResponsiveSizeValue;
  mr?: ResponsiveSizeValue;
  mx?: ResponsiveSizeValue;
}) =>
  toResponsiveSpacingProperties('margin', {
    all: m,
    top: mt,
    bottom: mb,
    y: my,
    left: ml,
    right: mr,
    x: mx,
  });
/**
 * Given padding props returns CSS properties for the specified values
 * @param {PaddingProps} paddingProps - The padding props to convert
 */
export const toResponsivePaddingProperties = ({
  p,
  pt,
  pb,
  py,
  pl,
  pr,
  px,
}: {
  p?: ResponsiveSizeValue;
  pt?: ResponsiveSizeValue;
  pb?: ResponsiveSizeValue;
  py?: ResponsiveSizeValue;
  pl?: ResponsiveSizeValue;
  pr?: ResponsiveSizeValue;
  px?: ResponsiveSizeValue;
}) =>
  toResponsiveSpacingProperties('padding', {
    all: p,
    top: pt,
    bottom: pb,
    y: py,
    left: pl,
    right: pr,
    x: px,
  });
