import type { NextWebVitalsMetric } from 'next/app';
import {
  init as initCAR,
  InitOptions,
  recordCustomTiming,
} from '@stitch-fix/client-analytics-reporter';
import settings, { hasLocalAssets } from './settings';
import logger from './logger';
import { getWindow } from './getBrowserGlobals';

type InitPerformanceAnalyticsOptions = Pick<
  InitOptions,
  'tags' | 'debug' | 'sampleRate'
>;

type ProcessedNextMetric = NextWebVitalsMetric & {
  endTime: number;
};

/**
 * Initializes DataDog RUM performance analytics.
 */
export const initPerformanceAnalytics = ({
  tags = {},
  debug = false,
  sampleRate = 60,
}: InitPerformanceAnalyticsOptions = {}) => {
  // Do not attempt to initialize CAR when running server-side.
  if (!getWindow()) {
    return;
  }

  const clientToken = settings.ddClientToken;
  const appId = settings.ddAppId;

  if (!appId || !clientToken) {
    logger.warn(
      'Unable to initialize client-analytics-reporter. Missing `appId` or `clientToken`. Visit https://github.com/stitchfix/sf-next/blob/beta/docs/analytics.md for setup instructions.',
    );

    return;
  }

  initCAR({
    appId,
    clientToken,
    appName: settings.appName,
    env: hasLocalAssets() ? 'dev' : 'prod',
    sampleRate,
    version: settings.appVersion,
    debug,
    tags: {
      ...tags,
      sf_next_version: settings.sfNextVersion,
    },
  });
};

/**
 * Evaluate a metric from Next.js and add some additional metadata that is
 * useful for reporting to DataDog.
 */
const processNextMetric = (
  metric: NextWebVitalsMetric,
): ProcessedNextMetric => {
  const { startTime, value } = metric;

  return {
    ...metric,
    // We need an end time to pass to DataDog for custom timings. This value
    // is determined a couple different ways depending on the metric:
    // - `startTime` and `value` are the same.
    // - `startTime` and `value` are different with `value` representing the
    //    time it took to complete.
    endTime: startTime === value ? startTime : startTime + value,
  };
};

/**
 * Report on next.js metrics and mark `app_rendered`.
 */
export const reportWebVitals = (metric: NextWebVitalsMetric) => {
  const { name, endTime } = processNextMetric(metric);

  if (name === 'Next.js-hydration') {
    // Hydration time is a reasonable approximation of where we marked
    // `app_rendered` in our rails apps.
    recordCustomTiming('app_rendered', endTime);
  }

  recordCustomTiming(`next_metric_${name}`, endTime);
};
