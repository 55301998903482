import { ViewportSize } from '../types/events';

// Viewport size definitions taken from
// https://github.com/stitchfix/mode-react/blob/main/src/_internal/screenSizes.ts#L70-L76

const getViewportSize = (): ViewportSize => {
  const viewportWidth = window.innerWidth;

  if (viewportWidth <= 560) {
    return 'small';
  }

  if (viewportWidth <= 900) {
    return 'medium';
  }

  // If the viewport is larger
  return 'large';
};

export default getViewportSize;
