import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
import IconBase, { IconProps } from '../IconBase';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconSocialPinterest16 = (baseProps: IconProps, ref: Ref<SVGSVGElement>) => {
  return <IconBase {...baseProps}>
        {({
      title,
      titleId,
      ...props
    }: SVGProps<SVGSVGElement> & SVGRProps) => <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" color="#25282D" ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path d="M16 8C16 12.4194 12.4194 16 8 16C7.17419 16 6.38065 15.8742 5.63226 15.6419C5.95806 15.1097 6.44516 14.2387 6.62581 13.5452C6.72258 13.171 7.12258 11.6419 7.12258 11.6419C7.38387 12.1387 8.14516 12.5613 8.95484 12.5613C11.3677 12.5613 13.1065 10.3419 13.1065 7.58387C13.1065 4.94194 10.9484 2.96452 8.17419 2.96452C4.72258 2.96452 2.8871 5.28064 2.8871 7.80645C2.8871 8.98065 3.5129 10.4419 4.50968 10.9065C4.66129 10.9774 4.74194 10.9452 4.77742 10.8C4.80323 10.6903 4.93871 10.1452 5 9.89355C5.01935 9.8129 5.00968 9.74194 4.94516 9.66452C4.61935 9.26129 4.35484 8.52581 4.35484 7.83871C4.35484 6.07419 5.69032 4.36774 7.96774 4.36774C9.93226 4.36774 11.3097 5.70645 11.3097 7.62258C11.3097 9.7871 10.2161 11.2871 8.79355 11.2871C8.00968 11.2871 7.41935 10.6387 7.60968 9.84194C7.83548 8.89032 8.27097 7.86452 8.27097 7.17742C8.27097 6.56452 7.94194 6.05161 7.25806 6.05161C6.45484 6.05161 5.80968 6.88065 5.80968 7.99355C5.80968 8.70323 6.04839 9.18064 6.04839 9.18064C6.04839 9.18064 5.25806 12.529 5.1129 13.1548C4.95161 13.8452 5.01613 14.8194 5.08387 15.4516C2.10968 14.2871 0 11.3903 0 8C0 3.58065 3.58065 0 8 0C12.4194 0 16 3.58065 16 8Z" fill="currentColor" /></svg>}
      </IconBase>;
};
const ForwardRef = forwardRef(IconSocialPinterest16);
export default ForwardRef;