import React, { CSSProperties } from 'react';
import logger from './logger';
import { getScreenSizes, ScreenSizeName } from './screenSizes';

export type NonResponsiveValue =
  | undefined
  | string
  | number
  | boolean
  | React.ReactElement
  | NonResponsiveValue[];
type ResponsiveObject<T> = Partial<Record<ScreenSizeName, T>> & {
  below?: boolean;
};

/**
 * Creates a responsive wrapper around the specified type
 * so that a prop can either accept a value of the type OR
 * multiple values of the type at different breakpoints.
 * The type can either be a string, number or boolean.
 */
export type ResponsiveValue<T extends NonResponsiveValue> =
  | T
  | ResponsiveObject<T>;

/**
 * A generic size value.
 * A `number` is a unit-less value that can be converted into a specific CSS value (typically a rem).
 * A `string` is a specific CSS unit value.
 */
export type SizeValue = number | string | undefined;

/**
 * A responsive version of `SizeValue` allowing for different size values at different breakpoints.
 */
export type ResponsiveSizeValue = ResponsiveValue<SizeValue>;

/**
 * Generic element styling props for components that allow their use.
 */
export interface ElementStylingProps {
  /**
   * Class name to add the component.
   */
  className?: string;
  /**
   * CSS styling declarations to be applied to the element.
   */
  style?: CSSProperties;
}

/**
 * Styling props for components that are deprecating the generic element style
 * props.
 */
export interface DeprecatedElementStylingProps {
  /**
   * Class name(s) to be applied to the component.
   * (**DEPRECATED.** See [deprecated styling props](https://mode-react.daylight.stitchfix.com/?path=/docs/faq--page#deprecated-styling-props) for more information.)
   * @deprecated See [deprecated styling props](https://mode-react.daylight.stitchfix.com/?path=/docs/faq--page#deprecated-styling-props) for more information.)
   */
  className?: string;
  /**
   * CSS styling declarations to be applied to the component.
   * (**DEPRECATED.** See [deprecated styling props](https://mode-react.daylight.stitchfix.com/?path=/docs/faq--page#deprecated-styling-props) for more information.)
   * @deprecated See [deprecated styling props](https://mode-react.daylight.stitchfix.com/?path=/docs/faq--page#deprecated-styling-props) for more information.)
   */
  style?: CSSProperties;
}

/**
 * Dangerous styling props that should be used sparingly and communicate the
 * associated risks to developers.
 */
export interface DangerouslySetStylingProps {
  /**
   * Class name(s) to be applied to the component.
   * **Warning:** Adding styling in this way increases the risk of unintentional breaking changes from Mode React. See [deprecated styling props](https://mode-react.daylight.stitchfix.com/?path=/docs/faq--page#deprecated-styling-props) for more information.)
   */
  dangerouslySetClassName?: string;
  /**
   * **For use in projects that use CSS modules.** Class name(s) to be applied to the component.
   * **Warning:** Adding styling in this way increases the risk of unintentional breaking changes from Mode React. See [deprecated styling props](https://mode-react.daylight.stitchfix.com/?path=/docs/faq--page#deprecated-styling-props) for more information.)
   */
  dangerouslySetStyleName?: string;
  /**
   * CSS styling declarations to be applied to the element.
   * **Warning:** Adding styling in this way increases the risk of unintentional breaking changes from Mode React. See [deprecated styling props](https://mode-react.daylight.stitchfix.com/?path=/docs/faq--page#deprecated-styling-props) for more information.)
   */
  dangerouslySetStyle?: CSSProperties;
}

/**
 * Styling props for components that are deprecating the generic styling props and
 * replacing them with dangerous ones.
 */
export type DeprecatedAndDangerousStylingProps = DeprecatedElementStylingProps &
  DangerouslySetStylingProps;

/**
 * Omit standard styling props (`style`, `className`) to avoid unintentional
 * inclusion of those props when extending prop types for HTML elements or other
 * types that intentionally include them.
 */
export type OmitElementStylingProps<C> = Omit<C, keyof ElementStylingProps>;

/**
 * Omit deprecated (`style`, `className`) and dangerous (`dangerouslySetStyle`,
 * `dangerouslySetClassName`) styling props to avoid unintentional inclusion
 * of those props when extending prop types from components that use them.
 */
export type OmitDeprecatedAndDangerousStylingProps<C> = Omit<
  C,
  keyof DeprecatedAndDangerousStylingProps
>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNonResponsiveValue = (value: any): value is NonResponsiveValue =>
  value === undefined ||
  typeof value === 'string' ||
  typeof value === 'number' ||
  typeof value === 'boolean' ||
  React.isValidElement(value) ||
  (Array.isArray(value) && value.every(isNonResponsiveValue));

// these are TS method overloads that are needed because we want different
// return types depending on the presence of the third parameter. If the
// `defaultValue` is specified the return value can no longer be `undefined`
export function getResponsiveValue<T extends NonResponsiveValue>(
  screenSize: ScreenSizeName,
  responsiveValue?: ResponsiveValue<T>,
): T | undefined;
export function getResponsiveValue<T extends NonResponsiveValue>(
  screenSize: ScreenSizeName,
  responsiveValue: ResponsiveValue<T>,
  defaultValue: NonNullable<T>,
): NonNullable<T>;

/**
 * Given the `screenSize` returns the matching value in `responsiveValue`
 * Returns `undefined` if there is no match
 * @param screenSize One of the predefined screen sizes
 * @param responsiveValue The value on which to match
 */
// eslint-disable-next-line no-restricted-syntax
export function getResponsiveValue<T extends NonResponsiveValue>(
  screenSize: ScreenSizeName,
  responsiveValue?: ResponsiveValue<T>,
  defaultValue?: NonNullable<T>,
): T | undefined {
  let value: T | undefined;

  if (!responsiveValue || isNonResponsiveValue(responsiveValue)) {
    value = responsiveValue;
  } else {
    const screenSizes = getScreenSizes();
    const matchOrder: ScreenSizeName[] = responsiveValue.below
      ? screenSizes
      : [...screenSizes].reverse();

    // for 'lg', this will be ['lg', 'md', 'sm'] from `SCREEN_SIZES_REVERSED`
    const sizesToTest = matchOrder.slice(matchOrder.indexOf(screenSize));

    // search for the first matching value in `responsiveValue`
    // that value will be the value we want to use
    const matchingSize = sizesToTest.find(
      // NOTE: We have to use the `in` operator instead of a simple
      // existence check because falsy values are all valid values
      // that could be set and we'd want to return those
      sizeToTest => sizeToTest in responsiveValue,
    );

    value = matchingSize ? responsiveValue[matchingSize] : undefined;
  }

  // if there wasn't a matching size or the found responsive value is
  // `undefined` use the default value. The `defaultValue` itself may be
  // `undefined` if the third param was not passed.
  return value ?? defaultValue;
}

/**
 * Maps over the values of a responsive value returning a new object with the mapped values
 * @param mapFn Function that takes in an individual value and returns the mapped value
 * @param responsiveValue The value to convert
 */
export const mapResponsiveValue = <
  Value extends NonResponsiveValue,
  MappedValue extends NonResponsiveValue,
>(
  mapFn: (val: Value | undefined) => MappedValue | undefined,
  responsiveValue?: ResponsiveValue<Value>,
): ResponsiveValue<MappedValue> | undefined => {
  if (!responsiveValue || isNonResponsiveValue(responsiveValue)) {
    return mapFn(responsiveValue);
  }

  const screenSizes = getScreenSizes();

  return screenSizes.reduce((result, screenSize) => {
    // If the screen size wasn't defined in the original responsiveValue object,
    // it also shouldn't be in the `result`.
    if (!(screenSize in responsiveValue)) {
      return result;
    }

    return {
      ...result,
      [screenSize]: mapFn(responsiveValue[screenSize]),
    };
  }, {} as ResponsiveObject<MappedValue>);
};

/**
 * Given a size value returns a string value that can be set as a CSS property value
 * @param sizeValue Size value to convert
 * @returns CSS property value if size value is specified, `undefined` otherwise
 */
export const getCssValue = (sizeValue?: SizeValue): string | undefined => {
  if (sizeValue === undefined) {
    return sizeValue;
  }

  return typeof sizeValue === 'number' ? `${sizeValue}rem` : sizeValue;
};

/**
 * Given the `screenSize` and the `responsiveSizeValue` returns a matching string value
 * that can be set as a CSS property value
 * @param screenSize One of the predefined screen sizes
 * @param responsiveValue The value on which to match and convert
 */
export const getResponsiveCssValue = (
  screenSize: ScreenSizeName,
  responsiveSizeValue?: ResponsiveSizeValue,
) => getCssValue(getResponsiveValue(screenSize, responsiveSizeValue));

// Keep track of when we see warnings for a given component, so that we only
// console.warn them once. We want to avoid spamming developers.
const styleDeprecationWarnings = new Set<string>();

/**
 * Determines how styling props (`className`, `style`) will be processed.
 * Defaults to `warn`.
 * - `warn` - Warn about deprecated props. Do not modify styling props.
 * - `makeDangerous` - Warn about deprecated props and rewrite deprecated props to their dangerous variations (e.g. `className` becomes `dangerouslySetClassName`). This can be used on components that are composed of other components that have deprecated these props. This avoids duplicative warnings from the child component about the deprecations.
 * - `remove` - Removes styling props from components. Useful for components that do not support these props and want to ensure they are removed before spreading props.
 */
type StylingPropsMode = 'warn' | 'makeDangerous' | 'remove';

/**
 * Determines how dangerous styling props (`dangerouslySetClassName`, `dangerouslySetStyle`) will be processed.
 * Defaults to `keep`.
 * - `keep` - Keep dangerous props as is. Use this when the props will be passed to another Mode React component that will process its props. Otherwise, that component may erroneously warn about deprecated styling props.
 * - `rewrite` - Rewrite dangerous props to their deprecated variations (e.g. `dangerouslySetStyle` becomes `style`). You should rewrite them when the results will be applied to a dom node.
 * - `remove` - Removes dangerous props from components. Useful for components that do not support these props and want to ensure they are removed before spreading props.
 */
type DangerousStylingPropsMode = 'keep' | 'rewrite' | 'remove';

/** Determines how styling props will be processed. */
type ProcessStylingPropsMode = {
  stylingProps: StylingPropsMode;
  dangerousStylingProps: DangerousStylingPropsMode;
};

type ProcessStylingPropResponse = {
  /**
   * If true, deprecated props were seen during processing.
   */
  sawDeprecatedProps: boolean;
  /**
   * Styling props that should be passed along based on processing for the specified props.
   */
  processedStylingProps: DeprecatedAndDangerousStylingProps;
};

/**
 * Processes a single styling prop and its dangerous variation.
 * @param props The props to process.
 * @param stylingPropName The name of the prop (e.g. `style`, `className`).
 * @param dangerousPropName The name of the prop's dangerous variation (e.g. `dangerouslySetStyle`, `dangerouslySetClassName`).
 * @param mode The way the styling props will be processed.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const processStylingProp = <T extends Record<string, any>>(
  props: T,
  stylingPropName: keyof DeprecatedElementStylingProps,
  dangerousPropName: keyof DangerouslySetStylingProps,
  { stylingProps, dangerousStylingProps }: ProcessStylingPropsMode,
): ProcessStylingPropResponse => {
  const makeDeprecatedPropsDangerous = stylingProps === 'makeDangerous';
  const rewriteDangerousProps = dangerousStylingProps === 'rewrite';
  const removeDangerousProps = dangerousStylingProps === 'remove';
  const keepDeprecatedProps = stylingProps === 'warn';

  // When removing props, they are not supported, so we do not console log the
  // deprecation warning.
  const shouldWarn = stylingProps !== 'remove';

  const stylingProp = props[stylingPropName];
  const dangerousProp = props[dangerousPropName];

  let sawDeprecatedProps = false;
  const processedStylingProps: DeprecatedAndDangerousStylingProps = {};

  if (stylingProp) {
    // Styling props take priority over dangerous styling props if both are
    // set.

    if (shouldWarn) {
      // Note that a deprecated prop was seen, so a single warning can be
      // provided when finished processing.
      sawDeprecatedProps = true;
    }
    if (makeDeprecatedPropsDangerous) {
      // Rewrite a deprecated prop to its dangerous variation.
      // e.g. `className` becomes `dangerouslySetClassName`
      processedStylingProps[dangerousPropName] = stylingProp;
    } else if (keepDeprecatedProps) {
      // Keep the prop as is
      processedStylingProps[stylingPropName] = stylingProp;
    }
  } else if (rewriteDangerousProps && dangerousProp) {
    // Dangerous styling prop is rewritten to its non-dangerous variation.
    // e.g. `dangerouslySetStyle` becomes `style`
    processedStylingProps[stylingPropName] = dangerousProp;
  } else if (dangerousProp && !removeDangerousProps) {
    // Keep the dangerous prop as is.
    processedStylingProps[dangerousPropName] = dangerousProp;
  }

  return {
    sawDeprecatedProps,
    processedStylingProps,
  };
};

type OmitStylingProps<T> = Omit<T, keyof DeprecatedAndDangerousStylingProps>;
type ProcessStylingPropsResponse<T> = T & DeprecatedAndDangerousStylingProps;

/**
 * Given a set of props for a component, return a set of props modified to
 * handle styling-related props.
 * @param props The props for the component
 * @param componentName The name of the component. Used for communicating the cause of deprecation warnings to developers.
 * @param mode The way the styling props will be processed.
 */
// Need to use `any` here because this code is trying to account for edge cases
// in ES6 code where the lack of type checking allows developers to pass in
// props that are not supported on a component.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const processStylingProps = <T extends Record<string, any>>(
  props: T,
  componentName: string,
  mode: ProcessStylingPropsMode,
): ProcessStylingPropsResponse<T> => {
  const nonStylingProps = removeStylingProps(props);
  const propsToProcess = [
    {
      stylingPropName: 'className',
      dangerousPropName: 'dangerouslySetClassName',
    },
    {
      stylingPropName: 'style',
      dangerousPropName: 'dangerouslySetStyle',
    },
  ] as const;

  let sawDeprecatedProps = false;

  // Initialize with the props we want to keep unrelated to styles.
  const processedProps = propsToProcess.reduce(
    (inProgressProcessedProps, { stylingPropName, dangerousPropName }) => {
      const processedProp = processStylingProp(
        props,
        stylingPropName,
        dangerousPropName,
        mode,
      );

      sawDeprecatedProps =
        sawDeprecatedProps || processedProp.sawDeprecatedProps;

      // Add styling props
      return {
        ...inProgressProcessedProps,
        ...processedProp.processedStylingProps,
      };
    },
    nonStylingProps as ProcessStylingPropsResponse<T>,
  );

  // Warn once if any deprecated props were seen.
  if (sawDeprecatedProps && !styleDeprecationWarnings.has(componentName)) {
    styleDeprecationWarnings.add(componentName);
    logger.warn(
      `The className and style props on ${componentName} will be deprecated in a future major release. See https://mode-react.daylight.stitchfix.com/?path=/docs/faq--page#deprecated-styling-props for recommendations.`,
    );
  }

  return processedProps;
};

/**
 * Given a set of props for a component, return a set of props modified to
 * remove styling-related props.
 * @param props The props for the component
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeStylingProps = <T extends Record<string, any>>(
  props: T,
): OmitStylingProps<T> => {
  const {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    className,
    dangerouslySetClassName,
    dangerouslySetStyleName,
    style,
    dangerouslySetStyle,
    /* eslint-enable @typescript-eslint/no-unused-vars */
    ...nonStylingProps
  } = props;

  return nonStylingProps;
};
