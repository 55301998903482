import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
import IconBase, { IconProps } from '../IconBase';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconError16 = (baseProps: IconProps, ref: Ref<SVGSVGElement>) => {
  return <IconBase {...baseProps}>
        {({
      title,
      titleId,
      ...props
    }: SVGProps<SVGSVGElement> & SVGRProps) => <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M7.99995 0.911865L8.60561 1.95706L15.391 14.0371L16 15.088H14.7853H1.21466H0L0.609002 14.0371L7.39429 1.95706L7.99995 0.911865ZM2.42932 13.688H13.5707L7.99995 3.70419L2.42932 13.688ZM8.69995 6.56803V7.26803V9.82803V10.528H7.29995V9.82803V7.26803V6.56802L8.69995 6.56803ZM7.29995 11.368H7.99995H8.03195H8.73195V12.768H8.03195H7.99995H7.29995V11.368Z" fill="currentColor" /></svg>}
      </IconBase>;
};
const ForwardRef = forwardRef(IconError16);
export default ForwardRef;