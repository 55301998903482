import { useState, useEffect } from 'react';
import { getWindow } from './getBrowserGlobals';
import { wasServerSideRendered } from './ssr';

// Because of the proliferation of responsive props, `useMedia` gets called
// *a lot*, which would result in a lot of duplicate MediaQueryList objects
// with a change handler. With the cache we only create one object per query
// on it we add many change listeners. The assumption is that `addEventListener`
// and `removeEventListener` are more optimized than us maintaining a cache
// of change listeners.
let MQL_CACHE: Record<string, MediaQueryList> = {};

// NOTE: exists & exported solely for testing. DO NOT USE OTHERWISE!
export const clearMqlCache = () => {
  MQL_CACHE = {};
};

const getMql = (query: string): MediaQueryList | undefined => {
  const win = getWindow();

  // The additional check of `window.matchMedia` is for test environments.
  // All of our supported browsers support `window.matchMedia`, but Jest
  // jsdom doesn't. And since this is going to be ultimately called by
  // nearly every Mode React component, running tests will complain that
  // `window.matchMedia` doesn't exist.
  if (!(query in MQL_CACHE) && typeof win?.matchMedia === 'function') {
    MQL_CACHE[query] = win.matchMedia(query);
  }

  return MQL_CACHE[query];
};

/**
 * Tracks state of the specified CSS media query, allowing for the rendering of
 * components based on whether or not the query matches. If the app is
 * server-side rendered, call `setServerSideRendered()` in the root-level
 * component so that the media query is properly hydrated on the client to match
 * what was pre-rendered.
 * @param query The CSS media query
 */
const useMedia = (query: string): boolean => {
  // `useMedia` is adapted from `react-use`:
  // https://github.com/streamich/react-use/blob/f2ba4c45e81d0d956d39dd855b9cfa6aef6f2246/src/useMedia.ts
  // It adds caching of MediaQueryList objects and handling of server-side
  // hydration

  const [state, setState] = useState(
    // If an app is server-side rendered, we need the initial client-side
    // render to match what was included in the HTML. Otherwise we get the
    // warning in DEV that markup rendered on the client doesn't match what
    // currently exists on the server. And when this situation occurs, React
    // does **not** render the new version based on the updated media query, so
    // we get stale UI. The workaround is to have a server-side rendered app
    // call `setServerSideRendered()`. In this case we know at initial render
    // time that we're hydrating a server-side rendered app (as opposed to just
    // initially rendering a client-rendered app). In this case, we don't have a
    // media query match because we know one couldn't have occurred server-side.
    // Within the `useEffect` we call `getMql` again and there we should get the
    // appropriate match. The UI will update on the client.
    () => !wasServerSideRendered() && !!getMql(query)?.matches,
  );

  useEffect(() => {
    const mql = getMql(query);

    if (mql) {
      let mounted = true;
      const onChange = () => {
        // in theory because we're removing the listener
        // on unmount we really shouldn't be able to get
        // here, but this is an extra check for safety.
        if (!mounted) {
          return;
        }

        setState(mql.matches);
      };

      mql.addListener(onChange);
      setState(mql.matches);

      return () => {
        mounted = false;
        mql.removeListener(onChange);
      };
    }

    return undefined;
  }, [query]);

  return state;
};

export default useMedia;
