import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
import IconBase, { IconProps } from '../IconBase';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const LogoMonogramMint = (baseProps: IconProps, ref: Ref<SVGSVGElement>) => {
  return <IconBase {...baseProps}>
        {({
      title,
      titleId,
      ...props
    }: SVGProps<SVGSVGElement> & SVGRProps) => <svg width={175} height={160} viewBox="0 0 175 160" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path d="M149.725 75.064H96.8523L116.985 47.3081H150.409C163.408 47.3081 174.647 36.6553 174.647 23.657C174.647 10.4632 163.799 -0.287338 150.507 0.00585824C143.08 0.10359 136.238 4.01287 131.938 9.97451L112.001 37.6326H24.1398C11.2392 37.6326 0.684123 47.9922 0.48866 60.8928C0.293196 74.0867 11.4346 84.9349 24.7262 84.9349H77.7946L57.6618 112.691H24.2375C11.2392 112.691 0 123.344 0 136.342C0 149.145 10.1641 159.602 22.967 159.993C30.8833 160.188 38.3109 156.279 42.9043 149.927L62.7439 122.464H150.409C163.31 122.464 173.865 112.104 174.061 99.2038C174.158 85.9122 163.017 75.064 149.725 75.064ZM140.148 15.252C142.689 11.8314 146.696 9.77905 150.898 9.77905C158.619 9.77905 164.776 16.0339 164.776 23.657C164.776 31.3778 158.521 37.5349 150.898 37.5349H124.022L140.148 15.252ZM34.7926 144.258C32.1538 147.972 27.8536 150.318 23.2602 150.22C15.7348 150.024 9.67546 143.867 9.67546 136.342C9.67546 128.719 15.9303 122.464 23.5534 122.464H50.5274L34.7926 144.258ZM24.1398 75.064C16.5167 75.064 10.2619 68.8091 10.2619 61.186C10.2619 53.5629 16.5167 47.3081 24.1398 47.3081H104.964L84.8313 75.064H24.1398ZM150.312 112.691H69.6829L89.8156 84.9349H150.312C157.935 84.9349 164.19 91.1897 164.19 98.8128C164.19 106.436 157.935 112.691 150.312 112.691Z" fill="#86C8BC" /></svg>}
      </IconBase>;
};
const ForwardRef = forwardRef(LogoMonogramMint);
export default ForwardRef;