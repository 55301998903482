import React, {
  Children,
  type ElementType,
  forwardRef,
  type FunctionComponent,
  type ReactNode,
} from 'react';
import classNames from 'classnames';
import { PolymorphicRef } from '../_internal/components';
import {
  mapResponsiveValue,
  ResponsiveSizeValue,
  processStylingProps,
  DeprecatedAndDangerousStylingProps,
  ResponsiveValue,
  NonResponsiveValue,
} from '../_internal/styling';
import Box, { ExtendableBoxProps, BoxProps } from '../Box';
import ResponsiveBase from '../ResponsiveBase';

type VerticalAlign = 'stretch' | 'top' | 'center' | 'bottom';
type HorizontalAlign = 'start' | 'center' | 'end';

const VERTICAL_ALIGN_MAP: Record<VerticalAlign, string> = {
  stretch: 'stretch',
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
};
const HORIZONTAL_ALIGN_MAP: Record<HorizontalAlign, string> = {
  start: 'flex-start',
  center: 'center',
  end: 'flex-end',
};

interface Props extends DeprecatedAndDangerousStylingProps {
  /**
   * One or more elements to horizontally evenly space. String must be wrapped in `<Fragment>`.
   */
  children: ReactNode;
  /**
   * The spacing between the inline items. Can specify any CSS unit with a string value.
   * A numeric value maps to our spacing unit (i.e. `1rem`)
   */
  spacing: ResponsiveSizeValue;
  /**
   * Controls the vertical alignment
   *
   * @default 'stretch'
   */
  vAlign?: ResponsiveValue<VerticalAlign>;
  /**
   * Controls the horizontal alignment
   *
   * @default 'start'
   */
  hAlign?: ResponsiveValue<HorizontalAlign>;
  /**
   * Whether or not the inline items should wrap if the items at their default width do not fit with the specified `spacing`
   */
  wrap?: ResponsiveValue<boolean>;
}

export type InlineProps<C extends ElementType = 'div'> = ExtendableBoxProps<
  C,
  Props
>;

type InlineComponent = FunctionComponent &
  (<C extends ElementType = 'div'>(
    props: InlineProps<C>,
  ) => React.ReactElement | null);

/**
 * Use `Inline` to evenly space out elements or components in the horizontal direction, wrapping if necessary.
 *
 * The `Inline` component implements a subset of CSS flexbox, focusing on maintaining the specified fixed spacing amount. It will shrink inline items as necessary to maintain the spacing. If you need fixed-width items with dynamic spacing or both dynamic-width items with dynamic spacing, use the full CSS flexbox styling.
 */
export const Inline: InlineComponent = forwardRef(
  <C extends ElementType>(
    {
      children,
      spacing,
      hAlign = 'start',
      vAlign = 'stretch',
      wrap = false,
      as,
      ...rootProps
    }: InlineProps<C>,
    ref?: PolymorphicRef<C>,
  ) => {
    const isList = as === 'ol' || as === 'ul';
    const inlineItems = Children.toArray(children)
      // We need to ensure that when we call `.map()` that we only have renderable items so that the
      // index calculations for when to include margins are correct.
      // TODO remove this ts-ignore (and the check `child !== false`) once we are fully on react 18.
      // @ts-ignore - Previously with react v17 we need to explicitly filter out `false` values -
      // with v18 it looks that `Children.toArray` handles this for us.
      .filter(child => child !== undefined && child !== null && child !== false)
      .map((child, index) => {
        let marginTop: ResponsiveSizeValue | undefined;
        let marginLeft: ResponsiveSizeValue | undefined;

        if (wrap) {
          marginTop = spacing;
          marginLeft = spacing;
        } else if (index > 0) {
          marginLeft = spacing;
        }

        return (
          <Box
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            data-testid="inline-item"
            as={isList ? 'li' : undefined}
            mt={marginTop}
            ml={marginLeft}
          >
            {child}
          </Box>
        );
      });

    // If there end up being no items to display,
    // we'll just return null instead of rendering
    // an empty container
    if (!inlineItems.length) {
      return null;
    }

    // We have to set top margin on all of the items because we don't know
    // when they will wrap. We also have left margin on the first because
    // the beginning of the 2nd, 3rd, 4th, etc rows will also have left
    // margin. To counteract the margins on the items, we set negative
    // margin on the container.
    const negativeSpacing = mapResponsiveValue(value => {
      if (typeof value === 'string') {
        // TODO: If folks want to pass in negative CSS value strings, we'll
        // need to inspect the string to remove the negation instead of adding
        // a second one
        return `-${value}`;
      }

      if (typeof value === 'number') {
        return -value;
      }

      return value;
    }, spacing);

    const { className, style, ...boxProps } = processStylingProps(
      rootProps,
      'Inline',
      {
        stylingProps: 'warn',
        dangerousStylingProps: 'rewrite',
      },
    ) as Partial<BoxProps<C>>;

    const properties = {
      justifyContent: {
        responsiveValue: hAlign,
        defaultValue: 'start',
        mapCssValue: (val: NonResponsiveValue) =>
          HORIZONTAL_ALIGN_MAP[val as HorizontalAlign],
      },
      alignItems: {
        responsiveValue: vAlign,
        defaultValue: 'stretch',
        mapCssValue: (val: NonResponsiveValue) =>
          VERTICAL_ALIGN_MAP[val as VerticalAlign],
      },
      flexWrap: {
        responsiveValue: wrap,
        mapCssValue: (val: NonResponsiveValue) => (val ? 'wrap' : undefined),
      },
    };

    return (
      <ResponsiveBase properties={properties}>
        {({ className: responsiveClassName, style: responsiveStyle }) => {
          if (!wrap) {
            // Typescript's inferred type for these polymorphic props produce a cryptic error when
            // passed to Box (starting with React v18). We can coerce the type to BoxProps<C> to
            // avoid the error.
            const coercedBoxProps = { as, ...boxProps } as BoxProps<C>;

            return (
              <Box
                data-testid="inline"
                ref={ref}
                className={classNames(responsiveClassName, className)}
                style={{ ...responsiveStyle, ...style }}
                display="flex"
                {...coercedBoxProps}
              >
                {inlineItems}
              </Box>
            );
          }

          const wrapProps = {
            // add the `as` prop to the wrap container instead of the outer container
            // so that when the Inline is a list, the `<li>` elements are direct
            // children
            as,
            ...(wrap ? { mt: negativeSpacing, ml: negativeSpacing } : {}),
          } as BoxProps<C>;

          return (
            <Box data-testid="inline" {...boxProps}>
              <Box
                data-testid="inline-wrap"
                {...wrapProps}
                ref={ref}
                className={classNames(responsiveClassName, className)}
                style={{ ...responsiveStyle, ...style }}
                display="flex"
              >
                {inlineItems}
              </Box>
            </Box>
          );
        }}
      </ResponsiveBase>
    );
  },
);

Inline.displayName = 'Inline';

export default Inline;
