// These Traceable types are injected by a google tag manager script
type TraceableStatus = 'IN_PROGRESS' | 'COMPLETE' | 'ERROR';
export type TraceableDetail = { status: TraceableStatus };

// We opt not to declare this on the global window namespace, to avoid conflicting with
// global declarations made in consuming apps/libs:
export interface SfixWindow extends Window {
  sfixMobile?: {
    isAndroid?: () => boolean;
    isiOS?: () => boolean;
  };
  TRACEABLE_POW_STATUS?: TraceableDetail;
}

export const getWindow = () => {
  if (window) return window as SfixWindow;

  return undefined;
};
