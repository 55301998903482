import React, {
  createContext,
  useContext,
  useMemo,
  useRef,
  useState,
  type ReactNode,
} from 'react';
import { LazyMotion } from './LazyMotion';

type NavigationDirection = 'back' | 'forward';
type NavigationContext = {
  clearTimeoutRef: React.MutableRefObject<(() => void) | undefined> | undefined;
  navigationDirection: NavigationDirection;
  onBackNavigation: () => void;
  onForwardNavigation: () => void;
};

const NavigationContext = createContext<NavigationContext>({
  clearTimeoutRef: undefined,
  navigationDirection: 'forward',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onBackNavigation: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onForwardNavigation: () => {},
});

export const useNavigationContext = () =>
  useContext(NavigationContext) as NavigationContext;

export const NavigationProvider = ({ children }: { children: ReactNode }) => {
  const clearTimeoutRef = useRef<(() => void) | undefined>();
  const [navigationDirection, setNavigationDirection] =
    useState<NavigationDirection>('forward');

  // Optimizing rerendering by memoizing the context value
  // Source: https://react.dev/reference/react/useContext#optimizing-re-renders-when-passing-objects-and-functions
  const contextValue = useMemo(
    () => ({
      clearTimeoutRef,
      navigationDirection,
      onBackNavigation: () => {
        if (clearTimeoutRef.current) {
          clearTimeoutRef.current();
        }

        setNavigationDirection('back');
      },
      onForwardNavigation: () => {
        if (clearTimeoutRef.current) {
          clearTimeoutRef.current();
        }

        setNavigationDirection('forward');
      },
    }),
    [clearTimeoutRef, navigationDirection],
  );

  return (
    <LazyMotion>
      <NavigationContext.Provider value={contextValue}>
        {children}
      </NavigationContext.Provider>
    </LazyMotion>
  );
};
