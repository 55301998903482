import Bugsnag from '@bugsnag/js';
import type { OnErrorCallback } from '@bugsnag/js';

export type ErrorMetadata = { [key: string]: unknown };

interface BugsnagNotify {
  group?: string;
  error: Error;
  metadata?: ErrorMetadata;
}

const bugsnagNotify = ({ error, metadata, group }: BugsnagNotify) => {
  // Log to the console in development mode
  if (process.env.NODE_ENV === 'development')
    // eslint-disable-next-line no-console
    console.warn({ error, metadata, group });

  const onError: OnErrorCallback = event => {
    if (group) {
      // The Bugsnag API works by assigning values to the event parameter
      // eslint-disable-next-line no-param-reassign
      event.groupingHash = group;
    }

    event.addMetadata('Event Reporter', metadata ?? {});
  };

  Bugsnag.notify(error, onError);
};

export default bugsnagNotify;
