import { getValidatedLocale } from '..';

type Money = {
  /** Amount in minor units (eg cents) */
  value: number;
  /** Currency 3-letter ISO code (eg 'USD') */
  currency: string;
};

type FormatMoneyOptions = {
  /** Whether to format with subunits (cents). Otherwise will round half up. */
  subunits?: boolean;
};

/**
 * Format a money object to string
 *
 * @param money Money object with `value` and `currency` attributes.
 * @param options Formatting options
 * @param options.subunits Whether to format with subunits (cents). Otherwise
 *   will round half up. Defaults to `false`.
 */
export const formatMoneyForLocale = (
  locale: string,
  { value, currency }: Money,
  { subunits = false }: FormatMoneyOptions = {},
): string => {
  const valueInMajorUnits = value / 100;

  const fractionDigitsConfig = subunits
    ? {}
    : {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      };

  return valueInMajorUnits.toLocaleString(getValidatedLocale(locale), {
    style: 'currency',
    currencyDisplay: 'symbol',
    currency,
    useGrouping: false, // no thousands-separator
    ...fractionDigitsConfig,
  });
};
