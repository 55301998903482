import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
import IconBase, { IconProps } from '../IconBase';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconSocialPinterest24 = (baseProps: IconProps, ref: Ref<SVGSVGElement>) => {
  return <IconBase {...baseProps}>
        {({
      title,
      titleId,
      ...props
    }: SVGProps<SVGSVGElement> & SVGRProps) => <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#25282D" ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path d="M24 12C24 18.629 18.629 24 12 24C10.7613 24 9.57097 23.8113 8.44839 23.4629C8.9371 22.6645 9.66774 21.3581 9.93871 20.3177C10.0839 19.7565 10.6839 17.4629 10.6839 17.4629C11.0758 18.2081 12.2177 18.8419 13.4323 18.8419C17.0516 18.8419 19.6597 15.5129 19.6597 11.3758C19.6597 7.4129 16.4226 4.44677 12.2613 4.44677C7.08387 4.44677 4.33065 7.92097 4.33065 11.7097C4.33065 13.471 5.26935 15.6629 6.76452 16.3597C6.99194 16.4661 7.1129 16.4177 7.16613 16.2C7.20484 16.0355 7.40806 15.2177 7.5 14.8403C7.52903 14.7194 7.51452 14.6129 7.41774 14.4968C6.92903 13.8919 6.53226 12.7887 6.53226 11.7581C6.53226 9.11129 8.53548 6.55161 11.9516 6.55161C14.8984 6.55161 16.9645 8.55968 16.9645 11.4339C16.9645 14.6806 15.3242 16.9306 13.1903 16.9306C12.0145 16.9306 11.129 15.9581 11.4145 14.7629C11.7532 13.3355 12.4065 11.7968 12.4065 10.7661C12.4065 9.84677 11.9129 9.07742 10.8871 9.07742C9.68226 9.07742 8.71452 10.321 8.71452 11.9903C8.71452 13.0548 9.07258 13.771 9.07258 13.771C9.07258 13.771 7.8871 18.7935 7.66935 19.7323C7.42742 20.7677 7.52419 22.229 7.62581 23.1774C3.16452 21.4306 0 17.0855 0 12C0 5.37097 5.37097 0 12 0C18.629 0 24 5.37097 24 12Z" fill="currentColor" /></svg>}
      </IconBase>;
};
const ForwardRef = forwardRef(IconSocialPinterest24);
export default ForwardRef;