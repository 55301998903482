import React from 'react';
import { IconProps } from './IconBase';

export type IconContextState = {
  // Values that are used as defaults instead of the defaults inside IconBase.
  // They can be overriden by props set on an icon.
  /**
   * Default purpose to use on icons if the purpose is not explicitly set using
   * props.
   */
  defaultPurpose?: IconProps['purpose'];

  /**
   * Default color to use on icons if the color is not explicitly set using
   * props.
   */
  defaultColor?: IconProps['color'];

  // Values that are used instead of the props specified on an icon. Useful for
  // rare cases where we always want to override behavior (e.g. disabled icons
  // in IconButtons always must be a specific color).
  /**
   * Color to use on icons. It will override colors set using props.
   */
  overrideColor?: IconProps['color'];
};

const IconContext = React.createContext<IconContextState | undefined>(
  undefined,
);

export default IconContext;

interface DecorativeIconProviderProps
  extends Pick<IconContextState, 'defaultColor'> {
  children: React.ReactNode;
}
export const DecorativeIconProvider = ({
  children,
  defaultColor,
}: DecorativeIconProviderProps) => (
  <IconContext.Provider value={{ defaultPurpose: 'decorative', defaultColor }}>
    {children}
  </IconContext.Provider>
);

interface IconProviderProps extends IconContextState {
  children: React.ReactNode;
}
export const IconProvider = ({
  children,
  ...otherProps
}: IconProviderProps) => (
  <IconContext.Provider value={otherProps}>{children}</IconContext.Provider>
);
