import { SimpleScreenViewEvent } from '../types/events';
import { getWindow } from '../getWindow';

type Platform = SimpleScreenViewEvent['platform'];

const getPlatform = (): Platform => {
  const sfixMobile = getWindow()?.sfixMobile;

  if (sfixMobile?.isAndroid?.()) {
    return 'android';
  }
  if (sfixMobile?.isiOS?.()) {
    return 'iOS';
  }

  return 'web';
};

export default getPlatform;
