import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
import IconBase, { IconProps } from '../IconBase';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconSocialTwitter16 = (baseProps: IconProps, ref: Ref<SVGSVGElement>) => {
  return <IconBase {...baseProps}>
        {({
      title,
      titleId,
      ...props
    }: SVGProps<SVGSVGElement> & SVGRProps) => <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path d="M9.49309 6.77491L15.3357 0H13.9512L8.87806 5.88256L4.82619 0H0.152832L6.28006 8.89547L0.152832 16H1.53741L6.89474 9.78782L11.1738 16H15.8472L9.49275 6.77491H9.49309ZM7.59672 8.97384L6.9759 8.08805L2.0363 1.03974H4.16293L8.14925 6.72795L8.77007 7.61374L13.9518 15.0075H11.8252L7.59672 8.97418V8.97384Z" fill="currentColor" /></svg>}
      </IconBase>;
};
const ForwardRef = forwardRef(IconSocialTwitter16);
export default ForwardRef;