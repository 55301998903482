import React, {
  type ElementType,
  forwardRef,
  type FunctionComponent,
  type ReactNode,
} from 'react';
import classNames from 'classnames';
import Text, { type TextProps, type ExtendableTextProps } from '../Text';
import type { PolymorphicRef } from '../_internal/components';
import {
  type ResponsiveSizeValue,
  processStylingProps,
  type DeprecatedAndDangerousStylingProps,
} from '../_internal/styling';
import { DecorativeIconProvider } from '../IconBase';

import styles from './link.module.scss';

const DEFAULT_ICON_SPACING = 0.25;

interface ResponsiveIconSpacingValue {
  start?: ResponsiveSizeValue;
  end?: ResponsiveSizeValue;
}

interface Props extends DeprecatedAndDangerousStylingProps {
  /**
   * Link contents
   */
  children: ReactNode;
  /**
   * Icon to display at the end of the link
   * (e.g. right arrow icon)
   */
  endIcon?: ReactNode;
  /**
   * The spacing between the text and icon(s)
   *
   * @default 0.25
   */
  iconSpacing?: ResponsiveSizeValue | ResponsiveIconSpacingValue;
  /**
   * The size of the link
   *
   * @default 'inherit'
   */
  size?: 'inherit' | 'cta' | 'tertiary';
  /**
   * Icon to display at the beginning of the link
   * (e.g. left chevron icon)
   */
  startIcon?: ReactNode;
  /**
   * The type of link to display
   *
   * @default 'default'
   */
  variant?: 'default' | 'on-dark' | 'inherit';

  /**
   * Override default behavior of the link underline. If not set, uses default
   * behavior based on the `size`.
   *
   * - `initial` - Start with the link underlined. Hide the underline on hover/active.
   * - `inverse` - No underline by default. Show the underline on hover/active.
   */
  underline?: 'initial' | 'inverse';
}

export type LinkProps<C extends ElementType = 'a'> = ExtendableTextProps<
  C,
  Props
>;

const isIconSpacing = (
  iconSpacing: ResponsiveSizeValue | ResponsiveIconSpacingValue,
): iconSpacing is ResponsiveIconSpacingValue => {
  if (!iconSpacing || typeof iconSpacing !== 'object') {
    return false;
  }

  // At this point `iconSpacing` is a `ResponsiveObject` or
  // `ResponsiveIconSpacingValue` so we need to tell by seeing
  // if the object has `start` & `end` properties
  const objToVerify = iconSpacing as ResponsiveIconSpacingValue;

  return 'start' in objToVerify || 'end' in objToVerify;
};

const normalizeIconSpacing = (
  iconSpacing: ResponsiveSizeValue | ResponsiveIconSpacingValue,
): ResponsiveIconSpacingValue => {
  let start: ResponsiveSizeValue;
  let end: ResponsiveSizeValue;

  if (iconSpacing) {
    if (isIconSpacing(iconSpacing)) {
      start = iconSpacing.start;
      end = iconSpacing.end;
    } else {
      start = iconSpacing;
      end = iconSpacing;
    }
  }

  return { start, end };
};

type LinkComponent = FunctionComponent &
  (<C extends ElementType = 'a'>(
    props: LinkProps<C>,
  ) => React.ReactElement | null);

/**
 * Links send the user to a different page or experience. We use links inline with text and also as CTAs, meant to move the user along a flow.
 */
export const Link: LinkComponent = forwardRef(
  <C extends ElementType = 'a'>(
    {
      as,
      children,
      endIcon,
      size = 'inherit',
      iconSpacing,
      startIcon,
      variant = 'default',
      underline,
      ...rootProps
    }: LinkProps<C>,
    ref?: PolymorphicRef<C>,
  ) => {
    const hasIcon = startIcon || endIcon;
    const className = classNames(
      styles.root,
      rootProps.className,
      rootProps.dangerouslySetClassName,
      {
        [styles.default]: variant === 'default',
        [styles['on-dark']]: variant === 'on-dark',
        [styles.cta]: size === 'cta',
        [styles.tertiary]: size === 'tertiary',
        [styles['has-start-icon']]: startIcon,
        [styles['has-end-icon']]: endIcon,
        [styles[`underline-${underline}`]]: underline,
      },
    );

    let contents = children;
    const textProps = processStylingProps(rootProps, 'Link', {
      stylingProps: 'warn',
      dangerousStylingProps: 'rewrite',
    }) as TextProps<C>;

    if (hasIcon) {
      const {
        start: startIconSpacing = DEFAULT_ICON_SPACING,
        end: endIconSpacing = DEFAULT_ICON_SPACING,
      } = normalizeIconSpacing(iconSpacing);

      contents = (
        <>
          <DecorativeIconProvider defaultColor="inherit">
            {startIcon}
          </DecorativeIconProvider>
          <Text
            className={styles.contents}
            ml={startIcon ? startIconSpacing : undefined}
            mr={endIcon ? endIconSpacing : undefined}
          >
            {children}
          </Text>
          <DecorativeIconProvider defaultColor="inherit">
            {endIcon}
          </DecorativeIconProvider>
        </>
      );
    }

    return (
      <Text ref={ref} as={as || 'a'} {...textProps} className={className}>
        {contents}
      </Text>
    );
  },
);

Link.displayName = 'Link';

export default Link;
