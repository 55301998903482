import React, {
  type ElementType,
  type ReactNode,
  forwardRef,
  type FunctionComponent,
} from 'react';
import classNames from 'classnames';
import ButtonBase, {
  ExtendableButtonBaseProps,
  ButtonBaseProps,
} from '../ButtonBase';
import {
  ResponsiveValue,
  getResponsiveValue,
  processStylingProps,
  DeprecatedAndDangerousStylingProps,
} from '../_internal/styling';
import useScreenSize from '../_internal/useScreenSize';
import logger from '../_internal/logger';
import { DecorativeIconProvider } from '../IconBase';
import { PolymorphicRef } from '../_internal/components';
import styles from './button.module.scss';

const deprecatedButtonVariants = [
  'primary',
  'secondary',
  'secondary-alt',
  'secondary-inverse',
  'tertiary',
  'tertiary-alt',
] as const;

type DeprecatedButtonVariants = (typeof deprecatedButtonVariants)[number];

type ButtonVariants =
  | 'filled-accent'
  | 'filled-accent-large'
  | 'filled-accent-slim'
  | 'filled-statement'
  | 'filled-statement-large'
  | 'filled-staple'
  | 'filled-staple-large'
  | 'slim'
  | 'slim-inverse'
  | 'outline'
  | 'outline-large'
  | 'outline-inverse'
  | 'outline-inverse-large'
  | 'text'
  | 'text-inverse'
  | 'apple'
  | 'facebook'
  | 'google'
  | 'iMessage'
  | 'linkedin'
  | 'messenger'
  | 'pinterest'
  | 'twitter'
  | 'whatsapp'
  | 'social';

const isDeprecatedButtonVariant = (
  variant: DeprecatedButtonVariants | ButtonVariants,
): variant is DeprecatedButtonVariants =>
  deprecatedButtonVariants.includes(variant as DeprecatedButtonVariants);

interface Props extends DeprecatedAndDangerousStylingProps {
  /**
   * An icon to place after the `children`.
   */
  endIcon?: ReactNode;

  /**
   * The size of the button.
   * @deprecated for `small` and `xsmall` buttons, use 'slim' variant instead
   *    */
  size?: ResponsiveValue<'large' | 'small' | 'xsmall'>;

  /**
   * An icon to place before the `children`.
   */
  startIcon?: ReactNode;

  /**
   * The variation/style of the button.
   *
   * @default 'filled-statement'
   */
  variant?: ResponsiveValue<ButtonVariants | DeprecatedButtonVariants>;

  /**
   * Whether or not the button fits the width of its contents (`"fit"`), fills the width of its container (`"fill"`) or has the default fixed size (`"fixed"`). The actual pixel width for `"fixed"` is determined by the `size` value.
   * @default 'fixed'
   */
  width?: ResponsiveValue<'fixed' | 'fit' | 'fill'>;

  /**
   * Whether or not the button should be full width within its container.
   * (**DEPRECATED.** Use `width="fill"` instead)
   *
   * @default false
   * @deprecated use `width` prop instead
   */
  isFullWidth?: boolean;

  /**
   * Whether or not the button should fit the width of its contents.
   * (**DEPRECATED.** Use `width="fit"` instead)
   *
   * @default false
   * @deprecated use `width` instead
   */
  isInline?: boolean;
}

export type ButtonProps<C extends ElementType = 'button'> =
  ExtendableButtonBaseProps<C, Props>;

type ButtonComponent = FunctionComponent &
  (<C extends ElementType = 'button'>(
    props: ButtonProps<C>,
  ) => React.ReactElement | null);

/**
 * Buttons allow users to take actions and make choices with a single tap.
 */
export const Button: ButtonComponent = forwardRef(
  <C extends ElementType = 'button'>(
    {
      children,
      endIcon,
      size,
      startIcon,
      variant,
      width,
      'aria-label': ariaLabel,
      isFullWidth,
      isInline,
      ...rootProps
    }: ButtonProps<C>,
    ref?: PolymorphicRef<C>,
  ) => {
    const screenSize = useScreenSize();
    const isWidthSpecified = width !== undefined;
    const responsiveWidth = getResponsiveValue(screenSize, width, 'fixed');
    const responsiveButtonVariant = getResponsiveValue(
      screenSize,
      variant,
      'filled-statement',
    );

    if (isFullWidth !== undefined) {
      logger.warn(
        '`isFullWidth` on `<Button />` is deprecated. Use `width="fill"` instead.',
      );
    }
    if (isInline !== undefined) {
      logger.warn(
        '`isInline` on `<Button />` is deprecated. Use `width="fit"` instead.',
      );
    }

    if (!children && !ariaLabel) {
      logger.warn(
        'Button must specify a label (via `children`) or `aria-label` to satisfy accessibility needs',
      );
    }

    if (size !== undefined) {
      logger.warn(
        '`size` on `<Button />` is deprecated. For `small` and `xsmall` buttons, use `slim` variant instead',
      );
    }

    if (isDeprecatedButtonVariant(responsiveButtonVariant)) {
      logger.warn(
        `${responsiveButtonVariant} variant on <Button /> is deprecated.`,
      );
    }

    // Override the variant to be `slim` while the size value is deprecated.
    const buttonSize = getResponsiveValue(screenSize, size);
    const buttonSizeSmallOrXsmall =
      buttonSize === 'xsmall' || buttonSize === 'small';
    const buttonVariant = buttonSizeSmallOrXsmall
      ? 'slim'
      : responsiveButtonVariant;

    const buttonBaseProps = processStylingProps(rootProps, 'Button', {
      stylingProps: 'warn',
      dangerousStylingProps: 'rewrite',
    }) as ButtonBaseProps<C>;

    const className = classNames(
      styles.root,
      buttonBaseProps.className,
      styles[buttonVariant],
      {
        [styles['is-inline']]:
          (!isWidthSpecified && isInline) || responsiveWidth === 'fit',
        [styles['is-full-width']]:
          (!isWidthSpecified && isFullWidth) || responsiveWidth === 'fill',
        [styles['is-fixed-width']]: responsiveWidth === 'fixed',
        [styles['start-icon']]: startIcon,
        [styles['end-icon']]: endIcon,
      },
    );

    return (
      <ButtonBase
        aria-label={ariaLabel}
        ref={ref}
        {...buttonBaseProps}
        className={className}
      >
        <DecorativeIconProvider>{startIcon}</DecorativeIconProvider>
        {children}
        <DecorativeIconProvider>{endIcon}</DecorativeIconProvider>
      </ButtonBase>
    );
  },
);

Button.displayName = 'Button';

export default Button;
