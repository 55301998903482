// See .env* files & CI for env values. In order to keep server-only secrets
// safe, Next.js replaces `process.env.*` with the correct values at build time.
// This means that `process.env` is not a standard JS object, so we can't do
// object destructuring or string concatenation of a env name.

const graphqlApiEndpoint = process.env.NEXT_PUBLIC_GRAPHQL_API_ENDPOINT;

const settings = {
  /**
   * The name of the application.
   */
  appName: process.env.NEXT_PUBLIC_APP_NAME,

  /**
   * Version of the app. It is the name of the environment when not production
   * (e.g. `development`, `test`). When in production running in CI, it is set
   * to the latest commit sha.
   */
  appVersion: process.env.NEXT_PUBLIC_APP_VERSION,

  /**
   * The app name to report when using apollo to query graphql.
   */
  apolloClientName: `${process.env.NEXT_PUBLIC_APP_NAME}:web`,

  /**
   * A JWT used to authenticate APIs (such as the GraphQL API) without using
   * cookies.
   */
  // Only use the JWT as the `bearerToken` when querying the production GraphQL
  // endpoint. We can't use the JWT when querying the mock graphql server on
  // `localhost` because the `bearerToken` is ultimately passed in the
  // `authorization` header and that causes a CORS error.
  bearerToken: !graphqlApiEndpoint?.includes('localhost')
    ? process.env.NEXT_PUBLIC_MOCK_CLIENT_JWT
    : undefined,

  /**
   * The API key for the client-side app reporting to Bugsnag
   */
  bugsnagClientApiKey: process.env.NEXT_PUBLIC_BUGSNAG_CLIENT_API_KEY,

  /**
   * The application id for reporting to DataDog RUM.
   */
  ddAppId: process.env.NEXT_PUBLIC_DD_APP_ID,
  /**
   * The client id for reporting to DataDog RUM.
   */
  ddClientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN,

  /**
   * The node environment for the application.
   */
  env: process.env.NEXT_PUBLIC_ENV,

  /**
   * The api endpoint to use when querying graphql.
   */
  graphqlApiEndpoint,

  /**
   * Version of sf-next.
   */
  sfNextVersion: process.env.NEXT_PUBLIC_SF_NEXT_VERSION,
} as const;

/**
 * Whether we are using `localhost` assets, such as when running `npm run dev`
 * or `npm run build -- --local`.
 */
export const hasLocalAssets = () =>
  process.env.NODE_ENV !== 'production' ||
  process.env.NEXT_PUBLIC_SF_NEXT_LOCAL === 'true';

export default settings;
