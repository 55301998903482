import React from 'react';

/**
 * The <StopPropagation> component is used to stop propagation of all events. This is used to prevent
 * events from bubbling from a React portal (Modal) to the parent elements. Such event bubbling
 * can cause unexpected behavior, such as when a Carousel renders a Modal, which renders a text input -
 * typing in the text input causes the Carousel to receive the keyboard events and scroll slides, which
 * moreover prevents the default behavior.
 *
 * See https://stitchfix.atlassian.net/browse/KFE-1378 for more context.
 *
 * See https://github.com/facebook/react/issues/11387 for the relevant React issue, and
 * https://github.com/facebook/react/issues/19637#issuecomment-968788962 for the workaround this was
 * based off of.
 */

type ReactEvents = keyof Omit<
  React.DOMAttributes<React.ReactElement>,
  // Non event props:
  | 'children'
  | 'dangerouslySetInnerHTML'
  | 'css'
  // onResize is only available as of React@18.2.0. Until we've bumped up to that minimum version, we will
  // omit it to avoid console warnings:
  | 'onResize'
  // We don't want to stop propagation during the capture phase, just the bubble phase. This ensures
  // that a child to <StopPropagation> will still receive events, but as those events are bubbled up
  // the tree, they will be stopped at the <StopPropagation> boundary.
  // For reference: https://blog.logrocket.com/event-bubbling-capturing-react/
  | 'onWheelCapture'
  | 'onAnimationStartCapture'
  | 'onAnimationIterationCapture'
  | 'onAnimationEndCapture'
  | 'onAbortCapture'
  | 'onAuxClickCapture'
  | 'onBeforeInputCapture'
  | 'onBlurCapture'
  | 'onCanPlayCapture'
  | 'onCanPlayThroughCapture'
  | 'onChangeCapture'
  | 'onClickCapture'
  | 'onCompositionEndCapture'
  | 'onCompositionStartCapture'
  | 'onCompositionUpdateCapture'
  | 'onContextMenuCapture'
  | 'onCopyCapture'
  | 'onCutCapture'
  | 'onDoubleClickCapture'
  | 'onDragCapture'
  | 'onDragEndCapture'
  | 'onDragEnterCapture'
  | 'onDragExitCapture'
  | 'onDragLeaveCapture'
  | 'onDragOverCapture'
  | 'onDragStartCapture'
  | 'onDropCapture'
  | 'onDurationChangeCapture'
  | 'onEmptiedCapture'
  | 'onEncryptedCapture'
  | 'onEndedCapture'
  | 'onErrorCapture'
  | 'onFocusCapture'
  | 'onGotPointerCapture'
  | 'onGotPointerCaptureCapture'
  | 'onInputCapture'
  | 'onInvalidCapture'
  | 'onKeyDownCapture'
  | 'onKeyPressCapture'
  | 'onKeyUpCapture'
  | 'onLoadCapture'
  | 'onLoadedDataCapture'
  | 'onLoadedMetadataCapture'
  | 'onLoadStartCapture'
  | 'onLostPointerCapture'
  | 'onLostPointerCaptureCapture'
  | 'onMouseDownCapture'
  | 'onMouseMoveCapture'
  | 'onMouseOutCapture'
  | 'onMouseOverCapture'
  | 'onMouseUpCapture'
  | 'onPasteCapture'
  | 'onPauseCapture'
  | 'onPlayCapture'
  | 'onPlayingCapture'
  | 'onPointerCancelCapture'
  | 'onPointerDownCapture'
  | 'onPointerMoveCapture'
  | 'onPointerOutCapture'
  | 'onPointerOverCapture'
  | 'onPointerUpCapture'
  | 'onProgressCapture'
  | 'onRateChangeCapture'
  | 'onResetCapture'
  | 'onResizeCapture'
  | 'onScrollCapture'
  | 'onSeekedCapture'
  | 'onSeekingCapture'
  | 'onSelectCapture'
  | 'onStalledCapture'
  | 'onSubmitCapture'
  | 'onSuspendCapture'
  | 'onTimeUpdateCapture'
  | 'onTouchCancelCapture'
  | 'onTouchEndCapture'
  | 'onTouchMoveCapture'
  | 'onTouchStartCapture'
  | 'onTransitionEndCapture'
  | 'onVolumeChangeCapture'
  | 'onWaitingCapture'
  | 'onPointerEnterCapture'
  | 'onPointerLeaveCapture'
>;

const handleStopPropagation = (event: React.SyntheticEvent) => {
  event.stopPropagation();
};

type Events<T extends ReactEvents> = { [K in T]: typeof handleStopPropagation };

export const eventHandlers: Events<ReactEvents> = {
  onAbort: handleStopPropagation,
  onAnimationEnd: handleStopPropagation,
  onAnimationIteration: handleStopPropagation,
  onAnimationStart: handleStopPropagation,
  onAuxClick: handleStopPropagation,
  onBeforeInput: handleStopPropagation,
  onBlur: handleStopPropagation,
  onCanPlay: handleStopPropagation,
  onCanPlayThrough: handleStopPropagation,
  onChange: handleStopPropagation,
  onClick: handleStopPropagation,
  onCompositionEnd: handleStopPropagation,
  onCompositionStart: handleStopPropagation,
  onCompositionUpdate: handleStopPropagation,
  onContextMenu: handleStopPropagation,
  onCopy: handleStopPropagation,
  onCut: handleStopPropagation,
  onDoubleClick: handleStopPropagation,
  onDrag: handleStopPropagation,
  onDragEnd: handleStopPropagation,
  onDragEnter: handleStopPropagation,
  onDragExit: handleStopPropagation,
  onDragLeave: handleStopPropagation,
  onDragOver: handleStopPropagation,
  onDragStart: handleStopPropagation,
  onDurationChange: handleStopPropagation,
  onDrop: handleStopPropagation,
  onEmptied: handleStopPropagation,
  onEncrypted: handleStopPropagation,
  onEnded: handleStopPropagation,
  onError: handleStopPropagation,
  onFocus: handleStopPropagation,
  onInput: handleStopPropagation,
  onInvalid: handleStopPropagation,
  onKeyDown: handleStopPropagation,
  onKeyPress: handleStopPropagation,
  onKeyUp: handleStopPropagation,
  onLoad: handleStopPropagation,
  onLoadedData: handleStopPropagation,
  onLoadedMetadata: handleStopPropagation,
  onLoadStart: handleStopPropagation,
  onMouseDown: handleStopPropagation,
  onMouseEnter: handleStopPropagation,
  onMouseLeave: handleStopPropagation,
  onMouseMove: handleStopPropagation,
  onMouseOut: handleStopPropagation,
  onMouseOver: handleStopPropagation,
  onMouseUp: handleStopPropagation,
  onPaste: handleStopPropagation,
  onPause: handleStopPropagation,
  onPlay: handleStopPropagation,
  onPlaying: handleStopPropagation,
  onPointerCancel: handleStopPropagation,
  onPointerDown: handleStopPropagation,
  onPointerEnter: handleStopPropagation,
  onPointerLeave: handleStopPropagation,
  onPointerMove: handleStopPropagation,
  onPointerOut: handleStopPropagation,
  onPointerOver: handleStopPropagation,
  onPointerUp: handleStopPropagation,
  onProgress: handleStopPropagation,
  onRateChange: handleStopPropagation,
  onReset: handleStopPropagation,
  onScroll: handleStopPropagation,
  onSeeked: handleStopPropagation,
  onSeeking: handleStopPropagation,
  onSelect: handleStopPropagation,
  onStalled: handleStopPropagation,
  onSubmit: handleStopPropagation,
  onSuspend: handleStopPropagation,
  onTimeUpdate: handleStopPropagation,
  onTouchCancel: handleStopPropagation,
  onTouchEnd: handleStopPropagation,
  onTouchMove: handleStopPropagation,
  onTouchStart: handleStopPropagation,
  onTransitionEnd: handleStopPropagation,
  onVolumeChange: handleStopPropagation,
  onWaiting: handleStopPropagation,
  onWheel: handleStopPropagation,
};

type StopPropagationProps = {
  children: React.ReactElement;
};

const StopPropagation = ({ children }: StopPropagationProps) => (
  <div {...eventHandlers}>{children}</div>
);

export default StopPropagation;
