import Cookies from 'js-cookie';
import type { AuthenticatedClient } from '@stitch-fix/client-facing-auth';
import { settings } from '../utils';
import { UK_REGION, US_REGION, type Region } from '../../utils/region';

export const FACEBOOK_PIXEL_COOKIE_NAME = 'fbp';
const FBP_EMAIL_REGEX = /:em=>"([a-fA-F0-9]*)",/;
const FBP_PARENT_EMAIL_REGEX = /:parent_email=>"([a-fA-F0-9]*)"/;
const US_CONTAINER_ID = 'GTM-7RWHC';
const UK_CONTAINER_ID = 'GTM-NH7JL38';

// rewrite of https://github.com/stitchfix/kufak_gtm_client/blob/b903167ced0e85b37e1f36d7583aefe69df46458/lib/stitch_fix/kufak_gtm_client/gtm_container.rb#L12-L23
const getContainerId = (region?: Region): string => {
  if (settings.env === 'production') {
    if (region === US_REGION) {
      return US_CONTAINER_ID;
    }
    if (region === UK_REGION) {
      return UK_CONTAINER_ID;
    }
  }

  return '';
};

// rewrite of https://github.com/stitchfix/kufak_gtm_client/blob/main/lib/stitch_fix/kufak_gtm_client/view_helpers.rb#L16-L21
export const getGtmScript = (region?: Region): string => {
  return `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;var n=d.querySelector('[nonce]');
n&&j.setAttribute('nonce',n.nonce||n.getAttribute('nonce'));f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${getContainerId(region)}');
`;
};

// rewrite of https://github.com/stitchfix/kufak_gtm_client/blob/6a7cf5e2337440d88e3d7b71620c9d19491ba30e/lib/stitch_fix/kufak_gtm_client/controller_helpers/facebook_pixel_cookie_helper.rb#L9-L21
export const getFacebookPixelData = () => {
  // Format of a cookie is a serialization of a Ruby object:
  // {:em=>"[HASH]", :parent_email=>"[HASH]"}
  const fbPixelCookie = Cookies.get(FACEBOOK_PIXEL_COOKIE_NAME);

  if (fbPixelCookie) {
    const emailMatches = fbPixelCookie.match(FBP_EMAIL_REGEX);

    if (emailMatches) {
      const parentEmailMatches = fbPixelCookie.match(FBP_PARENT_EMAIL_REGEX);

      return {
        em: emailMatches[1],
        parent_email: parentEmailMatches ? parentEmailMatches[1] : null,
      };
    }
  }

  return null;
};

export const getInitialData = (
  externalId?: string,
  businessLine?: AuthenticatedClient['businessLine'],
  activeExperiments = '',
) => {
  return {
    user: {
      external_id: externalId,
      businessLine,
    },
    facebook_pixel: getFacebookPixelData(),
    session: {
      initExperiment: activeExperiments,
    },
  };
};
