import { Box, Divider, Text } from '@stitch-fix/mode-react';
import Layout from '../Layout';

/**
 * Default error page
 */
const ErrorPage = () => (
  <Layout>
    <Text setting="title-xlarge" mt={5} align="center">
      Unable to load this page
    </Text>
    <Box maxWidth="400px" width="80%" mx="auto" my={3}>
      <Divider variant="statement" />
    </Box>
    <Text setting="body-medium" align="center">
      Something went wrong - like wearing socks with sandals.
    </Text>
    <Text setting="body-medium" align="center" mt={2}>
      Please go back and try again.
    </Text>
  </Layout>
);

export default ErrorPage;
