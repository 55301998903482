import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useRouter } from 'next/router';
import { I18nProvider } from '@stitch-fix/i18n';
import type { I18nInfo, Locale } from '../../types/i18n';
import { DEFAULT_LOCALE, isValidLocale } from '../../utils/i18n';
import { DEFAULT_REGION } from '../../utils/region';
import { getRegion } from './utils';

/**
 * Context carrying the internationalization (I18n) information for the client
 */
export const ClientI18nContext = createContext<I18nInfo>({
  locale: DEFAULT_LOCALE,
  region: DEFAULT_REGION,
});

interface ClientI18nProviderProps {
  /**
   * The app/page contents
   */
  children?: ReactNode;
}

/**
 * Puts the client I18n information in the React context
 */
export const ClientI18nProvider = ({ children }: ClientI18nProviderProps) => {
  // NOTE: There's currently not a way to hook into Next's locale determination
  // to provide a default that is the client's locale/region. We could default
  // it here, but then the I18n retrievals in `getStaticProps` and
  // `getServerSideProps` would be different than what would be retrieved here.

  const router = useRouter();

  // Router locale is set up in `configs/next/index.ts`
  const locale = isValidLocale(router.locale)
    ? (router.locale as Locale)
    : DEFAULT_LOCALE;
  const region = getRegion();

  // ensure that if the `region` doesn't change that the context object
  // doesn't change with each re-render.
  const i18nInfo = useMemo(() => ({ locale, region }), [locale, region]);

  // `I18nProvider` enables retrieving translations within the component tree. `ClientI18nContext` is for retrieving the client's locale/region information within the component tree.

  return (
    <I18nProvider locale={locale}>
      <ClientI18nContext.Provider value={i18nInfo}>
        {children}
      </ClientI18nContext.Provider>
    </I18nProvider>
  );
};

/**
 * Retrieves the client internalization (I18n) information anywhere in the
 * component tree when nested within an `I18nextProvider` &
 * `ClientI18nProvider`.
 * @returns Client I18n information
 */
export const useClientI18n = (): I18nInfo => useContext(ClientI18nContext);
