import { CSSProperties } from 'react';
import hyphenateStyleName from 'hyphenate-style-name';
import { getBreakpoints, getScreenSizes } from './screenSizes';
import {
  NonResponsiveValue,
  ResponsiveValue,
  isNonResponsiveValue,
  getCssValue,
} from './styling';

const BASE_BREAKPOINT = 'base' as const;

interface CssValueBreakpoint {
  breakpoint: number | typeof BASE_BREAKPOINT;
  value: string;
}

const makeBaseBreakpoint = (value: string): CssValueBreakpoint => ({
  breakpoint: BASE_BREAKPOINT,
  value,
});

export interface ResponsiveOptions<Value extends NonResponsiveValue> {
  /**
   * The value that changes depending on the screen size
   */
  responsiveValue: ResponsiveValue<Value> | undefined;

  /**
   * The default value when the responsive value for a screen size is
   * `undefined` or unspecified
   */
  defaultValue?: NonNullable<Value>;

  /**
   * A map of an individual responsive value to its equivalent CSS value. Needed
   * when the responsive value isn't a CSS property value
   */
  mapCssValue?: (val: Value) => string | undefined;
}

const getCssValueBreakpoints = <Value extends NonResponsiveValue>({
  responsiveValue,
  defaultValue,
  mapCssValue = val =>
    typeof val === 'number' ? getCssValue(val) : val?.toString(),
}: ResponsiveOptions<Value>): CssValueBreakpoint[] | string | undefined => {
  const conditionalMap = (val: NonNullable<Value> | undefined) =>
    val !== undefined ? mapCssValue(val) : undefined;

  // if not a responsive value object, there's no need to create breakpoints.
  // just return the mapped value
  if (isNonResponsiveValue(responsiveValue)) {
    return conditionalMap(responsiveValue ?? defaultValue);
  }

  const screenSizes = getScreenSizes();
  const breakpoints = getBreakpoints();
  const orderedScreenSizes = responsiveValue.below
    ? [...screenSizes].reverse()
    : screenSizes;

  // convert the responsive value object with screen size names (like `sm`,
  // `md`, etc.) into an ordered list of pixel breakpoints
  let valueBreakpoints = orderedScreenSizes
    .map((screenSize, index) => {
      // the labeled breakpoints are at the end of the screen size. For instance
      // the `md` breakpoint is at 900 whereas the `md` screen size is from 561
      // - 900. So when trying to grab the breakpoint for "up" media queries we
      // need to grab the breakpoint for the previous screen size.
      const breakpoint = responsiveValue.below
        ? breakpoints[screenSize]
        : breakpoints[orderedScreenSizes[index - 1]];

      // using `null` to indicate "missing" breakpoint that we want to omit &
      // not generate a media query for. this allows us to distinguish it from
      // an actual `undefined` value which we want to `unset`
      const value =
        responsiveValue && screenSize in responsiveValue
          ? conditionalMap(responsiveValue[screenSize] ?? defaultValue)
          : null;

      return {
        // the smallest screen size for "up" or the largest screen size for
        // "down" is the "base"
        breakpoint: index === 0 ? BASE_BREAKPOINT : breakpoint,

        // An explicit `undefined` is a signal that we are trying to override
        // the media query. So that maps to the `unset` CSS value
        value: value !== undefined ? value : 'unset',
      };
    })
    .filter(
      (valueBreakpoint): valueBreakpoint is CssValueBreakpoint =>
        valueBreakpoint.value !== null,
    );

  const mappedDefaultValue = conditionalMap(defaultValue);

  // if the responsive value object was empty or `mapCssValue` mapped to an
  // `undefined` value, we could have 0 breakpoints. If so just return the
  // mapped `defaultValue`.
  if (valueBreakpoints.length === 0) {
    return mappedDefaultValue;
  }

  // we always need a base breakpoint. if a base breakpoint wasn't specified in
  // the responsive value object (`sm` for "up" or `xxl` for "down"), we need to
  // add it.
  if (
    mappedDefaultValue !== undefined &&
    valueBreakpoints[0].breakpoint !== BASE_BREAKPOINT
  ) {
    valueBreakpoints = [
      makeBaseBreakpoint(mappedDefaultValue),
      ...valueBreakpoints,
    ];
  }

  // if the base breakpoint was `undefined`, its CSS value would be `unset`,
  // which is pointless (and confusing) for a base value. so remove it.
  if (
    valueBreakpoints[0].breakpoint === BASE_BREAKPOINT &&
    valueBreakpoints[0].value === 'unset'
  ) {
    valueBreakpoints.shift();
  }

  return valueBreakpoints;
};

export type DOMPropertyName = keyof CSSProperties;
export type DOMPropertyValue = CSSProperties[DOMPropertyName];

interface InlineStyle {
  name: DOMPropertyName;
  value: CSSProperties[DOMPropertyName];
}

// A responsive style for a property either has media query CSS (ultimately for
// a `<style>` tag) or an inline style. Never both.
type ResponsiveStyle = { inline: InlineStyle } | { css: string };

/**
 * Converts the specified responsive value for the given property into either a
 * media query CSS or an inline style
 * @param uniqueClassName A unique class name for the rendered component to use
 * within the media query CSS
 * @param domPropertyName Camel-case version of the CSS property (i.e.
 * `marginLeft`)
 */
export const getResponsiveStyleForProperty = <Value extends NonResponsiveValue>(
  uniqueClassName: string,
  domPropertyName: DOMPropertyName,
  { responsiveValue, defaultValue, mapCssValue }: ResponsiveOptions<Value>,
): ResponsiveStyle | undefined => {
  const valueBreakpoints = getCssValueBreakpoints({
    responsiveValue,
    defaultValue,
    mapCssValue,
  });

  if (valueBreakpoints === undefined) {
    return undefined;
  }

  // `valueBreakpoints` won't be an array if `responsiveValue` initially wasn't
  // an object. So we convert into a "base" breakpoint so all the logic below
  // only has to deal with arrays.
  const cssValueBreakpoints = Array.isArray(valueBreakpoints)
    ? valueBreakpoints
    : [makeBaseBreakpoint(valueBreakpoints)];

  // when there's only one "base" breakpoint, there's no need to use a `<style>`
  // tag. we can just make it an inline style. since all the `Box` props are
  // responsive, setting any prop (even if it's just to a non-responsive value),
  // would result in a ton of `<style>` tags. since the value isn't changing,
  // setting the inline `style` will be most optimal.
  if (
    cssValueBreakpoints.length === 1 &&
    cssValueBreakpoints[0].breakpoint === 'base'
  ) {
    return {
      inline: {
        name: domPropertyName,
        value: cssValueBreakpoints[0].value,
      },
    };
  }

  // transform the breakpoints into media queries
  const cssDeclarations = cssValueBreakpoints
    .map(({ breakpoint, value }) => {
      const cssPropertyName = hyphenateStyleName(domPropertyName);

      // ex: .a010 { padding-left: 16px; }
      const cssDeclaration = `.${uniqueClassName} { ${cssPropertyName}: ${value}; }`;

      if (breakpoint === 'base') {
        return cssDeclaration;
      }

      const isMaxWidth =
        !isNonResponsiveValue(responsiveValue) && responsiveValue.below;
      const feature = isMaxWidth ? 'max-width' : 'min-width';
      const featureValue = isMaxWidth
        ? `${breakpoint - 1}px`
        : `${breakpoint}px`;

      // ex: @media screen and (min-width: 560px) { .a010 { padding-left: 16px; } }
      return `@media screen and (${feature}: ${featureValue}) { ${cssDeclaration} }`;
    })
    .join('\n');

  return {
    css: cssDeclarations,
  };
};

export type ResponsiveCssProperties<
  OptionsValue extends NonResponsiveValue = NonResponsiveValue,
> = Partial<Record<DOMPropertyName, ResponsiveOptions<OptionsValue>>>;

interface ResponsiveStyles {
  /**
   * Media query styles to be added as the contents of `<style>` elements
   */
  cssStyles: string[];

  /**
   * Inline styles to be added to the DOM element
   */
  inlineStyles: Partial<CSSProperties>;
}

/**
 * Converts multiple responsive values for properties into a list of media query
 * CSS and merged inline styles
 * @param uniqueClassName A unique class name for the rendered component to use
 * within the media query CSS
 * @param properties Look-up of CSS properties (like `width` or `paddingLeft`)
 * to responsive prop configurations
 */
export const getResponsiveStylesForProperties = <
  OptionsValue extends NonResponsiveValue,
>(
  uniqueClassName: string,
  properties: ResponsiveCssProperties<OptionsValue>,
): ResponsiveStyles => {
  const responsiveStyles = Object.entries(properties)
    .map(([domPropertyName, options]) =>
      getResponsiveStyleForProperty(
        uniqueClassName,
        domPropertyName as DOMPropertyName,
        options,
      ),
    )
    .filter(
      // filter out any `undefined` responsive styles (and inform TS too)
      (responsiveStyle): responsiveStyle is ResponsiveStyle =>
        !!responsiveStyle,
    );

  const cssStyles = responsiveStyles
    .map(responsiveStyle =>
      'css' in responsiveStyle ? responsiveStyle.css : undefined,
    )
    .filter((css): css is string => !!css);

  const inlineStyles = responsiveStyles
    .map(responsiveStyle =>
      'inline' in responsiveStyle ? responsiveStyle.inline : undefined,
    )
    .filter((inline): inline is InlineStyle => !!inline)
    .reduce(
      (styles, { name, value }) => ({
        ...styles,
        [name]: value,
      }),
      {} as CSSProperties,
    );

  return {
    cssStyles,
    inlineStyles,
  };
};
