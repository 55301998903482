// This is the only file that allows these restricted globals because it
// provides helper functions to use them in a safe way for serving-side
// rendering. We're not using the helpers from `mode-react` because they are
// imported from its root index file that also imports components, which import
// CSS. And our CLIs cannot import those.
/* eslint-disable no-restricted-globals */

/**
 * Returns the `window` object if it exists in the current
 * environment, otherwise `undefined`
 */
export const getWindow = (): Window | undefined =>
  typeof window === 'object' ? window : undefined;

/**
 * Returns the `document` object if it exists in the current
 * environment, otherwise `undefined`
 */
export const getDocument = (): Document | undefined =>
  typeof document === 'object' ? document : undefined;

/* eslint-enable no-restricted-globals */
