import React, {
  type ElementType,
  forwardRef,
  type FunctionComponent,
  type Ref,
} from 'react';
import classnames from 'classnames';
import {
  PolymorphicComponentProps,
  ExtendableProps,
  PropsWithRef,
  PolymorphicRef,
} from '../_internal/components';
import {
  ElementStylingProps,
  ResponsiveValue,
  ResponsiveSizeValue,
  getResponsiveValue,
  NonResponsiveValue,
} from '../_internal/styling';
import { Color, useColors } from '../_internal/colors';
import {
  PaddingProps,
  MarginProps,
  toResponsivePaddingProperties,
  toResponsiveMarginProperties,
} from '../_internal/spacing';
import useScreenSize from '../_internal/useScreenSize';
import ResponsiveBase from '../ResponsiveBase';

import styles from './box.module.scss';

type CommonProps = {
  /**
   * Sets the display type ("none", "block", "flex", etc) of the container
   */
  display?: ResponsiveValue<string>;
  /**
   * Sets the height of the container
   */
  height?: ResponsiveSizeValue;
  /**
   * Sets the max-height of the container
   */
  maxHeight?: ResponsiveSizeValue;
  /**
   * Sets the max-width of the container
   */
  maxWidth?: ResponsiveSizeValue;
  /**
   * Sets the max-height of the container
   */
  minHeight?: ResponsiveSizeValue;
  /**
   * Sets the min-width of the container
   */
  minWidth?: ResponsiveSizeValue;
  /**
   * Sets the width of the container
   */
  width?: ResponsiveSizeValue;
};

type VariantProps = {
  /**
   * The container style
   */
  variant?: ResponsiveValue<
    | undefined
    | 'citrus'
    | 'grapefruit'
    | 'error-light'
    | 'error-dark'
    | 'error-border'
    | 'success-light'
    | 'success-border'
    | 'info-light'
    | 'info-dark'
    | 'info-border'
    | 'success-border'
    | 'promo-light'
    | 'promo-dark'
    | 'promo-border'
    | 'dark'
    | 'card-1'
    | 'card-2'
  >;

  color?: undefined;
  bgColor?: undefined;
};
type ColorProps = {
  /**
   * Sets the color for any text within the container. Invalid when `variant` is specified.
   */
  color?: ResponsiveValue<undefined | Color>;
  /**
   * Sets the background color for the container. Invalid when `variant` is specified.
   */
  bgColor?: ResponsiveValue<undefined | Color>;

  variant?: undefined;
};

type VariantOrColorProps = VariantProps | ColorProps;
type Props = CommonProps & VariantOrColorProps & MarginProps & PaddingProps;

/**
 * Allows for the props of a parent component to extend
 * the props of `Box` *before* they are merged with the
 * props of the underlying element.
 */
export type ExtendableBoxProps<
  C extends ElementType = 'div',
  OverrideProps = {},
> = PropsWithRef<
  C,
  PolymorphicComponentProps<C, ExtendableProps<Props, OverrideProps>>
>;

export type BoxProps<C extends ElementType = 'div'> = ExtendableBoxProps<
  C,
  ElementStylingProps
>;

type BoxComponent = FunctionComponent &
  (<C extends ElementType = 'div'>(
    props: BoxProps<C>,
  ) => React.ReactElement | null);

/**
 * Use `Box` as a generic container for elements, replacing the use of `<div>`. It renders a `<div>` by default.
 */
export const Box: BoxComponent = forwardRef(
  <C extends ElementType = 'div'>(
    {
      as,
      color,
      bgColor,
      display,
      height,
      maxHeight,
      maxWidth,
      minHeight,
      minWidth,
      width,
      variant,
      m,
      mt,
      mb,
      my,
      ml,
      mr,
      mx,
      p,
      pt,
      pb,
      py,
      pl,
      pr,
      px,
      style,
      ...rootProps
    }: BoxProps<C>,
    ref?: PolymorphicRef<C>,
  ) => {
    const Component = as || 'div';
    const screenSize = useScreenSize();

    const colors = useColors();

    const mapColorCssValue = (responsiveBgColor: NonResponsiveValue) =>
      !variant && responsiveBgColor
        ? colors[responsiveBgColor as Color]
        : undefined;

    const properties = {
      ...toResponsiveMarginProperties({ m, mt, mb, my, ml, mr, mx }),
      ...toResponsivePaddingProperties({ p, pt, pb, py, pl, pr, px }),

      // We don't want to include the color or bgColor when the variant is specified
      backgroundColor: {
        responsiveValue: bgColor,
        mapCssValue: mapColorCssValue,
      },
      color: {
        responsiveValue: color,
        mapCssValue: mapColorCssValue,
      },

      display: { responsiveValue: display },
      height: { responsiveValue: height },
      maxHeight: { responsiveValue: maxHeight },
      maxWidth: { responsiveValue: maxWidth },
      minHeight: { responsiveValue: minHeight },
      minWidth: { responsiveValue: minWidth },
      width: { responsiveValue: width },
    };

    const responsiveScreenSize = getResponsiveValue(screenSize, variant);

    return (
      <ResponsiveBase properties={properties}>
        {({ className: uniqueClasses, style: responsiveStyle }) => (
          <Component
            style={{ ...responsiveStyle, ...style }}
            // The polymorphic Component typing always interprets as a div for
            // some reason, which conflicts with the Ref being typed to match C.
            // Casting it as any ref to work around this.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ref={ref as Ref<any>}
            {...rootProps}
            // Because we spread rootProps, we run the risk of overwriting className prop if it is passed in.
            // By moving the className below the ...rootProps we can merge all the classNames together.
            className={classnames(
              uniqueClasses,
              rootProps.className,
              responsiveScreenSize ? styles[responsiveScreenSize] : undefined,
            )}
          />
        )}
      </ResponsiveBase>
    );
  },
);

Box.displayName = 'Box';

export default Box;
