import React, { ReactNode, ElementType } from 'react';
import Box, { ExtendableBoxProps, BoxProps } from '../Box';
import logger from '../_internal/logger';
import {
  processStylingProps,
  DeprecatedAndDangerousStylingProps,
  ResponsiveValue,
  mapResponsiveValue,
} from '../_internal/styling';

type Gutter = 'none' | 'tight' | 'standard';
type Width =
  | 'small'
  | 'small-fixed'
  | 'medium'
  | 'medium-fixed'
  | 'large'
  | 'large-fixed'
  | 'xlarge'
  | 'xlarge-fixed'
  | 'xxlarge'
  | 'full-bleed';

const WIDTH_PIXEL_MAP: Record<Width, number | undefined> = {
  small: 424,
  'small-fixed': 320,
  medium: 644,
  'medium-fixed': 560,
  large: 864,
  'large-fixed': 900,
  xlarge: 1084,
  'xlarge-fixed': 1140,
  xxlarge: 1368,
  'full-bleed': undefined,
};
const GUTTER_PIXEL_MAP: Record<Gutter, number | undefined> = {
  none: undefined,
  tight: 0.5,
  standard: 1,
};

const getWidthProps = (
  width?: Width,
): { width: string } | { maxWidth: string } => {
  if (!width || width === 'full-bleed') {
    return { width: '100%' };
  }

  const widthValue = WIDTH_PIXEL_MAP[width];

  if (widthValue) {
    const widthPixels = `${widthValue}px`;

    // for the `*-fixed` widths were going to set `width` on the container because it's not supposed to resize. The non-fixed values use `maxWidth` so they are fluid
    return width.endsWith('-fixed')
      ? { width: widthPixels }
      : { maxWidth: widthPixels };
  }

  // for backwards compatibility for non-TS apps in case they pass in a specific CSS width value (since the underlying `Box` has a `width` prop)
  logger.warn(
    `ResponsiveSection: invalid "width" prop. Passing as a CSS value. Specified value: ${width}`,
  );

  return { width };
};

interface Props extends DeprecatedAndDangerousStylingProps {
  /**
   * Contents of the responsive section
   */
  children: ReactNode;
  /**
   * Whether or not the responsive left & right padding should be removed
   * (**DEPRECATED.** Use `gutter="none"` instead)
   *
   * @default false
   * @deprecated Use `gutter="none"` instead
   */
  disableGutters?: boolean;
  /**
   * The amount of left & right padding on the outside of the content
   *
   * @default 'standard'
   */
  gutter?: ResponsiveValue<Gutter>;
  /**
   * The flexible (or fixed) width of the section
   *
   * @default 'xlarge'
   */
  width?: ResponsiveValue<Width>;
}

export type ResponsiveSectionProps<C extends ElementType = 'div'> =
  ExtendableBoxProps<C, Props>;

/**
 * The `ResponsiveSection` component centers page-level content horizontally within a fluid-width container. It's used for the main page/content layout, including header and footer. Use multiple `ResponsiveSection` components to construct an overall page layout.
 */
const ResponsiveSection = <C extends ElementType>({
  children,
  disableGutters = false,
  gutter: responsiveGutter,
  width: responsiveWidth = 'xlarge',
  ...rootProps
}: ResponsiveSectionProps<C>) => {
  const width = mapResponsiveValue(widthVal => {
    const widthProps = getWidthProps(widthVal);

    return 'width' in widthProps ? widthProps.width : '100%';
  }, responsiveWidth);
  const maxWidth = mapResponsiveValue(widthVal => {
    const widthProps = getWidthProps(widthVal);

    return 'maxWidth' in widthProps ? widthProps.maxWidth : undefined;
  }, responsiveWidth);

  const px = mapResponsiveValue(gutter => {
    const gutterWithDefault = gutter ?? (disableGutters ? 'none' : 'standard');

    return GUTTER_PIXEL_MAP[gutterWithDefault];
  }, responsiveGutter);

  const boxProps = processStylingProps(rootProps, 'ResponsiveSection', {
    stylingProps: 'warn',
    dangerousStylingProps: 'rewrite',
  }) as BoxProps<C>;

  if (disableGutters) {
    logger.warn(
      `ResponsiveSection: the "disableGutters" prop is deprecated. Use \`gutter="none"\` instead.`,
    );
  }

  return (
    <Box width={width} maxWidth={maxWidth} px={px} mx="auto" {...boxProps}>
      {children}
    </Box>
  );
};

export default ResponsiveSection;
