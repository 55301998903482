import React from 'react';
import { LazyMotion as FramerLazyMotion } from 'framer-motion';

const loadFeatures = () => import('./features').then(res => res.default);

// LazyMotion is a wrapper around Framer's LazyMotion component that allows us to load features lazily
// to reduce bundle size, docs: https://www.framer.com/motion/guide-reduce-bundle-size/#lazy-loading
export const LazyMotion = ({ children }: { children: React.ReactNode }) => (
  <FramerLazyMotion features={loadFeatures}>{children}</FramerLazyMotion>
);
