import { getAuthenticatedClient as baseGetAuthenticatedClient } from '@stitch-fix/client-facing-auth/browser';

/**
 * Gets the client auth information for the logged in user from the auth cookie, if it exists
 * @returns Client auth info if logged in, otherwise `undefined` indicating logged out
 */
export const getAuthenticatedClient = () => {
  // By default grab the JWT from the auth cookie. However, if it doesn't exist,
  // we'll fall back to the env var. The env var is set by `sf-next dev` and
  // potentially `sf-next start` as well to support local mock auth. It will
  // never be set when exporting for Production.
  // KFE-1337: Need to support passing in a `req` object in order to retrieve the auth cookie server-side.
  return baseGetAuthenticatedClient(process.env.NEXT_PUBLIC_MOCK_CLIENT_JWT);
};
