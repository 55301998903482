import { useEffect, useRef } from 'react';
import { getWindow } from '../getBrowserGlobals';

declare global {
  interface Window {
    MODE_REACT_LAST_ID?: {
      id: number;
    };
  }
}
let lastId = { id: 0 };
const syncWindow = () => {
  const win = getWindow();

  // Store ID on the window so that if there happens to be two different
  // versions of Mode React on the page, they will share the same ID counter.
  // Otherwise, they'll both start at 0 and we can get duplicated IDs. Nesting
  // it in an object so that they can share the same object reference when we
  // increment the number within.
  if (win) {
    if (win.MODE_REACT_LAST_ID) {
      lastId = win.MODE_REACT_LAST_ID;
    }

    win.MODE_REACT_LAST_ID = lastId;
  }
};
// ONLY FOR TESTING!!

export const resetLastId = () => {
  syncWindow();

  lastId.id = 0;
};
const getNextId = (prefix: string): string => {
  // because `lastId` is an object synced w/ the window, incrementing
  // here will increment both. See above for more details.
  lastId.id += 1;

  return `${prefix}${lastId.id}`;
};
// Copied from react docs
// https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
const usePrevious = <T>(value: T) => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

const useUniqueIdLegacy = (prefix: string): string => {
  const idRef = useRef('');
  const prevPrefix = usePrevious(prefix);

  if (prevPrefix === undefined || prevPrefix !== prefix) {
    idRef.current = getNextId(prefix);
  }

  return idRef.current;
};

export default useUniqueIdLegacy;
