import type { IncomingMessage } from 'http';
import type { AuthenticatedClient } from '@stitch-fix/client-facing-auth';
import { getWindow } from '../utils/getBrowserGlobals';
import {
  DEFAULT_REGION,
  getHostInfo,
  isValidRegion,
  type Region,
} from '../../utils/region';

interface GetRegionOptions {
  client?: AuthenticatedClient;
  req?: IncomingMessage;
}

/**
 * Retrieves the region from the client, domain, or environment variable.
 */
export const getRegion = (options: GetRegionOptions = {}): Region => {
  const { client, req } = options;
  const hostName = req?.headers.host || getWindow()?.location.hostname;
  const prioritizedRegionOptions = [
    // 1. Use the client's region if available
    client?.region,

    // 2. Use the region from the request's host if available
    getHostInfo(hostName)?.region,

    // 3. Use the region from the environment variable if available
    process.env.NEXT_PUBLIC_REGION,
  ];

  // validate the region. if it's invalid or none was found above, use the
  // default region
  return prioritizedRegionOptions.find(isValidRegion) || DEFAULT_REGION;
};
