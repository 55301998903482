import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
import IconBase, { IconProps } from '../IconBase';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconSocialYoutube24 = (baseProps: IconProps, ref: Ref<SVGSVGElement>) => {
  return <IconBase {...baseProps}>
        {({
      title,
      titleId,
      ...props
    }: SVGProps<SVGSVGElement> & SVGRProps) => <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M22.8007 4.81577C23.5234 5.54239 23.7578 7.19681 23.7578 7.19681L23.7558 7.19486C23.9055 8.48538 23.987 9.78286 24 11.0819V12.9102C23.9897 14.2092 23.9101 15.5067 23.7617 16.7972C23.7617 16.7972 23.5273 18.4536 22.8065 19.1802C21.9825 20.0412 21.0712 20.1308 20.5547 20.1816C20.4983 20.1872 20.4465 20.1923 20.4001 20.1979C17.0463 20.446 12.001 20.446 12.001 20.446C12.001 20.446 5.76023 20.3913 3.84016 20.2077C3.74765 20.1902 3.63549 20.1766 3.50852 20.1613C2.89831 20.0875 1.94623 19.9725 1.18954 19.1802C0.472703 18.4438 0.238295 16.7894 0.238295 16.7894C0.0900342 15.4989 0.0104913 14.2014 0 12.9024V11.0839C0.0103244 9.78487 0.0898673 8.48733 0.238295 7.19681C0.238295 7.19681 0.468793 5.54239 1.19541 4.81577C2.01823 3.95429 2.92809 3.86507 3.44354 3.81457C3.50075 3.80894 3.55308 3.80379 3.59991 3.79812C6.95955 3.55396 11.999 3.55396 11.999 3.55396C11.999 3.55396 17.0404 3.55396 20.3962 3.79812C20.4426 3.80374 20.4944 3.80885 20.5511 3.81442C21.0665 3.86521 21.9788 3.95515 22.8007 4.81577ZM9.51444 8.36487V15.1096L15.9974 11.748L9.51444 8.36487Z" fill="currentColor" /></svg>}
      </IconBase>;
};
const ForwardRef = forwardRef(IconSocialYoutube24);
export default ForwardRef;