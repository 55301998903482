import React, { ReactNode } from 'react';

import '../global.scss';

export interface CssBaseProps {
  children?: ReactNode;
}

const CssBase = ({ children }: CssBaseProps) => <>{children}</>;

export default CssBase;
