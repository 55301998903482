import { getWindow, type TraceableDetail } from '../getWindow';

export const onTraceableComplete = (
  callback: () => void,
): undefined | (() => void) => {
  const win = getWindow();

  if (!win) return undefined;

  if (win.TRACEABLE_POW_STATUS?.status === 'COMPLETE') {
    callback();

    return undefined;
  }

  const eventCallback = ((event: CustomEvent<TraceableDetail>) => {
    const data = event.detail;

    if (data.status === 'COMPLETE') {
      callback();
    }
  }) as EventListener;

  document.addEventListener('traceable-pow-status-change', eventCallback);

  return () => {
    document.removeEventListener('traceable-pow-status-change', eventCallback);
  };
};
