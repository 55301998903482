import { CloudinaryImageTransforms } from './types';

/**
 * Generates an image URL after applying Cloudinary URL based transformations.
 *
 * @param baseUrl The base URL for an image hosted on Cloudinary or Cloudfront, e.g., "https://res.cloudinary.com/stitch-fix"
 * @param mediaPath A Cloudinary image's name (version and/or public id), e.g., "/v1585276216/a6ttgw2xjomdl1pbzehz.jpg"
 * @param transforms Transformations that should be applied to image
 */
const generateCloudinaryUrl = (
  baseUrl: string,
  mediaPath: string,
  transforms: CloudinaryImageTransforms,
): string => {
  const matchLeadingSlash = /^\//;
  const matchTrailingSlash = /\/$/;
  const normalizedBaseUrl = baseUrl.replace(matchTrailingSlash, '');
  const normalizedMediaPath = mediaPath.replace(matchLeadingSlash, '');
  // Join all transforms with a ',', excluding effects 'e'.
  const transformString = Object.entries(transforms)
    .filter(([prefix]) => prefix !== 'e')
    .map(([prefix, value]) => `${prefix}_${value}`)
    .join(',');
  let effectString = '';

  // Multiple effect values are allowed, but must be joined with a `/`.
  if ('e' in transforms) {
    effectString = Array.isArray(transforms.e)
      ? transforms.e.map(value => `e_${value}`).join('/')
      : `e_${transforms.e}`;
  }

  // Effects take precedence over other transforms to allow us to apply effect on original image
  return [normalizedBaseUrl, effectString, transformString, normalizedMediaPath]
    .filter(Boolean)
    .join('/');
};

export default generateCloudinaryUrl;
