import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
import IconBase, { IconProps } from '../IconBase';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconSocialFacebookCircle24 = (baseProps: IconProps, ref: Ref<SVGSVGElement>) => {
  return <IconBase {...baseProps}>
        {({
      title,
      titleId,
      ...props
    }: SVGProps<SVGSVGElement> & SVGRProps) => <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#1877F2" ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path d="M12 0.387085C5.37273 0.387085 0 5.61322 0 12.0597C0 17.9119 4.43182 22.7438 10.2065 23.588V15.1534H7.23764V12.0851H10.2065V10.0435C10.2065 6.66323 11.8996 5.17921 14.7878 5.17921C16.1711 5.17921 16.9025 5.27896 17.2489 5.32459V8.00292H15.2787C14.0525 8.00292 13.6244 9.13357 13.6244 10.408V12.0851H17.2178L16.7302 15.1534H13.6244V23.6129C19.4815 22.8398 24 17.9687 24 12.0597C24 5.61322 18.6273 0.387085 12 0.387085Z" fill="currentColor" /></svg>}
      </IconBase>;
};
const ForwardRef = forwardRef(IconSocialFacebookCircle24);
export default ForwardRef;