import { getAuthenticatedClient } from '@stitch-fix/client-facing-auth/browser';
import { ClientId } from '../types/events';

const getClientId = (): ClientId => {
  const { client } = getAuthenticatedClient();

  return client?.clientId?.toString() || null;
};

export default getClientId;
