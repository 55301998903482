import jwtDecode from 'jwt-decode';
import jwtEncode from 'jwt-encode';
import type { AuthenticatedClient, JwtAuthData } from './types';

const toAuthenticatedClient = (authData: JwtAuthData): AuthenticatedClient => {
  // TODO: Use something like TS JSON Schema to validate the token data

  const {
    // see: https://github.com/stitchfix/client-facing-auth/blob/3f2e64fcfb5a62387bd478fec3bf2e9717873495/lib/stitch_fix/client_facing_auth/jwt/auth_info.rb#L13-L15
    prn: clientId,

    data,
  } = authData;

  const households = data.household.map(({ client_id, external_id }) => ({
    clientId: client_id,
    externalId: external_id,
  }));

  // we should always have a matching household member, otherwise something is
  // terribly wrong. hence the use of the non-null operator (`!`)
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const householdMatchingClient = households.find(
    member => member.clientId === clientId,
  )!;

  // see:
  // https://github.com/stitchfix/client-facing-auth/blob/3f2e64fcfb5a62387bd478fec3bf2e9717873495/lib/stitch_fix/client_facing_auth/authenticated_client.rb#L50-L52
  // the first client in the household is the one (and only) adult. so if the
  // current `clientId` matches that first household member, the
  // `primary_business_line` is the current business line. otherwise a kid is
  // the authenticated client, so the business line is "kids"
  const businessLine =
    households[0].clientId === clientId ? data.primary_business_line : 'kids';

  return {
    adminEmail: data.admin_email,
    adminId: data.admin_id,
    businessLine,
    clientId,
    email: data.email,
    externalId: householdMatchingClient.externalId,
    firstName: data.first_name,
    household: households,
    lastName: data.last_name,
    locale: data.locale,
    region: data.region,
  };
};

export type AuthenticatedClientResponse =
  | {
      /**
       * The client auth info if logged in. Will be `undefined` if logged out or
       * there is an `error`.
       */
      client: AuthenticatedClient | undefined;
      expired: boolean;
      expiresAt: Date | undefined;
      error: undefined;
    }
  | {
      client: undefined;
      expired: boolean;
      expiresAt: undefined;
      /**
       * The error that ocurred while retrieving the authenticated client
       */
      error: Error;
    };

/**
 * Gets an authenticated client from the specified JWT, if it exists.
 * @param jwt The JWT
 * @returns `undefined` if the `jwt` is `undefined` or empty. Otherwise, the authenticated client info
 */
export const getAuthenticatedClientFromJwt = (
  jwt?: string,
): AuthenticatedClientResponse => {
  if (!jwt)
    return {
      client: undefined,
      expired: false,
      expiresAt: undefined,
      error: undefined,
    };

  try {
    const authData = jwtDecode<JwtAuthData>(jwt);
    const client = toAuthenticatedClient(authData);

    // `exp` is in seconds from Epoch, Date() takes milliseconds
    const expiresAt = authData.exp ? new Date(authData.exp * 1000) : undefined;
    const expired = expiresAt ? expiresAt < new Date() : false;

    return { client, expired, expiresAt, error: undefined };
  } catch (error: unknown) {
    return {
      client: undefined,
      expired: false,
      expiresAt: undefined,
      error: error as Error,
    };
  }
};

/**
 * Generates a mock JWT for the specified mock auth data
 * @param mockAuthData The mock JWT auth data to encode into a JWT
 * @returns the unsigned mock JWT token
 */
export const genMockJwtToken = (mockAuthData: JwtAuthData): string => {
  // We don't need to sign with our real private key because this is
  // only used for testing / development
  return jwtEncode(mockAuthData, 'mock-private-key');
};
