import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
import IconBase, { IconProps } from '../IconBase';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconSuccess16 = (baseProps: IconProps, ref: Ref<SVGSVGElement>) => {
  return <IconBase {...baseProps}>
        {({
      title,
      titleId,
      ...props
    }: SVGProps<SVGSVGElement> & SVGRProps) => <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M7.99995 1.69996C4.52056 1.69996 1.69995 4.52057 1.69995 7.99996C1.69995 11.4794 4.52056 14.3 7.99995 14.3C11.4793 14.3 14.3 11.4794 14.3 7.99996C14.3 4.52057 11.4793 1.69996 7.99995 1.69996ZM0.299952 7.99996C0.299952 3.74737 3.74736 0.299961 7.99995 0.299961C12.2525 0.299961 15.7 3.74737 15.7 7.99996C15.7 12.2526 12.2525 15.7 7.99995 15.7C3.74736 15.7 0.299952 12.2526 0.299952 7.99996ZM7.26085 9.68188L10.1253 5.07059L11.3146 5.80933L7.93529 11.2493L7.4205 12.078L6.80032 11.325L4.4196 8.43402L5.50031 7.54404L7.26085 9.68188Z" fill="currentColor" /></svg>}
      </IconBase>;
};
const ForwardRef = forwardRef(IconSuccess16);
export default ForwardRef;